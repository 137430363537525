import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import React from 'react';
import styled from '@mui/system/styled';
import { TypeBackground } from '@mui/material/styles/createPalette';
import { SxProps, useTheme } from '@mui/material/styles';

const Section = styled('section')({}); // Grants access to the sx prop

export interface StPageSectionProps {
  children?: React.ReactNode;
  maxWidth?: React.ComponentProps<typeof Container>['maxWidth'];
  variant?: keyof TypeBackground;
  backgroundImage?: string;
  noTopPadding?: true;
  noBottomPadding?: boolean;
  sx?: SxProps;
}

export function StPageSection(props: StPageSectionProps) {
  const {
    children,
    maxWidth,
    variant = 'default',
    backgroundImage,
    noTopPadding,
    noBottomPadding,
    sx,
  } = props;

  const theme = useTheme();

  let padding = theme.spacing(25, 0);
  if (noTopPadding && noBottomPadding) {
    padding = theme.spacing(0, 0);
  } else if (noTopPadding) {
    padding = theme.spacing(0, 0, 25, 0);
  } else if (noBottomPadding) {
    padding = theme.spacing(25, 0, 0, 0);
  }

  return (
    <Section
      className="page-section"
      sx={{
        // Allows you to use \n to wrap text in titles
        whiteSpace: 'pre-line',
        padding,
        // This tricky guy will add padding on smaller breakpoints ONLY if the component
        // is the highest parent. Will not apply padding to any nested <StPageSection /> components.
        '&:not(.page-section .page-section)': { px: { xs: 5} },
        color: variant === 'dark' ? 'background.default' : undefined,
        backgroundColor: `background.${variant}`,
        backgroundImage: backgroundImage && `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        ...sx,
      }}
    >
      <Container maxWidth={maxWidth ?? 'md'} component={Box}>
        {children}
      </Container>
    </Section>
  );
}
