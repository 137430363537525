import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

interface ImageCardProps extends Pick<HTMLImageElement, 'src' | 'alt'> {
  title: string;
  subtitle: string;
}

export function ImageCard(props: ImageCardProps & React.ComponentProps<typeof Stack>) {
  const { title, subtitle, src, alt, ...stackProps } = props;

  return (
    <Stack direction="column" {...stackProps}>
      <img src={src} alt={alt} width="100%" />
      <Box
        sx={{
          height: '100%',
          backgroundColor: (theme) => theme.palette.background.default,
          padding: (theme) => theme.spacing(4),
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Typography variant="h6" sx={{ textAlign: 'left', fontWeight: 'bold', mb: 1 }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'left' }}>
          {subtitle}
        </Typography>
      </Box>
    </Stack>
  );
}
