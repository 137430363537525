import React from 'react';
import { ScoreBar, ScoreBarData, ErrorHandle } from 'components';
import { norm } from 'utility';
import { useLanguage } from 'components/util/Language';
import { useSelector } from 'react-redux';
import { AppState } from 'sterling-redux/store';

export interface CategoryScores {
  category_code: string;
  category_label: string;
  category_label_fr: string;
  total_score: number;
  max_score: number;
}

export function CategoryBar(props: {
  categoryDataA?: CategoryScores[];
  categoryDataB?: CategoryScores[];
}) {
  const lang = useLanguage();
  const { unitCode, assessmentCode } = useSelector((state: AppState) => state.activeSelections);
  const { categoryDataA, categoryDataB } = props;
  if (!categoryDataA || !categoryDataB) {
    return <ErrorHandle error="Module Bar received invalid data." />;
  }

  const data: ScoreBarData[] = categoryDataA.map((cat) => {
    const matchCat = categoryDataB.find((c) => c.category_code === cat.category_code);
    return {
      description: { EN: cat.category_label, FR: cat.category_label_fr }[lang],
      value: norm(cat.total_score, cat.max_score),
      compValue: norm(matchCat?.total_score ?? 0, matchCat?.max_score ?? 0),
      link: `/my-performance/performance-report/${unitCode}/${assessmentCode}/${cat.category_code}`,
    };
  });

  return <ScoreBar data={data} />;
}
