import { Grid } from '@mui/material';
import Link from '@mui/material/Link';
import { HomeDiv } from '../home/styled.Home';

function HaccSterlingEnd () {
  return (
    <HomeDiv id="home-screen">
    <section className="intro">
      <div className="hacc content wide mb-0">
        <Grid container>
          <Grid item xs={12}>
            <h1 style={{ marginBottom: '20px' }}>
              End of Service
            </h1>
            <h3 style={{ marginBottom: '20px' }}>
              The Hyundai Centre of Excellence dealer program has
              concluded its operations as of this time.
            </h3>
            <p>
              For questions about the co-op program or the reporting tool, please reach out to{' '}
              <Link href="mailto:marketing@hyundaicanada.com">marketing@hyundaicanada.com</Link>.
            </p>
          </Grid>
        </Grid>
      </div>
    </section>
  </HomeDiv>
  )
}

export default HaccSterlingEnd
