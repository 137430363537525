import React from 'react';
import clsx from 'clsx';
import CircularProgress from '@mui/material/CircularProgress';
import { Analytics } from 'components/util/AnalyticEvents';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';


const DEBUG = false;

export const useLoadingStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

export function Loading(props: { debug?: string; styleOverride?: React.CSSProperties; prompt?: string }) {
  const classes = useLoadingStyles();
  const newStyle = props.styleOverride ? props.styleOverride : {};
  Analytics.LoadTime(props.debug, React.useRef(true), React.useRef(performance.now()));
  return (
    <div className={clsx(classes.root)} style={newStyle}>
      {DEBUG && props.debug ? <p>Debug:{props.debug}</p> : <CircularProgress />}
      {props.prompt && <p>{props.prompt}</p>}
    </div>
  );
}
