import { Box } from '@mui/material';
import React from 'react';
import {useHotkeys} from 'react-hotkeys-hook';


export function KbdShortcut(props: {left?: boolean; char: string, hideChar?: boolean, displayChar?: string, onTap?: () => void, children?: React.ReactNode, inline?: true}) {
  const {char, left, hideChar, displayChar, children, onTap, inline} = props;
  const noop = () => (null);
  useHotkeys(char, onTap || noop, [onTap || noop]);
  if (!children) return <></>
  if (!char) return <>{children}</>

  const kbdStyle: React.CSSProperties = {
    whiteSpace: 'nowrap',
    border: '1px solid #aaa',
    borderStyle: 'outset',
    borderRadius: '4px',
    padding: '0px 0.3em 0px 0.3em',
    margin: '0px 0px 0px 0px',
    verticalAlign: 'baseline',
    lineHeight: '1.4em',
    background: '#f4f4f4',
    display: 'inline-block',
    position: 'absolute',
    transform: inline ? 'translate(20%, 20%)' : 'translate(-50%, -50%)',
    userSelect: 'none',
    opacity: hideChar ? 0 : 1,
};
  return (
    <Box component="span" >
      {left ? <kbd style={kbdStyle}>{ displayChar ?? char.toUpperCase()}</kbd> : null }
      {children}
      {!left ? <kbd style={kbdStyle}>{ displayChar ?? char.toUpperCase()}</kbd> : null}
    </Box>
  )
}
