import { createContext, useReducer, Dispatch, useState } from 'react';
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { ComplianceReportState, ReportFormState } from 'suites/compliance/app/business-logic';

interface ConfirmChecklistFormState {
  confirmImpact?: Record<'CHECKLIST' | 'MARKETING' | 'IMPACT', boolean>;
}

type ComplianceReportAction =
  | 'SUBMIT_AN_INFRACTION'
  | 'CHECKLIST_CONFIRMED'
  | 'FORM_SUBMITTED'
  | 'BACK_TO_FORM'
  | 'FORM_VERIFIED'
  | 'BACK_TO_LANDING'
  | 'SUBMIT_REPORT';

const complianceReportStateReducer = (
  state: ComplianceReportState,
  action: ComplianceReportAction
): ComplianceReportState => {
  switch (action) {
    case 'SUBMIT_AN_INFRACTION':
      return 'CONFIRM_CHECKLIST';
    case 'CHECKLIST_CONFIRMED':
    case 'BACK_TO_FORM':
      return 'REPORT_FORM';
    case 'FORM_SUBMITTED':
      return 'VERIFY_FORM';
    case 'FORM_VERIFIED':
      throw new Error('Code for what to do when everything done goes here');
    case 'BACK_TO_LANDING':
      return 'REPORT_LANDING';
    case 'SUBMIT_REPORT':
      return 'REPORT_SUBMITTED';
  }
};

interface ComplianceReportContext {
  reportState: ComplianceReportState;
  dispatchReportAction: Dispatch<ComplianceReportAction>;
  form?: UseFormReturn<ReportFormState>;
  onFormSubmit?: SubmitHandler<ReportFormState>;
  checklistForm?: UseFormReturn<ConfirmChecklistFormState>;
  newCaseId?: string;
  setNewCaseId?: Dispatch<React.SetStateAction<string | undefined>>;
}

const defaultContext: ComplianceReportContext = {
  reportState: 'REPORT_LANDING',
  dispatchReportAction: () => undefined,
};

export const ComplianceReportContext = createContext<ComplianceReportContext>(defaultContext);

export const useComplianceReport = () => {
  const [reportState, dispatchReportAction] = useReducer(
    complianceReportStateReducer,
    defaultContext.reportState
  );

  const form = useForm<ReportFormState>({ shouldUnregister: false });
  const checklistForm = useForm<ConfirmChecklistFormState>({ shouldUnregister: false });

  const onFormSubmit: SubmitHandler<ReportFormState> = () => {
    dispatchReportAction('FORM_SUBMITTED');
  };

  const [newCaseId, setNewCaseId] = useState<string>();

  return {
    reportState,
    dispatchReportAction,
    form,
    onFormSubmit,
    checklistForm,
    setNewCaseId,
    newCaseId,
  };
};
