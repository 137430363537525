import React from 'react';
import { AppState } from 'sterling-redux/store';
import { useSelector, useDispatch } from 'react-redux';
import { setAssessmentCode } from 'sterling-redux/reducers/activeSelections';
import { Loading, ErrorHandle } from 'components';
import { useSterlingQuery } from 'generated';
import { useLanguage } from 'components/util/Language';
import { DropdownSelector, DropdownSelectorProps } from './templates/DropdownSelector';

export function SelectAssessment(props: {
  setFunction?: DropdownSelectorProps['setFunction'];
  initialValue?: DropdownSelectorProps['initialValue'];
}) {
  const lang = useLanguage();
  const { setFunction, initialValue } = props;

  const { assessmentCode } = useSelector((state: AppState) => ({
    assessmentCode: state?.activeSelections?.assessmentCode,
  }));
  const dispatch = useDispatch();

  const sterlingQuery = useSterlingQuery();

  if (sterlingQuery.loading) return <Loading debug="sterlingQuery" />;
  if (sterlingQuery.error) return <ErrorHandle gqlError={sterlingQuery.error} />;
  if (!sterlingQuery.data) return <ErrorHandle error="Invalid data." />;

  return (
    <DropdownSelector
      id="select-assessment"
      dropdownOptions={sterlingQuery.data.assessmentMany.map((assessment) => {
        return {
          label: lang === 'EN' ? assessment.title : assessment.title_fr,
          value: assessment.code,
        };
      })}
      initialValue={initialValue || assessmentCode}
      formHelperText={{ EN: `Assessment Period`, FR: `Période d’évaluation` }[lang]}
      setFunction={
        setFunction || ((newAssessmentCode: string) => dispatch(setAssessmentCode(newAssessmentCode)))
      }
    />
  );
}
