import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, FieldError } from 'react-hook-form';
import { StInputWrapper } from 'suites/sterling/atomics/inputs/InputWrapper';
import { StRadioGroup } from 'suites/sterling/molecules/inputs/radio-group/RadioGroup';
import { StTextInput } from 'suites/sterling/molecules/inputs/text/TextInput';
import { StDropzone } from 'suites/sterling/molecules/widgets/dropzone/Dropzone';
import { ComplianceReportContext } from '../../ComplianceSubmitReport.model';
import { StPageSection } from 'suites/sterling/molecules/sections/page/PageSection';
import dayjs from 'dayjs';
import {
  COMPLIANCE_CASE_CATEGORIES,
  dateSort,
  goToFormAnchor,
  mockReportFormState,
} from 'suites/compliance/app/business-logic';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import { useAuthorization } from 'suites/sterling/app/context/authorization';
import { StSelect } from 'suites/sterling/molecules/inputs/select/Select';
import { ErrorHandle, Button } from 'components';
import { useComplianceCasesManyLazyQuery, useUnitsManyQuery } from 'generated';
import { FormCaseCard } from './subcomponents/form-case-card/FormCaseCard';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { complianceAppContent as c } from 'suites/compliance/ComplianceApp.content';
import { useComplianceParams } from 'suites/compliance/app/hooks/useComplianceParams'

export function ReportForm() {
  const t = useTranslation();
  const { form, onFormSubmit, dispatchReportAction } = useContext(ComplianceReportContext);
  const { token } = useAuthorization();
  const { caseId, clearParam } = useComplianceParams();
  const [updateExisting, setUpdateExisting] = useState(false);
  const myDealerCodes = token?.coe_role_permissions;

  const unitsQuery = useUnitsManyQuery();
  const dealersToReport = unitsQuery.data?.unitsMany.filter(
    (un) => myDealerCodes?.includes(un.code) !== true
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fetchCases, cases] = useComplianceCasesManyLazyQuery();

  if (!onFormSubmit || !form) {
    throw Error('Invalid form state received');
  }

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = form;
  const caseCategory = watch('caseCategory');
  // const caseOngoing = watch('caseOngoing');
  const evidence = watch('caseEvidence') ?? [];

  const caseType = caseId ? 'UPDATE' : 'NEW';

  useEffect(() => {
    const defaultDealer = myDealerCodes?.[0];
    if (defaultDealer) {
      form.setValue('reporterDealerCode', defaultDealer);
    }
    form.setValue('caseDate', new Date().toUTCString());
  }, []);

  useEffect(() => {
    if (caseId) {
      fetchCases().then((payload) => {
        const existingCase = payload.data?.complianceCasesMany.find(
          (aCase) => aCase.caseId === caseId
        );

        // Only allow to modify a case that is set as RETURNED
        if (existingCase && existingCase.status === 'RETURNED') {
          // slice() because payload data is read-only
          const caseInformationList = existingCase.caseInformation
            .slice()
            .sort(dateSort('DESC'));
          const caseInformation = caseInformationList?.[0];
          if (!caseInformation) {
            throw Error('No existing case information found');
          }
          form.setValue('caseSummary', caseInformation.summary);
          form.setValue('caseCategory', caseInformation.category);
          form.setValue('caseDate', caseInformation.infractionDate);
          form.setValue('caseLocation', caseInformation.infractionLocation);
          form.setValue('caseOngoing', caseInformation.ongoing);
          form.setValue('caseOtherCategory', caseInformation.categoryOther);
          form.setValue('reportedDealerCode', existingCase.reported.dealerCode);
          form.setValue('reporterEmail', existingCase.reporter.email);
          form.setValue('reporterName', existingCase.reporter.name);
          setUpdateExisting(true);
          // If attempting to modify any other kind of case
          // Clear the form and clear the query param
        } else {
          form.reset();
          clearParam('caseId');
        }
      });
    }
  }, [caseId]);

  useEffect(() => {
    if (errors) {
      const errorField = Object.keys(errors)?.[0] as keyof typeof errors | undefined;
      if (errorField && !window.location.href.includes(errorField)) {
        goToFormAnchor(errorField, caseId);
      }
    }
  }, [errors]);

  if (token?.compliance_role !== 'DEALER' || !myDealerCodes || myDealerCodes.length === 0) {
    return (
      <ErrorHandle error="Unable to determine your dealership.  Please contact support." />
    );
  }

  if (!dealersToReport || dealersToReport.length === 0) {
    return <ErrorHandle error="Unable to load dealerships.  Please contact support." />;
  }

  const evidenceSizeMB = Math.round(
    evidence.reduce((acc, curr) => {
      return acc + curr.size;
    }, 0) /
      (1024 * 1024)
  );

  const evidenceNumFiles = evidence.length;

  return (
    <StPageSection variant="paper" noTopPadding>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Stack spacing={4} sx={{ maxWidth: '90%' }}>
          <Typography variant="h2">{t(c.pages.submitReport.form.titleTop)}</Typography>

          <FormCaseCard>
            <Typography variant="subtitle1">
              {t(c.pages.submitReport.form.intro.title)}
            </Typography>
            <Typography variant="body2">{t(c.pages.submitReport.form.intro.text)}</Typography>
          </FormCaseCard>

          <FormCaseCard>
            <Controller
              name="reporterName"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => {
                return (
                  <StTextInput
                    disabled={updateExisting}
                    largeLabel
                    id="reporterName"
                    label={t(c.pages.submitReport.form.formFields.reporterName.label)}
                    tooltip={t(c.pages.submitReport.form.formFields.reporterName.tooltip)}
                    placeholder={t(
                      c.pages.submitReport.form.formFields.reporterName.placeholder
                    )}
                    value={value}
                    setValue={onChange}
                    error={!!errors.reporterName}
                    helperText={errors.reporterName?.message}
                  />
                );
              }}
              rules={{
                required: t({ EN: 'Required', FR: 'Requis' }),
              }}
            />
          </FormCaseCard>

          <FormCaseCard>
            <Controller
              name="reporterDealerCode"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <StSelect
                    disabled={updateExisting}
                    largeLabel
                    id="reporterDealerCode"
                    label={t(c.pages.submitReport.form.formFields.reporterDealerCode.label)}
                    tooltip={t(
                      c.pages.submitReport.form.formFields.reporterDealerCode.tooltip
                    )}
                    value={value}
                    setValue={onChange}
                    options={token?.coe_role_permissions.map((k) => ({ label: k, value: k }))}
                    error={!!errors.reporterDealerCode}
                    helperText={errors.reporterDealerCode?.message}
                  />
                );
              }}
            />
          </FormCaseCard>

          <FormCaseCard>
            <Controller
              name="reporterEmail"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => {
                return (
                  <StTextInput
                    disabled={updateExisting}
                    largeLabel
                    id="reporterEmail"
                    label={t(c.pages.submitReport.form.formFields.reporterEmail.label)}
                    tooltip={t(c.pages.submitReport.form.formFields.reporterEmail.tooltip)}
                    placeholder={t(
                      c.pages.submitReport.form.formFields.reporterEmail.placeholder
                    )}
                    value={value}
                    setValue={onChange}
                    error={!!errors.reporterEmail}
                    helperText={errors.reporterEmail?.message}
                  />
                );
              }}
              rules={{
                required: t({ EN: 'Required', FR: 'Requis' }),
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Must be email format',
                },
              }}
            />
          </FormCaseCard>

          <Typography variant="h5" pt={10}>
            {t(c.pages.submitReport.form.titleMiddle)}
          </Typography>

          <FormCaseCard>
            <Controller
              name="reportedDealerCode"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => {
                return (
                  <StSelect
                    disabled={updateExisting}
                    largeLabel
                    id="reportedDealerCode"
                    label={t(c.pages.submitReport.form.formFields.reportedDealerCode.label)}
                    tooltip={t(
                      c.pages.submitReport.form.formFields.reportedDealerCode.tooltip
                    )}
                    value={value}
                    setValue={onChange}
                    options={dealersToReport.map((deal) => ({
                      label: `${deal.name} (${deal.code})`,
                      value: deal.code,
                    }))}
                    error={!!errors.reportedDealerCode}
                    helperText={errors.reportedDealerCode?.message}
                  />
                );
              }}
              rules={{
                required: t({ EN: 'Required', FR: 'Requis' }),
              }}
            />
          </FormCaseCard>

          <FormCaseCard>
            <Controller
              name="caseCategory"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <StRadioGroup
                    largeLabel
                    row
                    id="caseCategory"
                    label={t(c.pages.submitReport.form.formFields.caseCategory.label)}
                    tooltip={t(c.pages.submitReport.form.formFields.caseCategory.tooltip)}
                    value={value}
                    setValue={onChange}
                    error={!!errors.caseCategory}
                    helperText={errors.caseCategory?.message}
                    options={COMPLIANCE_CASE_CATEGORIES.map((cat) => ({
                      label: t(c.categories[cat]),
                      value: cat,
                    }))}
                  />
                );
              }}
              rules={{
                required: t({ EN: 'Required', FR: 'Requis' }),
              }}
            />
          </FormCaseCard>

          {caseCategory === 'OTHER' && (
            <FormCaseCard>
              <Controller
                name="caseOtherCategory"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => {
                  return (
                    <StTextInput
                      largeLabel
                      id="caseOtherCategory"
                      label={t(c.pages.submitReport.form.formFields.caseOtherCategory.label)}
                      tooltip={t(
                        c.pages.submitReport.form.formFields.caseOtherCategory.tooltip
                      )}
                      placeholder={t(
                        c.pages.submitReport.form.formFields.caseOtherCategory.placeholder
                      )}
                      width="512px"
                      value={value}
                      setValue={onChange}
                      error={!!errors.caseOtherCategory}
                      helperText={errors.caseOtherCategory?.message}
                    />
                  );
                }}
                rules={{
                  required: caseCategory === 'OTHER' && t({ EN: 'Required', FR: 'Requis' }),
                }}
              />
            </FormCaseCard>
          )}

          <FormCaseCard>
            <Controller
              name="caseOngoing"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <StRadioGroup
                    largeLabel
                    row
                    id="caseOngoing"
                    label={t(c.pages.submitReport.form.formFields.caseOngoing.label)}
                    tooltip={t(c.pages.submitReport.form.formFields.caseOngoing.tooltip)}
                    value={value}
                    setValue={onChange}
                    error={!!errors.caseOngoing}
                    helperText={errors.caseOngoing?.message}
                    options={[
                      {
                        label: t(c.pages.submitReport.form.formFields.caseOngoing.options.yes),
                        value: 'YES',
                      },
                      {
                        label: t(c.pages.submitReport.form.formFields.caseOngoing.options.no),
                        value: 'NO',
                      },
                      {
                        label: t(
                          c.pages.submitReport.form.formFields.caseOngoing.options.unsure
                        ),
                        value: 'UNSURE',
                      },
                    ]}
                  />
                );
              }}
              rules={{
                required: t({ EN: 'Required', FR: 'Requis' }),
              }}
            />
          </FormCaseCard>


          {/* <FormCaseCard>
            <Controller
              name="caseDate"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => {
                return (
                  <StInputWrapper
                    largeLabel
                    id="caseDate"
                    label={t(c.pages.submitReport.form.formFields.caseDate.label)}
                    tooltip={t(c.pages.submitReport.form.formFields.caseDate.tooltip)}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker />
                    </LocalizationProvider>
                  </StInputWrapper>
                );
              }}
              rules={{
                required: t({ EN: 'Required', FR: 'Requis' }),
              }}
            />
          </FormCaseCard> */}


          <FormCaseCard>
            <Controller
              name="caseDate"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => {
                return (
                  <StInputWrapper
                    largeLabel
                    id="caseDate"
                    label={t(c.pages.submitReport.form.formFields.caseDate.label)}
                    tooltip={t(c.pages.submitReport.form.formFields.caseDate.tooltip)}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // inputFormat="MM/dd/yyyy"
                        value={dayjs(value)}
                        onChange={onChange}
                        // minDate={addYears(Date.now(), -1)}
                        // maxDate={Date.now()}
                        // renderInput={(params: TextFieldProps) => (
                        //   <TextField
                        //     sx={{ width: 200 }}
                        //     {...params}
                        //     error={!!errors.caseDate}
                        //     helperText={errors.caseDate?.message}
                        //   />
                        // )}
                      />
                    </LocalizationProvider>
                  </StInputWrapper>
                );
              }}
              rules={{
                required: t({ EN: 'Required', FR: 'Requis' }),
              }}
            />
          </FormCaseCard>

          <FormCaseCard>
            <Controller
              name="caseLocation"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => {
                return (
                  <StTextInput
                    disabled={updateExisting}
                    largeLabel
                    id="caseLocation"
                    label={t(c.pages.submitReport.form.formFields.caseLocation.label)}
                    tooltip={t(c.pages.submitReport.form.formFields.caseLocation.tooltip)}
                    placeholder={t(
                      c.pages.submitReport.form.formFields.caseLocation.placeholder
                    )}
                    value={value}
                    setValue={onChange}
                    error={!!errors.caseLocation}
                    helperText={errors.caseLocation?.message}
                  />
                );
              }}
              rules={{
                required: t({ EN: 'Required', FR: 'Requis' }),
              }}
            />
          </FormCaseCard>



          <Typography variant="h5" pt={10}>
            {t(c.pages.submitReport.form.titleBottom)}
          </Typography>

          <FormCaseCard>
            <Controller
              name="caseSummary"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => {
                return (
                  <StTextInput
                    largeLabel
                    id="caseSummary"
                    width="100%"
                    label={t(c.pages.submitReport.form.formFields.caseSummary.label)}
                    tooltip={t(c.pages.submitReport.form.formFields.caseSummary.tooltip)}
                    placeholder={t(
                      c.pages.submitReport.form.formFields.caseSummary.placeholder
                    )}
                    value={value}
                    setValue={onChange}
                    error={!!errors.caseSummary}
                    helperText={
                      errors.caseSummary?.message ||
                      `${t(c.pages.submitReport.form.errorMessages.minLength)} (${
                        value.length
                      }/100)`
                    }
                    multiline
                    rows={8}
                  />
                );
              }}
              rules={{
                required: t({ EN: 'Required', FR: 'Requis' }),
                minLength: {
                  value: 100,
                  message: t(c.pages.submitReport.form.errorMessages.minLength),
                },
                maxLength: {
                  value: 5000,
                  message: t(c.pages.submitReport.form.errorMessages.maxLength),
                },
              }}
            />
          </FormCaseCard>

          <FormCaseCard>
            <Controller
              name="caseEvidence"
              control={control}
              defaultValue={[]}
              render={({ field: { onChange } }) => {
                return (
                  <StInputWrapper
                    largeLabel
                    id="caseEvidence"
                    label={t(c.pages.submitReport.form.formFields.caseEvidence.label)}
                    tooltip={t(c.pages.submitReport.form.formFields.caseEvidence.tooltip)}
                    error={!!errors.caseEvidence}
                    helperText={
                      (errors.caseEvidence as unknown as FieldError)?.message ||
                      `${t(
                        c.pages.submitReport.form.errorMessages.max10FilesAnd100MB
                      )} (${evidenceNumFiles}/10 & ${evidenceSizeMB}/100MB)`
                    }
                  >
                    <StDropzone
                      label={t(
                        c.pages.submitReport.form.formFields.caseEvidence.dropzoneLabel
                      )}
                      setFiles={onChange}
                      files={evidence}
                      thumbnailHandler={() => undefined}
                    />
                  </StInputWrapper>
                );
              }}
              rules={{
                validate: {
                  mineOne: () =>
                    evidenceNumFiles > 0 ||
                    t(c.pages.submitReport.form.errorMessages.min1File),
                  maxTen: () =>
                    evidenceNumFiles < 10 ||
                    t(c.pages.submitReport.form.errorMessages.max10Files),
                  max100MB: () =>
                    evidenceSizeMB < 100 ||
                    t(c.pages.submitReport.form.errorMessages.max100MB),
                },
              }}
            />
          </FormCaseCard>

          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Button
              variant="text"
              onClick={() => {
                form.reset();
                dispatchReportAction('BACK_TO_LANDING');
              }}
            >
              {t(c.pages.submitReport.form.buttonCancelLabel)}
            </Button>

            {process.env.REACT_APP_DEBUG_MODE === 'true' && (
              <Button
                onClick={() => {
                  form.setValue('caseCategory', mockReportFormState.caseCategory);
                  form.setValue('caseDate', mockReportFormState.caseDate);
                  form.setValue('caseOngoing', mockReportFormState.caseOngoing);
                  form.setValue('caseOtherCategory', mockReportFormState.caseOtherCategory);
                  form.setValue('caseSummary', mockReportFormState.caseSummary);
                  form.setValue('reportedDealerCode', mockReportFormState.reportedDealerCode);
                  form.setValue('reporterEmail', mockReportFormState.reporterEmail);
                  form.setValue('reporterName', mockReportFormState.reporterName);
                }}
              >
                [Debug] Autofill
              </Button>
            )}
            <Button type="submit" color="primary">
              {t(c.pages.submitReport.form.buttonSubmitLabel[caseType])}
            </Button>
          </Stack>
        </Stack>
      </form>
    </StPageSection>
  );
}
