import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import React, { Fragment } from 'react';
import ListItemText from '@mui/material/ListItemText';
import { NavLink, useRouteMatch } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import { LanguageLabel, Translation } from 'suites/sterling/app/types';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { OemLogo } from '../logos/oem/OemLogo';
import styled from '@mui/system/styled';
import { StHeader } from '../headers/Header';
import Box from '@mui/material/Box';
import { useComplianceHistory } from 'suites/compliance/app/hooks/useComplianceHistory';

const LogoWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  height: '180px',
});

export interface MenuItem {
  label: Translation | LanguageLabel;
  to: string;
  icon?: React.ReactNode;
  children?: MenuItem[];
}

export interface StDrawerProps {
  options: MenuItem[];
}

const drawerWidth = 340;

export function StDrawer(props: StDrawerProps) {
  const { options } = props;

  const t = useTranslation();
  const match = useRouteMatch();
  const { push } = useComplianceHistory();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const makeNavItem = (opt: MenuItem, idx: number): React.ReactNode =>
    // List item composed of a router link based on Material's guidelines
    // https://material-ui.com/guides/composition/#link
  ( <Fragment key={`${opt.label}${idx}`}>
    {opt.to ? (
        <ListItem
          button
          key={`${opt.label}${idx}-item`}
          component={NavLink}
          to={`${match.url}${opt.to}`}
          exact
          activeClassName="active-menu-item"
          onClick={() => setMobileOpen(false)}
          >
          {opt.icon && <ListItemIcon>{opt.icon}</ListItemIcon>}
          <ListItemText
            primaryTypographyProps={{ variant: 'body1' }}
            primary={t(opt.label)}
            />
        </ListItem>
      ): (
        <ListItem
        key={`${opt.label}${idx}item`}
        onClick={() => setMobileOpen(false)}
        sx={{cursor: 'default'}}
        >
        {opt.icon && <ListItemIcon>{opt.icon}</ListItemIcon>}
        <ListItemText
          primaryTypographyProps={{ variant: 'body1' }}
          primary={t(opt.label)}
          />
      </ListItem>
      )}
      {opt.children && (
        <List
          sx={{
            paddingBottom: 0,
            '.MuiListItem-root': {
                marginLeft: (theme) => theme.spacing(4),
            },
            '.active-menu-item': {
              backgroundColor: 'secondary.main',
              color: 'secondary.contrastText',
              '& .MuiListItemText-primary': {
                fontWeight: 'bold',
              },
            },
          }}
        >
          {opt.children.map(makeNavItem)}
        </List>
      )}
    </Fragment>
  )

  const drawer = (
    <nav>
      <a
      aria-label='Company label'
        href="/"
        onClick={(e) => {
          // CLicking logo returns to landing page
          e.preventDefault();
          push('');
        }}
      >
        <LogoWrapper>
          <OemLogo width="75%" />
        </LogoWrapper>
      </a>
      <List
        sx={{
          '.active-menu-item': {
            backgroundColor: 'secondary.main',
            color: 'secondary.contrastText',
            '& .MuiListItemText-primary': {
              fontWeight: 'bold',
            },
          },
        }}
      >
        {options.map(makeNavItem)}
      </List>
    </nav>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <StHeader handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },

            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block', position: 'fixed' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
