import { LanguageLabel, Translation } from 'suites/sterling/app/types';

const titleSterling: Translation = {
  DEFAULT: {
    EN: 'Sign in to the Program.',
    FR: 'Se connecter au Programme.',
  },
};

const titleCompliance: Translation = {
  DEFAULT: {
    EN: 'Sign in to the marketing compliance program.',
    FR: 'S’inscrire au programme de conformité aux normes de marketing.',
  },
};

const titleAdherence: Translation = {
  DEFAULT: {
    EN: 'Sign in to the Brand Adherence Program.',
    FR: 'Se connecter au Programme d’adhésion à la marque.',
  },
};

const submitButton: Translation = {
  DEFAULT: {
    EN: 'Sign In',
    FR: 'Se connecter',
  },
};

const supportMessage: Translation = {
  DEFAULT: {
    EN: 'Trouble signing in? or can’t remember your login?',
    FR: 'Vous ne parvenez pas à vous connecter? Vous avez oublié vos identifiants? ',
  },
};

const supportMessageLinkText: Translation = {
  DEFAULT: {
    EN: 'Contact Support',
    FR: 'Veuillez contacter l’assistance technique.',
  },
};

const supportMessageLink: Translation = {
  DEFAULT: {
    EN: '',
    FR: '',
  },
  HACC: {
    EN: 'hyundai.support@blvdagency.com',
    FR: 'hyundai.assistance@blvdagency.com',
  },
  MMSC: {
    EN: 'mitsubishi.support@blvdagency.com',
    FR: 'mitsubishi.assistance@blvdagency.com',
  },
};

const username: LanguageLabel = {
  EN: 'Username',
  FR: 'Nom d’utilisateur',
};

const password: LanguageLabel = {
  EN: 'Password',
  FR: 'Mot de passe',
};

export const loginFormContent = {
  terms: {
    username,
    password,
  },
  title: {
    sterling: titleSterling,
    compliance: titleCompliance,
    adherence: titleAdherence,
  },
  submitButton,
  supportMessage,
  supportMessageLinkText,
  supportMessageLink,
};
