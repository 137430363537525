import Box from '@mui/system/Box';
import { ReactComponent as TooltipIcon } from 'assets/images/icons/compliance/Tooltip.svg';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

export function StTooltip(props: { label: string }) {
  const { label } = props;

  return (
    <Box sx={{ width: 20, height: 20 }}>
      <Tooltip title={label}>
        <IconButton sx={{ padding: 0 }} size="large">
          <TooltipIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
