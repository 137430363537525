import { Compliance_Cases } from 'generated';
import { useHistory, useLocation } from 'react-router-dom';

type ParamKey = Extract<keyof Compliance_Cases, 'caseId'>;

type ComplianceState = {
  [key in ParamKey]: Compliance_Cases[ParamKey] | null;
};

export const useComplianceParams = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const complianceState: ComplianceState = {
    caseId: params.get('caseId'),
  };

  const setParam = <T extends ParamKey>(key: T, value: string | number) => {
    params.set(key, String(value));
    history.push({ pathname: location.pathname, search: params.toString() });
  };

  const clearParam = (key: ParamKey) => {
    params.delete(key);
    history.push({ pathname: location.pathname, search: params.toString() });
  };

  return { ...complianceState, setParam, clearParam };
};
