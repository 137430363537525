import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { LabelValue } from "suites/sterling/app/types";
import React from "react";
import {
  StInputWrapper,
  StInputWrapperProps,
} from "suites/sterling/atomics/inputs/InputWrapper";

export interface CheckboxProps extends StInputWrapperProps {
  options: LabelValue[];
  setValueMap?: (newValueMap: Record<string, boolean>) => void;
  valueMap?: Record<string, boolean>;
  row?: boolean;
}

export function StCheckboxGroup(props: CheckboxProps) {
  const { setValueMap, options, valueMap, row, ...rest } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueMap?.({ ...valueMap, [event.target.name]: event.target.checked });
  };

  return (
    <StInputWrapper {...rest}>
      <FormGroup row={row}>
        {options.map((op, idx) => {
          return (
            <FormControlLabel
              key={idx}
              control={
                <Checkbox
                  checked={valueMap?.[op.value]}
                  onChange={handleChange}

                  name={`${op.value}`}
                />
              }
              label={op.label}
              componentsProps={{ typography:  {variant: 'button'}}}
            />
          );
        })}
      </FormGroup>
    </StInputWrapper>
  );
}
