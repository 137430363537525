import { LanguageLabel, SuiteCode, Translation } from 'suites/sterling/app/types';
import complianceLoginImage from 'assets/images/login/Compliance-Login-Image.png';
import adherenceLoginImage from 'assets/images/login/Adherence-Login-Image.jpg';
import haccLoginImage from 'assets/images/login/HACC-Login-Image.png';
import mmscLoginImage from 'assets/images/login/MMSC-Login-Image.png';

interface CardText {
  title: Translation;
  subtitle: Translation;
}

interface SuiteContent {
  title: Translation;
  subtitle: Translation;
  description: Translation;
  selectLoginType: Translation;
  oemCard: CardText;
  sterlingCard: CardText;
  imageSrc: Translation;
}

const DEFAULT_NA: LanguageLabel = {
  EN: 'N/A',
  FR: 'N/A',
};

const DEFAULT_EMPTY: LanguageLabel = {
  EN: '',
  FR: '',
};

const oemCard: CardText = {
  title: {
    DEFAULT: DEFAULT_NA,
    HACC: {
      EN: 'Hyundai Credentials.',
      FR: 'Identifiants Hyundai.',
    },
    MMSC: {
      EN: 'Mitsubishi Credentials.',
      FR: 'Identifiants Mitsubishi.',
    },
  },
  subtitle: {
    DEFAULT: DEFAULT_NA,
    HACC: {
      EN: 'Use your organizational account provided by Hyundai Canada to login.',
      FR: 'Pour vous connecter, veuillez utiliser les identifiants de votre compte de concession fournis par Hyundai Canada.',
    },
    MMSC: {
      EN: 'Use your MiDealer Portal login provided by Mitsubishi Motors Sales Canada to login.',
      FR: 'Pour vous connecter, veuillez utiliser les identifiants du portail MiDealer fournis par Mitsubishi Motors Sales Canada.',
    },
  },
};

const sterlingCardPortal: CardText = {
  title: {
    DEFAULT: DEFAULT_NA,
    HACC: {
      EN: 'CoE Credentials.',
      FR: 'Identifiants CdE.',
    },
    MMSC: {
      EN: 'CoE Credentials.',
      FR: 'Identifiants CdE.',
    },
  },
  subtitle: {
    DEFAULT: DEFAULT_NA,
    HACC: {
      EN: 'Use your account information provided to you by the Centre of Excellence Program.',
      FR: 'Veuillez utiliser les identifiants fournis par le programme du Centre de l’Excellence.',
    },
    MMSC: {
      EN: 'Use your account information provided to you by the Centre of Excellence Program.',
      FR: 'Veuillez utiliser les identifiants fournis par le programme du Centre de l’Excellence.',
    },
  },
};

const sterlingCardCompliance: CardText = {
  title: {
    DEFAULT: DEFAULT_NA,
    HACC: {
      EN: 'Compliance Credentials.',
      FR: 'Identifiants de conformité.',
    },
    MMSC: DEFAULT_NA,
  },
  subtitle: {
    DEFAULT: DEFAULT_NA,
    HACC: {
      EN: 'Use your account information provided to you by the marketing compliance program.',
      FR: 'Veuillez utiliser les identifiants fournis par le Programme de conformité aux normes de marketing.',
    },
    MMSC: DEFAULT_NA,
  },
};

const sterlingCardAdherence: CardText = {
  title: {
    DEFAULT: DEFAULT_NA,
    HACC: {
      EN: 'CoE Credentials.',
      FR: 'Identifiants du program',
    },
    MMSC: DEFAULT_NA,
  },
  subtitle: {
    DEFAULT: DEFAULT_NA,
    HACC: {
      EN: 'Use your account information provided to you by the Brand Adherence Program.',
      FR: 'Veuillez utiliser les identifiants fournis par Programme d’adhésion à la marque.',
    },
    MMSC: DEFAULT_NA,
  },
};

const PORTAL: SuiteContent = {
  title: {
    DEFAULT: {
      EN: 'Welcome',
      FR: 'Bienvenue',
    },
  },
  subtitle: {
    DEFAULT: DEFAULT_NA,
    HACC: {
      EN: 'Your Centre of Excellence experience begins here.',
      FR: 'Découvrez ici le Centre de votre Excellence.',
    },
    MMSC: {
      EN: 'Your Centre of Excellence experience begins here.',
      FR: 'Découvrez ici le Centre de votre Excellence.',
    },
  },
  description: {
    DEFAULT: DEFAULT_EMPTY,
  },
  selectLoginType: {
    DEFAULT: {
      EN: 'Select a login type to continue.',
      FR: 'Choisissez comment vous identifier pour continuer.',
    },
  },
  oemCard,
  sterlingCard: sterlingCardPortal,
  imageSrc: {
    DEFAULT: DEFAULT_EMPTY,
    HACC: {
      EN: haccLoginImage,
      FR: haccLoginImage,
    },
    MMSC: {
      EN: mmscLoginImage,
      FR: mmscLoginImage,
    },
  },
};

const ADHERENCE: SuiteContent = {
  title: {
    DEFAULT: {
      EN: 'The Brand Adherence Program',
      FR: 'Programme d’adhésion à la marque.',
    },
  },
  subtitle: {
    DEFAULT: DEFAULT_NA,
    HACC: {
      EN: `Hyundai Canada has built a hub for dealers to find everything they need to provide a consistent and high-quality customer experience in alignment with the Hyundai Brand.`,
      FR: `Hyundai Canada a créé une plaque tournante où les concessionnaires peuvent trouver tout ce dont ils ont besoin pour offrir une expérience client constante et de haute qualité en alignement avec la marque Hyundai.`,
    },
    MMSC: {
      EN: 'Your Centre of Excellence experience begins here.',
      FR: 'Découvrez ici le Centre de votre Excellence.',
    },
  },
  description: {
    DEFAULT: DEFAULT_EMPTY,
  },
  selectLoginType: {
    DEFAULT: {
      EN: 'Select a login type to continue.',
      FR: 'Choisissez comment vous identifier pour continuer.',
    },
  },
  oemCard,
  sterlingCard: sterlingCardAdherence,
  imageSrc: {
    DEFAULT: DEFAULT_EMPTY,
    HACC: {
      EN: adherenceLoginImage,
      FR: adherenceLoginImage,
    },
  },
};

const COMPLIANCE: SuiteContent = {
  title: {
    DEFAULT: DEFAULT_NA,
    HACC: {
      EN: 'The Hyundai marketing compliance program.',
      FR: 'Le programme de conformité aux normes de marketing de Hyundai.',
    },
  },
  subtitle: {
    DEFAULT: DEFAULT_NA,
    HACC: {
      EN: 'Dealer reporting tool.',
      FR: 'Outil de signalement des concessionnaires.',
    },
  },
  description: {
    DEFAULT: DEFAULT_NA,
    HACC: {
      EN: 'Hyundai Canada has built a hub for dealers to report and stay updated with the infractions reported while head office ensures fairness.',
      FR: 'Hyundai Canada a créé un lieu où les concessionnaires peuvent signaler les infractions et obtenir des mises à jour sur la façon dont le siège social veille au traitement équitable de tous.',
    },
  },
  selectLoginType: {
    DEFAULT: {
      EN: 'Select a login type to continue.',
      FR: 'Choisissez comment vous identifier pour continuer.',
    },
  },

  oemCard,
  sterlingCard: sterlingCardCompliance,
  imageSrc: {
    DEFAULT: DEFAULT_EMPTY,
    HACC: {
      EN: complianceLoginImage,
      FR: complianceLoginImage,
    },
  },
};

export const loginScreenContent: Record<SuiteCode, SuiteContent> = {
  PORTAL,
  COMPLIANCE,
  ADHERENCE
};
