import React from 'react';
import { ScorePie, ScorePieData } from 'components';
import { percentageString } from 'utility';

export function TotalScore(props: {
  totalScore: number;
  maxScore: number;
  height?: number | string;
  fill?: string;
  fontSize?: number;
  fontWeight?: number;
  fullSizeBar?: true;
}) {
  const { totalScore, maxScore, height, fill, fontSize, fontWeight, fullSizeBar } = props;

  const innerCircleText = percentageString(totalScore, maxScore);

  const data: ScorePieData[] = [
    { innerCircleText, value: totalScore || 0.01},
    { innerCircleText, value: maxScore - totalScore},
  ];

  return <ScorePie {...{ data, height, fill, fontSize, fullSizeBar, fontWeight }} />;
}
