import Stack from '@mui/material/Stack';

import { CaseStatusChip } from 'suites/compliance/components/chips/case-status/CaseStatusChip';
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';
import { StrictComplianceCase } from 'suites/compliance/app/business-logic';

interface CaseStatusTransitionChipProps {
  from: StrictComplianceCase['status'];
  to: StrictComplianceCase['status'];
}

export function CaseStatusTransitionChip({ from, to }: CaseStatusTransitionChipProps) {
  return (
    <Stack direction="row" spacing={2}>
      <CaseStatusChip status={from} />
      <ArrowRightAlt />
      <CaseStatusChip status={to} />
    </Stack>
  );
}
