import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Button } from 'components';
import { StCheckboxGroup } from 'suites/sterling/molecules/inputs/checkbox-group/CheckboxGroup';
import { useContext } from 'react';
import { ComplianceReportContext } from '../../ComplianceSubmitReport.model';
import { Controller } from 'react-hook-form';
import { reduceToBooleanMap } from 'suites/sterling/app/utility';
import { LabelValue } from 'suites/sterling/app/types';
import { StPageSection } from 'suites/sterling/molecules/sections/page/PageSection';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { useUnitsManyQuery } from 'generated';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { complianceAppContent as c } from 'suites/compliance/ComplianceApp.content';

export function ConfirmChecklist() {
  const { dispatchReportAction, checklistForm } = useContext(ComplianceReportContext);
  const t = useTranslation();

  // This performs the dealer list query during the checklist confirmation stage
  // so that it is pre-loaded by the time the user clicks to the form
  useUnitsManyQuery();

  if (!checklistForm) return null;

  const { control, watch } = checklistForm;

  const confirmImpactState = watch('confirmImpact');

  const confirmImpact: LabelValue[] = [
    { label: t(c.pages.submitReport.checklist.checkboxLabelChecklist), value: 'CHECKLIST' },
    {
      label: t(c.pages.submitReport.checklist.checkboxLabelMarketing),
      value: 'MARKETING',
    },
    {
      label: t(c.pages.submitReport.checklist.checkboxLabelImpact),
      value: 'IMPACT',
    },
  ];

  return (
    <StPageSection variant="paper" noTopPadding>
      <Stack direction="column" alignItems="flex-start" gap={10}>
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          {t(c.pages.submitReport.checklist.title)}
        </Typography>

        <Typography variant="subtitle2">{t(c.pages.submitReport.checklist.text)}</Typography>

        <Stack direction="column" alignItems="flex-start" gap={2}>
          <a href={t(c.infractionChecklistLink)} target="_blank" rel="noopener noreferrer">
            <Button variant="text" startIcon={<PictureAsPdfIcon />}>
              {t(c.pages.submitReport.checklist.linkChecklistLabel)}
            </Button>
          </a>

          <a
            href={t(c.pages.landing.sectionC.cardB.marketingPolicyLink)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="text" startIcon={<PictureAsPdfIcon />}>
              {t(c.pages.submitReport.checklist.linkMarketingLabel)}
            </Button>
          </a>
        </Stack>

        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {t(c.pages.submitReport.checklist.titleDoYouUnderstand)}
        </Typography>

        <Controller
          name="confirmImpact"
          control={control}
          defaultValue={reduceToBooleanMap(confirmImpact)}
          render={({ field: { onChange, value } }) => {
            return (
              <StCheckboxGroup
                id="input-confirmImpact"
                valueMap={value}
                setValueMap={onChange}
                options={confirmImpact}
              />
            );
          }}
        />

        {confirmImpactState?.CHECKLIST &&
          confirmImpactState?.MARKETING &&
          confirmImpactState?.IMPACT && (
            <Stack direction="row" justifyContent="space-between" alignSelf="stretch">
              <Button variant="text" onClick={() => dispatchReportAction('BACK_TO_LANDING')}>
                {t(c.pages.submitReport.checklist.linkCancel)}
              </Button>
              <Button
                color="primary"
                onClick={() => dispatchReportAction('CHECKLIST_CONFIRMED')}
              >
                {t(c.pages.submitReport.checklist.buttonReportLabel)}
              </Button>
            </Stack>
          )}
      </Stack>
    </StPageSection>
  );
}
