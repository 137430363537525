import Stack from '@mui/material/Stack';
import { CaseCard } from 'suites/compliance/components/cards/case/CaseCard';
import Typography from '@mui/material/Typography';
import { complianceAppContent as c } from 'suites/compliance/ComplianceApp.content';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { CaseStatsUpdateType } from 'suites/compliance/app/business-logic';
import { CaseStatusTransitionChip } from 'suites/compliance/components/chips/case-status-transition/CaseStatusTransitionChip';
import { Compliance_CasesStatusTransition } from 'generated';

export interface CaseUpdateConfirmationCardProps {
  updateType: CaseStatsUpdateType;
  onClose: () => void;
  transition: Compliance_CasesStatusTransition;
}

export function CaseUpdateConfirmationCard({
  updateType,
  onClose,
  transition,
}: CaseUpdateConfirmationCardProps) {
  const t = useTranslation();

  return (
    <CaseCard
      title={
        <Typography
          variant="subtitle1"
          component={Stack}
          direction="row"
          alignItems="center"
          gap={1}
        >
          <CheckCircleOutlineIcon sx={{ mr: 1 }} />
          {t(c.components.caseUpdateConfirmation[updateType].title)}
        </Typography>
      }
      titleOpposite={
        <IconButton color="inherit" aria-label="close alert" onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      }
      noDivider
      noBorder
    >
      <Stack direction="column" alignItems="flex-start" gap={5}>
        <Typography variant="caption">
          {t(c.components.caseUpdateConfirmation[updateType].text)}
        </Typography>

        <CaseStatusTransitionChip {...transition} />
      </Stack>
    </CaseCard>
  );
}
