import styled, { css } from 'styled-components';
import { OemTheme } from 'assets/styles/themes/functions';
import { accordianStyles } from 'components/general/accordian/styled.Accordian';
import { colours } from 'assets/styles/themes/resources';
import { IE11Fix } from 'assets/styles/themes/mixins';

export const AppFontStyles = styled.div`
  font-size: 16px;
  font-family: ${`${OemTheme.font.regular}, 'Helvetica', 'sans-serif'`};

  p,
  a,
  span,
  label,
  input,
  text,
  small,
  button,
  h1,
  h2,
  h3 {
    font-family: ${`${OemTheme.font.regular}, 'Helvetica', 'sans-serif'`};
  }

  h1,
  h2 {
    font-family: ${`${OemTheme.font.headerBold}, 'Helvetica', 'sans-serif'`};
  }

  h3 {
    font-family: ${`${OemTheme.font.headerMedium}, 'Helvetica', 'sans-serif'`};
  }

  p {
    font-size: 20px;
  }

  .p16 {
    p,
    li {
      font-size: 16px;
    }
  }

  text {
    font-size: 14px;
  }

  small {
    font-size: 12px;
  }

  a,
  span {
    font-size: inherit;
  }

  a {
    color: ${colours.black};
    text-decoration: underline;
    outline: 0;
  }

  input[type='password'] {
    font-family: Helvetica, sans-serif;
  }
`;

export const AppStyledWrapper = styled.div`
  ${accordianStyles};
  .main-wrapper {
    flex-wrap: nowrap;
  }

  @media (min-width: 2200px) {
    .main-wrapper {
      .nav-grid {
        max-width: 380px;
        flex-basis: 380px;
      }

      .content-wrapper {
        max-width: calc(100% - 380px);
        flex-basis: calc(100% - 380px);
      }
    }
  }

  .mobile-nav {
    width: 100%;
    height: 100%;

    .arrow {
      font-size: 20px;
      font-weight: bold;
    }

    .back-to {
      span {
        color: black;
        font-size: 14px;
        opacity: 0.6;
      }
    }
  }

  .mobile-nav-header {
    background-color: white;
    width: 100%;
    border-bottom: 1px solid #eee;
    margin-bottom: 0;
    min-height: 85px;
    position: fixed;
    z-index: 10;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .nav-grid {
    position: sticky;
    z-index: 1;
    background-color: ${OemTheme.colour.nav.background};
    top: 0;
    height: 100vh;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    min-width: 300px;

    ::-webkit-scrollbar {
      width: 0 !important;
    }

    ${IE11Fix(
      css`
        height: 100% !important;
      `
    )}
  }

  .remove-trailing-period {
    &:after {
      content: '';
    }
  }

  /* .MuiCircularProgress-root {
    width: 28px !important;
    height: 28px !important;
    max-width: 28px;
    max-height: 28px;
  } */

  .MuiCircularProgress-svg {
    color: ${OemTheme.colour.active};
    fill: ${OemTheme.colour.active};
  }

  @media (max-width: 959px) {
    .content .MuiGrid-spacing-xs-10 > .MuiGrid-item {
      padding-bottom: 0 !important;
      padding-top: 30px !important;
    }
  }
`;
