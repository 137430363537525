import { ErrorHandle, Loading } from 'components';
import { usePrivateRouteQuery } from 'generated';
import { gql } from '@apollo/client';
import React from 'react';
import { Redirect, RouteProps, Route } from 'react-router-dom';

// eslint-disable-next-line
gql`
  query privateRoute($path: String!) {
    routePermission(path: $path)
  }
`;

function RedirectToHome() {
  return <Redirect to="/" />;
}

export function PrivateRoute(props: RouteProps) {
  const { path } = props;
  const stringPath = Array.isArray(path) ? path[0] : path || '';
  const pathWithoutParams = stringPath.replace(/:.+$/, '');
  const privateQuery = usePrivateRouteQuery({
    variables: { path: pathWithoutParams },
  });

  if (privateQuery.loading) return <Loading />;
  if (privateQuery.error)
    return (
      <>
        <ErrorHandle gqlError={privateQuery.error} />
        <Route {...props} component={RedirectToHome} />
      </>
    );
  if (!privateQuery.data) {
    return <ErrorHandle error="Invalid data (PrivateRoute)." />;
  }

  const permission = privateQuery.data.routePermission;

  if (!permission) {
    return <Route {...props} component={RedirectToHome} />;
  }

  return <Route {...props} />;
}
