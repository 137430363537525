import { PageShell } from 'suites/sterling/molecules/shells/page/PageShell';
import { StPageSection } from 'suites/sterling/molecules/sections/page/PageSection';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import complianceQuestionMarkFocus from 'assets/images/compliance/Fairness-Illustration-Question_Mark-Focus.png';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { complianceAppContent as c } from 'suites/compliance/ComplianceApp.content';
import { ContentSection } from 'suites/sterling/molecules/sections/content/ContentSection';
import { LargeIconWithText } from 'suites/sterling/components/large-icon-with-text/LargeIconWithText';

function BasicAccordion({ num, Q, A }: { num: number; Q: string; A: string }) {
  return (
    <Accordion elevation={0} sx={{ border: '1px solid rgba(0, 0, 0, 0.3)' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${num}a-content`}
        id={`panel${num}a-header`}
      >
        <Typography variant="body2" sx={{ p: 3 }}>
          {Q}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2" sx={{ p: 5 }}>
          {A}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export function ComplianceSupport() {
  const t = useTranslation();

  return (
    <PageShell variant="paper">
      <ContentSection
        variant="paper"
        noBottomPadding
        title={`${t(c.pages.support.menuLabel)}.`}
        subtitle={t(c.pages.support.subtitle)}
      >
        <img
          alt="Question-Mark-Focus"
          src={complianceQuestionMarkFocus}
          width="100%"
          height="auto"
          style={{ display: 'block' }}
        />
      </ContentSection>

      <ContentSection noTopPadding>
        <ContentSection variant="dark" title={t(c.pages.support.contacting)}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            gap={10}
            justifyContent="space-around"
            sx={{ width: '70%', margin: 'auto' }}
          >
            <LargeIconWithText
              iconKey="Email"
              title={t(c.pages.support.email.label)}
              subtitle={t(c.pages.support.email.description)}
              content={
                <Link
                  sx={{
                    color: (theme) => theme.palette.background.default,
                    textDecoration: 'underline',
                  }}
                  href={`mailto:${t(c.pages.support.email.address)}`}
                >
                  <Typography variant="subtitle1">
                    {t(c.pages.support.email.address)}
                  </Typography>
                </Link>
              }
            />
            <LargeIconWithText
              iconKey="Call"
              title={t(c.pages.support.telephone.label)}
              subtitle={t(c.pages.support.telephone.description)}
              content={
                <Link
                  sx={{
                    color: (theme) => theme.palette.background.default,
                    textDecoration: 'underline',
                  }}
                  href={`tel:${t(c.pages.support.telephone.number)}`}
                >
                  <Typography variant="subtitle1">
                    {' '}
                    {t(c.pages.support.telephone.number)}
                  </Typography>
                </Link>
              }
            />
          </Stack>
        </ContentSection>
      </ContentSection>

      <StPageSection variant="default">
        <Stack direction="column" alignItems="center" gap={10}>
          <Typography variant="h3"> {t(c.pages.support.frequentlyAsked.title)}</Typography>

          <Typography variant="subtitle1">
            {t(c.pages.support.frequentlyAsked.subtitle)}
          </Typography>

          <Stack direction="column" gap={2}>
            {Object.entries(c.faq).map((entry, idx) => {
              const qAndA = entry[1];
              return <BasicAccordion key={idx} num={idx + 1} Q={t(qAndA.Q)} A={t(qAndA.A)} />;
            })}
          </Stack>
        </Stack>
      </StPageSection>
    </PageShell>
  );
}
