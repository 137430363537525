import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { LabelValue } from "suites/sterling/app/types";
import React from "react";
import {
  StInputWrapper,
  StInputWrapperProps,
} from "suites/sterling/atomics/inputs/InputWrapper";

export interface RadioProps extends StInputWrapperProps {
  options: LabelValue[];
  value?: string;
  setValue?: (newValue: string) => void;
  row?: boolean;
}

export function StRadioGroup(props: RadioProps) {
  const { value, setValue, options, row, ...rest } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue?.(event.target.value);
  };

  return (
    <StInputWrapper {...rest}>
      <RadioGroup
        row={row}
        aria-label={props.label}
        name={props.label}
        value={value}
        onChange={handleChange}
      >
        {options.map((op) => {
          return (
            <FormControlLabel
              key={op.value}
              value={`${op.value}`}
              label={op.label}
              control={<Radio color="primary" />}
            />
          );
        })}
      </RadioGroup>
    </StInputWrapper>
  );
}
