import React from 'react';
import styled from 'styled-components';
import { SortOrder } from 'generated';

import { OemTheme } from 'assets/styles/themes/functions';

const SortDiv = styled.div`
  display: flex;
  margin: 0 0 0 4px;
  flex-direction: column;
  justify-content: center;
  user-select: none;

  .active,
  .inactive {
    line-height: 7px;
    font-size: 7px;
  }

  .active {
    color: ${OemTheme.colour.indicator.active};
  }

  .inactive {
    color: ${OemTheme.colour.indicator.inactive};
  }
`;

export function SortIndicator(props: { active: boolean; sortOrder?: SortOrder }) {
  const { active, sortOrder } = props;
  return (
    <SortDiv>
      <span className={active && sortOrder === 'ASC' ? 'active' : 'inactive'}>&#9650;</span>
      <span className={active && sortOrder === 'DESC' ? 'active' : 'inactive'}>&#9660;</span>
    </SortDiv>
  );
}
