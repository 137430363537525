import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { ClientRoute } from 'routing/Routes';
import { useLanguage } from 'components/util/Language';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';

const getNextPath = (content: ClientRoute): string => {
  if (content.noContent && content.children?.[0]) {
    return getNextPath(content.children[0]);
  }
  return content.path;
};

export function NavBuilder(props: {
  setMobileOpen?: (arg: boolean) => void;
  content: ClientRoute;
  nestLevel: number;
}) {
  const { content, nestLevel, setMobileOpen } = props;
  const location = useLocation();
  const lang = useLanguage();

  const currentPath = lang === 'FR' && content.pathFr ? content.pathFr : content.path;

  const highlighted = location.pathname.includes(currentPath);

  const [open, setOpen] = React.useState(highlighted);

  useEffect(() => {
    if (highlighted) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [location.pathname, highlighted]);

  const renderLink = (path: string, hasChildren: boolean) => (
    <Link
      to={path}
      className={clsx(
        'nav-link',
        'nav-link-sub',
        `nested${nestLevel}`,
        open && 'nested-selected'
      )}
      onClick={setMobileOpen && !hasChildren ? () => setMobileOpen(false) : () => null}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: lang === 'FR' && content.titleFr ? content.titleFr : content.title,
        }}
      />
    </Link>
  );

  if (content.children && content.children.length > 0) {
    const nextPath = getNextPath(content);

    return (
      <>
        {renderLink(nextPath, true)}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {content.children.map((childContent, index) => (
              <NavBuilder
                key={index}
                content={childContent}
                nestLevel={nestLevel + 1}
                setMobileOpen={setMobileOpen}
              />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return renderLink(currentPath, false);
}
