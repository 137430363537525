import { gql, useApolloClient, useLazyQuery } from "@apollo/client";
import { useAuthorization } from "suites/sterling/app/context/authorization";
import Cookies from "js-cookie";

const removeToken = (): void => Cookies.remove("token");

const LOGOUT_QUERY = gql`
  query logout {
    validateLogout: logout {
      success
    }
  }
`;

export const useLogout = () => {
  const { refreshAuth } = useAuthorization();
  const client = useApolloClient();

  const [logout] = useLazyQuery(LOGOUT_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.validateLogout?.success) {
        removeToken();
        refreshAuth();
        // Unlike resetStore, clearStore will not refetch cached queries
        client.clearStore();
        // Forces out all query cache
        client.cache.gc();
      }
    },
  });

  return { logout };
};
