import { ThemeOptions } from '@mui/material/styles';
import 'assets/font-loaders/sterling-fonts.css';

const FALLBACK_FONTS = 'Arial, sans-serif' as const;
export const withFallbacks = (font: string) => `${font}, ${FALLBACK_FONTS}`;

declare module '@mui/material/styles' {
  interface CommonColors {
    black: string;
    white: string;
    error: string;
    warning: string;
    caution: string;
    success: string;
  }
}

export const GRAPH_COLORS = {
  // graph palette
  peach: '#FFBF9E',
  lightTan: '#E8D9C9',
  mint: '#C8E8D8',
  mediumGrey: '#D6D6D6',
  pink: '#FFC1D1',
  greyBlue: '#A4BBD5',
  lightPurple: '#D4C4FF',
  pastelGreen: '#AEDAA2',
  lightBrown: '#C5B5A9',
};
export const STERLING_COLOURS = {
  black: '#000000',
  white: '#ffffff',
  green: '#4CAF50',
  tba: '#ffff00',
  bruiseBlack: '#1e1328',
  seafoam: '#abc2bb',
  lightGrey: '#e5e4e4',
  lead: '#5c6770',
  // bootstrap dialogue palette
  success: '#7BC67E',
  warning: 'rgba(249, 171, 23, 0.6)',
  caution: '#E6A56A',
  error: '#C26666',
  ...GRAPH_COLORS,
} as const;

export const COMMON_COLORS = {
  error: STERLING_COLOURS.error,
  warning: STERLING_COLOURS.warning,
  caution: STERLING_COLOURS.caution,
  success: STERLING_COLOURS.success,
};
const sterlingThemeOptions: ThemeOptions = {
  custom: {
    border: {
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: STERLING_COLOURS.black,
    },
    drawer: {
      width: 'auto',
    },
    appBar: {
      height: '64px',
    },
    navLogo: {
      paddingTop: 105,
    },
    inputLabel: {
      fontSize: 14,
    },
  },
  shape: {
    borderRadius: 0,
  },
  spacing: 4,
  palette: {
    common: {
      ...COMMON_COLORS,
    },
    primary: {
      main: STERLING_COLOURS.bruiseBlack,
      contrastText: STERLING_COLOURS.white,
    },
    secondary: {
      main: STERLING_COLOURS.seafoam,
      contrastText: STERLING_COLOURS.bruiseBlack,
    },
    info: {
      main: STERLING_COLOURS.lead,
      contrastText: STERLING_COLOURS.white,
    },
    success: {
      main: STERLING_COLOURS.green,
      contrastText: STERLING_COLOURS.black,
    },
    warning: {
      main: STERLING_COLOURS.black,
      contrastText: STERLING_COLOURS.white,
    },
    background: {
      default: STERLING_COLOURS.white,
      paper: STERLING_COLOURS.lightGrey,
      dark: STERLING_COLOURS.bruiseBlack, // Custom
      sterling: STERLING_COLOURS.seafoam,
    },
  },
  typography: {
    fontFamily: withFallbacks('Helvetica Now Regular'),
    fontSize: 16,
    body1: {
      lineHeight: 1.4,
    },
    body2: {
      lineHeight: 1.4,
    },
    subtitle1: {
      lineHeight: 1.4,
    },
    subtitle2: {
      lineHeight: 1.4,
    },
    caption: {
      lineHeight: 1.4,
    },
    overline: {
      lineHeight: 1.4,
    },
    button: {
      fontSize: 20,
      lineHeight: 1.4,
      textTransform: 'none',
    },
    h1: {
      lineHeight: 1.4,
    },
    h2: {
      lineHeight: 1.4,
    },
    h3: {
      lineHeight: 1.4,
    },
    h4: {
      lineHeight: 1.4,
    },
    h5: {
      lineHeight: 1.4,
    },
    h6: {
      lineHeight: 1.4,
    },
  },
};

export default sterlingThemeOptions;
