import React from 'react';
import { gql } from '@apollo/client';
import { useRankedDealersSegmentedQuery } from 'generated';
import { AppState } from 'sterling-redux/store';
import { useSelector } from 'react-redux';
import { Loading, ErrorHandle } from 'components';
import { RankedList } from './templates/RankedList';

// eslint-disable-next-line
gql`
  query rankedDealersSegmented($assessmentCode: String!, $filterText: String!) {
    assessmentSummaryManyGroup(
      assessmentCode: $assessmentCode
      filterRole: false
      filterText: $filterText
      groupBy: "UNIT_CODE"
      sortBy: "FINAL_SCORE"
      sortOrder: "DESC"
      filterBy: "REGION_CODE"
      pageNum: 0
      pageSize: 10
    ) {
      edges {
        review_details {
          unit_name
          region_code
        }
        final_score
      }
    }
  }
`;

export function RankedDealersSegmented(props: {
  segment: { value: string; name: string };
}) {
  const { segment } = props;
  const  assessmentCode  = useSelector((state: AppState) =>
    state.activeSelections.assessmentCode,
  );

  const rankedDealersSegmentedQuery = useRankedDealersSegmentedQuery({
    variables: {
      assessmentCode,
      filterText: segment.value,
    },
  });

  if (rankedDealersSegmentedQuery.loading) {
    return <Loading debug="Loading rankedDealersSegmentedQuery" />;
  }
  if (rankedDealersSegmentedQuery.error) {
    return <ErrorHandle gqlError={rankedDealersSegmentedQuery.error} />;
  }

  return (
    <RankedList
      title={segment.name}
      list={rankedDealersSegmentedQuery.data?.assessmentSummaryManyGroup.edges.map((rev) => ({
        unit_name: rev.review_details.unit_name,
      }))}
      fieldName="unit_name"
      numToDisplay={10}
    />
  );
}
