import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { StPageSection } from 'suites/sterling/molecules/sections/page/PageSection';
import { PageShell } from 'suites/sterling/molecules/shells/page/PageShell';

export function RestrictedApp() {

  return (
    <PageShell>
      <StPageSection>
        <Typography variant="h4">
          You do not have permission to use this application. If you believe you are seeing
          this message in error please{' '}
          <Link href="mailto:hyundai.support@blvdagency.com">contact support</Link>
        </Typography>
      </StPageSection>
    </PageShell>
  );
}
