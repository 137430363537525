import React, { useState, ChangeEvent } from 'react';
import { useDropdownBaseStyles } from './styled.SearchDropdown';
import { t } from 'i18next';

interface DropdownOption {
  value: string;
  label: string;
}

export interface DropdownSelectorProps {
  options: DropdownOption[];
  initialValue: DropdownOption['value'];
  placeholder: string;
  clear?: boolean;
  setFunction: (arg: string) => void;
  small?: boolean;
  buttonLook?: boolean;
  noIcon?: boolean;
}

export const useSearchDropdown = (props: DropdownSelectorProps) => {
  const { initialValue, setFunction, placeholder, options, noIcon } = props;
  const [selectedValue, setSelectedValue] =
    React.useState<DropdownOption['value']>(initialValue);
  const [logValue, setLogValue] = React.useState<DropdownOption['value']>(initialValue);
  const [filterPhrase, setFilterPhrase] = useState('');
  const [isCleared, setIsCleared] = useState(false);
  const makeClasses = useDropdownBaseStyles(props.small, props.buttonLook);
  const classes = makeClasses();
  const handleChange = (option: DropdownOption | null) => {
    setFunction(option?.value ?? '');
    setSelectedValue(option?.value ?? '');
    setLogValue(option?.label ?? '');
    setIsCleared(false);
  };
  const handleClear = (clearValue: boolean | undefined) => {
    if (clearValue && !isCleared) {
      setSelectedValue('');
      setIsCleared(true);
    }
  };
  const activeOptions =
    filterPhrase === ''
      ? options
      : [
          { label: t('Clear Filter'), value: '' },
          ...options.filter((op) => op.value.toLowerCase().includes(filterPhrase)),
        ];

  return {
    selectedValue,
    logValue,
    classes,
    noIcon,
    handleClear,
    autoCompleteProps: {
      options: activeOptions,
      getOptionLabel: (option: DropdownOption) => option.label,
      classes: {
        root: classes.input,
        input: classes.innerInput,
        listbox: classes.dropdown,
        focused: classes.inputFocused,
        popupIndicator: classes.popupIndicator,
      },
      onChange: (_e: unknown, option: DropdownOption | null) => {
        if (!option) {
          setFunction('');
          handleClear(true);
        }
        if (option?.value) {
          handleChange(option);
          setFilterPhrase('');
        }
      },
    },
    textFieldProps: {
      placeholder,
      onChange: ({ target }: ChangeEvent<HTMLInputElement>) => {
        if (!target.value) {
          setFunction('');
          setSelectedValue('');
          return setIsCleared(true);
        }
        const selected = options?.find((op) => op.label === target.value);
        if (selected) {
          handleChange(selected);
          setFilterPhrase(selected.value);
          return setIsCleared(false);
        }
        setFilterPhrase(target.value);
      },
      InputLabelProps: {
        shrink: false,
      },
    },
  };
};
