import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import Visibility from '@mui/icons-material/Visibility';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { formatCaseId, StrictComplianceCase } from 'suites/compliance/app/business-logic';
import { useComplianceRole } from 'suites/compliance/app/context/compliance-role';
import { useComplianceParams } from 'suites/compliance/app/hooks/useComplianceParams';
import { CaseStatusChip } from 'suites/compliance/components/chips/case-status/CaseStatusChip';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { complianceAppContent as c } from 'suites/compliance/ComplianceApp.content';
import { CaseCard } from 'suites/compliance/components/cards/case/CaseCard';
import { AssigneeChip } from '../../chips/assignee/AssigneeChip';

// eslint-disable-next-line
export interface CaseSummaryTileProps extends StrictComplianceCase {
  hideAssigneeChip?: boolean;
}

export function CaseSummaryTile(props: CaseSummaryTileProps) {
  const { caseId, inProgress, reported, status, repeatCaseIds, hideAssigneeChip } = props;

  const { setParam } = useComplianceParams();
  const t = useTranslation();
  const { role } = useComplianceRole();

  if (!role) throw Error('Permission denied');

  const isDone = status === 'CLOSED' || status === 'RETURNED';

  return (
    <CaseCard onClick={() => setParam('caseId', caseId)}>
      <Stack
        sx={{ flexDirection: 'row' }}
        justifyContent="space-between"
        gap={3}
      >
        <Stack direction="row" gap={6}>
          <Typography variant="tableHeader" sx={{ width: (theme) => theme.spacing(30) }}>
            {t(c.terms.case)}-{formatCaseId(caseId)}
          </Typography>
          <Typography
            variant="tableBody"
            sx={{
              maxWidth: (theme) => theme.spacing(100),
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              opacity: 0.7,
              marginTop: '2px'
            }}
          >
              {reported.dealerCode} {reported.dealerName}
          </Typography>
        </Stack>

        <Stack justifyContent="flex-end">
          <Stack direction="row" gap={2}>
            {/* Dealer cannot see if a case is marked repeat */}
            {repeatCaseIds && role !== 'DEALER' && <SyncProblemIcon />}

            {/* Dealer cannot see if a case has been viewed */}
            {!isDone && inProgress && role !== 'DEALER' && <Visibility />}

            {/* Dealer cannot see who a case has been assigned to */}
            {status !== 'CLOSED' && role !== 'DEALER' && !hideAssigneeChip && (
              <AssigneeChip status={status} />
            )}
            <CaseStatusChip status={status} />
          </Stack>
        </Stack>
      </Stack>
    </CaseCard>
  );
}
