import { gql } from '@apollo/client';
import {
  FetchAllConfigurationsAllFieldsDocument,
  FetchAllConfigurationsAllFieldsQuery,
  FetchAllConfigurationsAllFieldsQueryResult,
  FetchAllConfigurationsAllFieldsQueryVariables,
} from 'generated';
import { client } from 'apollo';
import { ThunkActionComplete } from 'sterling-redux/store';
import { GraphQLError } from 'graphql';
import { CurrentOem } from 'utility';
import { setError } from './activeSelections';

// eslint-disable-next-line
gql`
  query fetchAllConfigurationsAllFields(
    $type: EnumConfigurationsType!
    $oem: EnumConfigurationsOem!
  ) {
    config: configurationOne(filter: { type: $type, oem: $oem }) {
      type
      oem
      maintenance_message_en
      maintenance_message_fr
      region_label_en
      region_label_fr
      district_label_en
      district_label_fr
    }
  }
`;

// eslint-disable-next-line
gql`
  query segmentsMany {
    segmentsMany {
      hierarchy
      type
      name
      value
      parent
    }
  }
`;

export const SET_CONFIGURATION = 'SET_CONFIGURATION';
const SET_CONFIGURATION_QUERY_ERRORS = 'SET_CONFIGURATION_QUERY_ERRORS';
const SET_CONFIGURATION_CUSTOM_ERROR = 'SET_CONFIGURATION_CUSTOM_ERROR';

type Configuration = NonNullable<FetchAllConfigurationsAllFieldsQueryResult['data']>;

export interface ConfigurationState {
  data?: Configuration;
  queryErrors?: readonly GraphQLError[];
  customError?: string;
}

interface ConfigurationAction {
  type: string;
  payload: ConfigurationState;
}

// ACTIONS

type SetConfiguration = (arg: Configuration) => ConfigurationAction;

export const setConfiguration: SetConfiguration = (data) => ({
  type: SET_CONFIGURATION,
  payload: {
    data,
  },
});

export const fetchConfiguration = (): ThunkActionComplete => async (dispatch) => {
  const result = await client.query<
    FetchAllConfigurationsAllFieldsQuery,
    FetchAllConfigurationsAllFieldsQueryVariables
  >({
    query: FetchAllConfigurationsAllFieldsDocument,
    variables: {
      type: 'STARTUP',
      oem: CurrentOem,
    },
    fetchPolicy: 'network-only',
  });

  if (result?.errors && result?.errors.length > 0) {
    dispatch({
      type: SET_CONFIGURATION_QUERY_ERRORS,
      payload: result?.errors,
    });
  } else if (result?.data && result?.data.config) {
    dispatch(setConfiguration({ ...result?.data }));
  } else {
    dispatch({
      type: SET_CONFIGURATION_CUSTOM_ERROR,
      payload: {
        customError:
          'Configuration Query result is invalid.  Check for null fields in configuration query.',
      },
    });

    dispatch(setError('API Service Unavailable.  Retrying...'));
  }
};

// REDUCER
// eslint-disable-next-line
export default function (state: ConfigurationState = {}, action: ConfigurationAction) {
  switch (action.type) {
    case SET_CONFIGURATION: {
      const { data } = action.payload;
      return {
        ...state,
        data,
      };
    }
    case SET_CONFIGURATION_QUERY_ERRORS: {
      const { queryErrors } = action.payload;
      return {
        ...state,
        data: undefined,
        queryErrors,
      };
    }
    case SET_CONFIGURATION_CUSTOM_ERROR: {
      const { customError } = action.payload;
      return {
        ...state,
        data: undefined,
        customError,
      };
    }
    default:
      return state;
  }
}
