import { useAuthorization } from "suites/sterling/app/context/authorization";
import { Role } from "suites/sterling/app/types";

export interface StRoleControllerProps {
  roles?: Role[];
  excludeRoles?: Role[];
  children?: React.ReactNode;
}

export function StRoleController(props: StRoleControllerProps) {
  const { roles, excludeRoles, children } = props;
  const { token } = useAuthorization();
  if (!token) return null;
  const { coe_role: coeRole } = token;

  if (excludeRoles?.includes(coeRole)) {
    return null;
  }

  return roles?.includes(coeRole) ? <>{children}</> : null;
}
