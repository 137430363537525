import { assigneeFromStatus, getLatestRevision, StrictComplianceCase } from 'suites/compliance/app/business-logic';
import { CaseSummaryTileGroup } from 'suites/compliance/components/tiles/case-summary/group/CaseSummaryTileGroup';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { StPageSection } from 'suites/sterling/molecules/sections/page/PageSection';
import { TitleSection } from 'suites/sterling/molecules/sections/title/TitleSection';
import { NoCasesCard } from '../../review/subcomponents/NoCasesCard';
import { complianceAppContent as c } from 'suites/compliance/ComplianceApp.content';
import { Compliance_Cases, Enumcompliance_CasesCaseInformationCategory } from 'generated';
import { CollectionContext, FilterChildCollection } from 'suites/sterling/molecules/utility/filter-child-collection/FilterChildCollection';

export interface ComplianceAdminPanelAllCasesViewProps {
  cases: StrictComplianceCase[];
}

interface FilterableCasesExtensions {
  assignee: ReturnType<typeof assigneeFromStatus>;
  reportedName: string;
  category?: Enumcompliance_CasesCaseInformationCategory;
};

// eslint-disable-next-line react/no-unused-prop-types, @typescript-eslint/no-unused-vars
function CaseCollectionWrapper(props: { listContent: (Compliance_Cases & FilterableCasesExtensions)[] }) {
  return (
    <CollectionContext.Consumer>
      {({filteredCollection})=> <CaseSummaryTileGroup cases={filteredCollection} />}
    </CollectionContext.Consumer>
  )
}

export function ComplianceAdminPanelAllCasesView(props: ComplianceAdminPanelAllCasesViewProps) {
  const { cases } = props;

  const t = useTranslation();
  const filterableCases = cases.map((_c)=>({ ..._c, assignee: assigneeFromStatus(_c.status), reportedName: _c.reported.dealerName, category: getLatestRevision(_c)?.category}));

  return (
    <>
      <TitleSection variant="paper" title={t(c.pages.adminPanel.allCases.title)} />
      <StPageSection variant="paper" noTopPadding>
        {cases.length === 0 ? <NoCasesCard /> :
        <FilterChildCollection filters={[
          {field: 'status', label: 'Case Status', width: '134px'},
          {field: 'assignee', label: 'Assignee', width: '160px'},
          {field: 'reportedName', label: 'Reported', width: '318px'},
          {field: 'category', label: 'Category', width: '138px'},
          ]}>
            <CaseCollectionWrapper listContent={filterableCases} />
          </FilterChildCollection>
          }
      </StPageSection>
    </>
  );
}
