import styled, { css } from 'styled-components';
import { colours } from 'assets/styles/themes/resources';
import { OemTheme } from 'assets/styles/themes/functions';

export const TableWrapper = styled.div<{ rowHeight?: string }>`
  overflow-x: hidden;
  border: 1px solid;
  border-radius: 6px;
  border-color: ${OemTheme.colour.default};
  background-color: ${OemTheme.colour.default};

  &.vendor-table {
    td:first-child,
    th:first-child {
      width: 200px;
    }
  }

  &.executive-summary-group-table {
    margin-top: 36px;

    td:first-child,
    th:first-child {
      width: 500px;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    text-align: left;

    th,
    td,
    a,
    p,
    span {
      font-size: 12px;
      color: ${OemTheme.colour.text.default};
      font-family: ${OemTheme.font.regular};
      text-overflow: ellipsis;
      word-wrap: break-word;
    }

    th {
      span {
        color: white;
      }
    }

    tr {
      ${({ rowHeight }: { rowHeight?: string }) =>
        css`
          border-bottom: 1px solid ${OemTheme.colour.default};
          height: ${rowHeight ?? `85px`};
        `}
    }

    thead tr:first-child {
      border: none;
    }

    th,
    td {
      padding: 0 0 0 12px;
    }

    td {
      position: relative;
    }

    td:first-child,
    th:first-child {
      width: 125px;
      padding: 0 10px 0 16px;
      margin-right: 30px;
    }

    /* Removed due to increased in # of categories for program recat ~ Alex E */
    /* td:nth-child(11),
    th:nth-child(11) {
      width: 100px;
    } */

    tbody tr:nth-child(odd) {
      background-color: ${colours.white};
    }

    tbody tr:nth-child(even) {
      background-color: ${colours.grey};
    }

    tbody tr:last-child {
      border: none;
    }

    .header-content {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      font-weight: normal;
      color: #fff;

      @media (max-width: 1280px) {
        flex-wrap: wrap;
      }
    }

    &.scroll-table {
      overflow-x: scroll;

      table {
        width: auto;
      }
    }
  }
`;
