import React from 'react';
import ArrowRight from '@mui/icons-material/ArrowRight';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import { handleEnterKey } from 'utility';
import { PaginationWrapper } from './styled.Paginator';
import {
  arrayOfPages,
  previousAndNext,
  pageValidator,
  PaginatorProps,
} from './model.Paginator';

export function Paginator(props: PaginatorProps) {
  const { currentPage, setCurrentPage } = props;
  const { previousPage, nextPage } = previousAndNext(props);

  return (
    <PaginationWrapper style={props.style}>
      <ArrowLeft className="page-arrow" onClick={previousPage} />
      <div>
        {arrayOfPages(props).map((pageNum, index) => {
          const { displayEllipsis, displayPageNumber } = pageValidator(props, pageNum);

          if (displayEllipsis) return <span key={index}>...</span>;

          if (displayPageNumber) {
            return (
              <span
                style={{
                  textDecoration: pageNum === currentPage ? 'underline' : 'none',
                }}
                onClick={() => setCurrentPage(pageNum)}
                key={index}
                onKeyPress={(e) => handleEnterKey(e, () => setCurrentPage(pageNum))}
                role="button"
                tabIndex={0}
              >
                {pageNum + 1}
              </span>
            );
          }
          return null;
        })}
      </div>

      <ArrowRight className="page-arrow" onClick={nextPage} />
    </PaginationWrapper>
  );
}
