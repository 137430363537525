import React from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import { useModalStyles, modalStyle } from 'views/pages/coe-standards/styled.Resources';
import { handleEnterKey } from 'utility';

export const useImageModal = () => {
  const classes = useModalStyles();
  const [open, setOpen] = React.useState(false);
  const [imgURL, setImgURL] = React.useState('');
  const [imgAlt, setImgAlt] = React.useState('');

  const toggleModal = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const imageOrAnchor = e.target as HTMLImageElement & HTMLAnchorElement;
    if (!imageOrAnchor) return setOpen(false);
    setImgURL(imageOrAnchor.src || imageOrAnchor.href);
    setImgAlt((e.target as HTMLImageElement).alt);
    setOpen(!open);
  };

  return {
    onImageClick: toggleModal,
    ImageModal: () => (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
          <div style={modalStyle} className={classes.paper} >
            <div className="modal-download">
              <a download={imgAlt} href={imgURL} aria-label='download link' target="_blank" rel="noopener noreferrer">
                <DownloadIcon />
              </a>
            </div>
            <div
              className="modal-close"
              role="button"
              tabIndex={0}
              onClick={toggleModal}
              onKeyDown={(e) => handleEnterKey(e, () => toggleModal(e))}
            >
              X
            </div>
            <img src={imgURL} alt={imgAlt} className="modal-img" />
          </div>
      </Modal>
    ),
  };
};
