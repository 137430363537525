import React, { ComponentProps } from 'react';
import Button from '@mui/material/Button';
import { Analytics } from 'components/util/AnalyticEvents';
import { IconName, SterlingIcon, SterlingIconProps } from 'components/general/SterlingIcon';
import { SterlingButtonWrapper } from './styled.SterlingButtons';

export interface SterlingButtonProps {
  children: JSX.Element | string;
  onClick?: (e: React.SyntheticEvent) => void;
  style?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  active?: boolean;
  form?: string;
  isDisabled?: boolean;
  buttonGroup?: boolean;
  name?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  icon?: IconName;
  id?: string;
  noHover?: boolean;
}

export function SterlingButton(props: SterlingButtonProps & ComponentProps<typeof Button>) {
  const {
    children,
    active,
    name,
    type,
    onClick,
    style,
    wrapperStyle,
    form,
    className,
    isDisabled,
    buttonGroup,
    id,
    noHover,
    ...rest
  } = props;

  const logAndDoClick = (event: React.MouseEvent) => {
    Analytics.ButtonClick(event, children, onClick);
  };

  return (
    <SterlingButtonWrapper
      buttonGroup={buttonGroup}
      active={!!active}
      style={wrapperStyle}
      noHover={noHover}
    >
      <Button
        id={id}
        name={name}
        style={style}
        className={className}
        onClick={logAndDoClick}
        type={type ?? 'button'}
        form={form}
        color="primary"
        variant="contained"
        disabled={isDisabled}
        {...rest}
      >
        {children}
      </Button>
    </SterlingButtonWrapper>
  );
}

export function SterlingIconButton(props: SterlingButtonProps & SterlingIconProps) {
  const newProps = {
    ...props,
    children: (
      <>
        <SterlingIcon title="" name={props.icon} />
        {props.children}
      </>
    ),
  };

  return (
    <SterlingButton {...newProps} wrapperStyle={{ marginRight: 'auto', marginLeft: 'auto' }} />
  );
}
