import FormControlUnstyled from '@mui/core/FormControlUnstyled';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import CancelIcon from '@mui/icons-material/Cancel';
import { StTooltip } from '../tooltips/Tooltip';
import Stack from '@mui/material/Stack';

export interface StInputWrapperProps {
  id: string;
  label?: string;
  error?: boolean;
  helperText?: React.ReactNode;
  disabled?: boolean;
  largeLabel?: boolean;
  tooltip?: string;
}

export function StInputWrapper(props: StInputWrapperProps & { children?: React.ReactNode }) {
  const { id, label, error, helperText, disabled, largeLabel, tooltip, children } = props;

  return (
    <FormControlUnstyled disabled={disabled} error={error}>
      {label && (
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start">
          <InputLabel
            htmlFor={id}
            error={error}
            sx={
              largeLabel
                ? {
                    fontFamily: (theme) => theme.typography.h1.fontFamily,
                    fontSize: 20,
                    mb: 4,
                    mr: 1,
                  }
                : {}
            }
          >
            {label}
          </InputLabel>
          {tooltip && <StTooltip label={tooltip} />}
        </Stack>
      )}

      {children}
      { helperText &&
        <FormHelperText id={id} error={error} sx={{ mt: 3 }}>
          { helperText }
        </FormHelperText>
      }
    </FormControlUnstyled>
  );
}

export function StErrorAdornment(props: { error?: boolean }) {
  const { error } = props;

  if (!error) return null;

  return (
    <InputAdornment
      position="end"
      sx={{ '& svg': { fill: (theme) => theme.palette.error.main } }}
    >
      <CancelIcon />
    </InputAdornment>
  );
}
