import { canViewAdminPanel, StrictComplianceCase } from 'suites/compliance/app/business-logic';
import { useComplianceParams } from 'suites/compliance/app/hooks/useComplianceParams';
import { useComplianceCasesManyQuery } from 'generated';
import { PageShell } from 'suites/sterling/molecules/shells/page/PageShell';
import { ComplianceAdminPanelAllCasesView } from './views/ComplianceAdminPanelAllCasesView';
import { CaseDetailPage } from '../review/subpages/case-detail/CaseDetail';
import { useComplianceRole } from 'suites/compliance/app/context/compliance-role';
import { ComplianceRestrictedRoute } from '../restricted/RestrictedRoute';

export function ComplianceAdminPanel() {
  const { caseId } = useComplianceParams();
  const casesQuery = useComplianceCasesManyQuery({ variables: { adminView: true } });
  const { role } = useComplianceRole();

  if (!casesQuery.data || !role) {
    return null;
  }

  if (!canViewAdminPanel(role)) {
    return <ComplianceRestrictedRoute />;
  }

  const CASES = casesQuery.data.complianceCasesMany as StrictComplianceCase[];

  const selectedCase = CASES.find((el) => el.caseId === caseId);

  return (
    <PageShell variant="paper">
      {selectedCase ? (
        <CaseDetailPage selectedCase={selectedCase} allowCaseManagement={false} />
      ) : <ComplianceAdminPanelAllCasesView cases={CASES} />}
    </PageShell>
  );
}
