import React from 'react';
import { ApolloError } from '@apollo/client';

export function ErrorHandle(props: {
  componentName?: string;
  error?: string;
  gqlError?: ApolloError;
  showToScreen?: boolean;
}) {
  const { componentName = '', error, gqlError, showToScreen = false } = props;
  if (error) console.error(`${componentName} Err: ${error}`);
  if (gqlError) console.error(`ApolloError:${componentName} ${gqlError}`);

  return !showToScreen ? null : (
    <div>
      <p>{componentName && `Error in ${componentName}`}</p>
      <p>{error}</p>
      <p>{gqlError && gqlError.message}</p>
      <p>
        {gqlError &&
          gqlError.graphQLErrors.map(({ message }, i) => <span key={i}>{message}</span>)}
      </p>
    </div>
  );
}
