import React from 'react';
import { gql } from '@apollo/client';
import { useRankedSegmentsQuery, SortOrder, GroupBy, useSegmentsManyQuery } from 'generated';
import { AppState } from 'sterling-redux/store';
import { useSelector } from 'react-redux';
import { Loading, ErrorHandle } from 'components';
import { percentageDisplay } from 'utility';
import { RankedList } from './templates/RankedList';

// eslint-disable-next-line
gql`
  query rankedSegments($assessmentCode: String!, $sortOrder: String!, $groupBy: String!) {
    assessmentSummaryManyGroup(
      assessmentCode: $assessmentCode
      groupBy: $groupBy
      filterRole: true
      sortBy: "FINAL_SCORE"
      sortOrder: $sortOrder
      pageNum: 0
      pageSize: 0
    ) {
      edges {
        group_id
        final_score
      }
    }
  }
`;

export function RankedSegments(props: {
  sortOrder: SortOrder;
  title: string;
  showScore: boolean;
  groupBy: GroupBy;
}) {
  const { title, sortOrder, groupBy, showScore } = props;
  const { assessmentCode } = useSelector((state: AppState) => ({
    assessmentCode: state.activeSelections.assessmentCode,
  }));

  const segmentsManyQuery = useSegmentsManyQuery();

  const rankedSegmentsQuery = useRankedSegmentsQuery({
    variables: {
      assessmentCode,
      sortOrder,
      groupBy,
    },
  });

  if (segmentsManyQuery.loading) return <Loading debug="useSegmentsManyQuery" />;
  if (segmentsManyQuery.error) return <ErrorHandle gqlError={segmentsManyQuery.error} />;

  if (rankedSegmentsQuery.loading) {
    return <Loading debug="Loading rankedSegmentsQuery" />;
  }
  if (rankedSegmentsQuery.error) {
    return <ErrorHandle gqlError={rankedSegmentsQuery.error} />;
  }

  return (
    <RankedList
      title={title}
      list={rankedSegmentsQuery.data?.assessmentSummaryManyGroup.edges.map((summary) => ({
        formatted_unit_name: showScore
          ? `${
              segmentsManyQuery.data?.segmentsMany?.find((s) => s.value === summary.group_id)
                ?.name
            } (${percentageDisplay(summary.final_score)})`
          : segmentsManyQuery.data?.segmentsMany.find((s) => s.value === summary.group_id)
              ?.name,
      }))}
      fieldName="formatted_unit_name"
      numToDisplay={100}
    />
  );
}
