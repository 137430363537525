import { OemTheme } from 'assets/styles/themes/functions';
import { colours } from 'assets/styles/themes/resources';

// Unsure how to type this
// eslint-disable-next-line
export const DropdownBaseStyle = (small?: boolean, buttonLook?: boolean): any => {
  return {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    input: {
      cursor: 'pointer !important',
      borderRadius: '4px',
      border: `1px solid ${OemTheme.colour.buttonOutline}`,
      '& svg': {
        fill: `${OemTheme.colour.buttonOutline}`,
        opacity: 0.6,
        cursor: 'pointer',
        pointerEvents: 'none',
        transition: 'none',
      },
      // '&:hover': {
      //   '& input': {
      //     color: `${OemTheme.colour.buttonOutline}`,
      //     '&::placeholder': {
      //       color: `${OemTheme.colour.buttonOutline}`,
      //       opacity: 0.4,
      //     },
      //   },
      //   '& svg': {
      //     fill: `${OemTheme.colour.buttonOutline}`,
      //   },
      // },
      '& input::placeholder': {
        color: `rgba(0,0,0,0.6)`,
        opacity: '1',
      },
      '& fieldset': {
        border: 'none !important',
        padding: '0',
      },
      '& .MuiSelect-select': {
        paddingTop: small ? '10.5px' : buttonLook ? '14px' : '15px',
        paddingBottom: small ? '10.5px' : buttonLook ? '14px' : '15px',
      },
    },
    innerInput: {
      border: 'none !important',
      borderRadius: '0 !important',
      backgroundColor: buttonLook ? 'transparent' : colours.white,
      textOverflow: 'ellipsis',
      height: OemTheme.tweaks('selectBoxHeight', 'auto'),
      fontFamily: `${OemTheme.font.regular}`,
      cursor: 'pointer',
      color: `${OemTheme.colour.buttonOutline}`,
      '&:hover, &:focus': {
        color: `${OemTheme.colour.buttonOutline}`,
      },
    },
    inputFocused: {
      border: `1px solid ${OemTheme.colour.buttonOutline}`,
      '& svg': {
        fill: `${OemTheme.colour.buttonOutline}`,
      },
      '& input::placeholder': {
        color: `${OemTheme.colour.buttonOutline}`,
      },
    },
    dropdown: {
      border: `3px solid ${OemTheme.colour.buttonOutline}`,
      borderRadius: '0',
      backgroundColor: colours.white,
      color: OemTheme.colour.text.default,
      fontFamily: OemTheme.font.regular,
      fontSize: '16px',
      transition: 'border linear 0.2s !important',
      maxHeight: '500px',
      overflowY: 'scroll',
    },
    menuItem: {
      cursor: 'pointer !important',
    },
    menuItemSelected: {
      backgroundColor: 'rgba(0, 0, 0, 0.05) !important',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.07)',
      },
    },
  };
};
