import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import {
  StErrorAdornment,
  StInputWrapper,
  StInputWrapperProps,
} from 'suites/sterling/atomics/inputs/InputWrapper';

export interface StTextInputProps extends StInputWrapperProps {
  value?: string;
  setValue?: (newValue: string) => void;
  multiline?: boolean;
  rows?: number;
  placeholder?: string;
  type?: 'text' | 'password';
  width?: string;
  maxWidth?: string;
}

export function StTextInput(props: StTextInputProps) {
  const { value, setValue, multiline, rows, placeholder, type, width, maxWidth, ...rest } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue?.(event.target.value);
  };

  return (
    <StInputWrapper {...rest}>
      <OutlinedInput
        disabled={rest.disabled}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        id={props.id}
        aria-describedby={props.id}
        endAdornment={<StErrorAdornment error={props.error} />}
        multiline={multiline}
        rows={rows}
        type={type ?? 'text'}
        sx={{ width: width || '400px', maxWidth: maxWidth ?? '100%' }}
      />
    </StInputWrapper>
  );
}
