import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  Label,
  ResponsiveContainer,
} from 'recharts';
import { GoogleTrendSegment } from 'generated';
import { OemTheme } from 'assets/styles/themes/functions';

export function SegmentedLineGraph(props: {
  trendData: GoogleTrendSegment[];
  averageInterest: number;
  lineKey: string;
  children?: JSX.Element;
}) {
  const { trendData, averageInterest, lineKey } = props;

  return (
    <>
      <ResponsiveContainer width="100%" height={350}>
        <LineChart
          width={800}
          height={250}
          data={trendData}
          margin={{ top: 5, right: 40, left: -5, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" tick={false} label={{ value: 'Last 52 weeks' }} />
          <YAxis />
          <Tooltip
            formatter={(val: unknown, name: string) => [
              `${val}%`,
              `${name}`.charAt(0).toUpperCase() + name.slice(1),
            ]}
          />
          <Line
            type="monotone"
            dataKey={lineKey}
            stroke={OemTheme.colour.focused}
            activeDot={{ r: 8 }}
          />
          <ReferenceLine
            y={averageInterest}
            label={<Label value="Avg" position="right" />}
            stroke="red"
            strokeDasharray="3 3"
          />
        </LineChart>
      </ResponsiveContainer>
      {props.children}
    </>
  );
}

export default SegmentedLineGraph;
