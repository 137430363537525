import { Button as MuiButton } from '@mui/material';
import { Analytics } from 'components/util/AnalyticEvents';
import { ComponentProps } from 'react';

export function Button(props: ComponentProps<typeof MuiButton>) {
  const { children, onClick } = props;
  const logAndDoClick = (event: React.MouseEvent) => {
    Analytics.ButtonClick(event, (children as JSX.Element | string) ?? 'Not Labeled', onClick);
  };

  return <MuiButton {...props} onClick={logAndDoClick}/>;
}
