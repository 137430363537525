import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { useComplianceHistory } from 'suites/compliance/app/hooks/useComplianceHistory';
import { Button } from 'components';
import Stack from '@mui/material/Stack';
import complianceQuestionMarkFocus from 'assets/images/compliance/Fairness-Illustration-Question_Mark-Focus.png';
import hyundaiBackgroundRoad from 'assets/images/compliance/Hyundai-Background-Road.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ContentSection } from 'suites/sterling/molecules/sections/content/ContentSection';
import { complianceAppContent as c } from 'suites/compliance/ComplianceApp.content';
import Typography from '@mui/material/Typography';

export function HaveQuestionsContentSection() {
  const t = useTranslation();
  const { push } = useComplianceHistory();

  return (
    <ContentSection noBottomPadding backgroundImage={hyundaiBackgroundRoad}>
      <ContentSection
        title={<Typography variant="h3">{t(c.haveQuestions)}</Typography>}
        subtitle={t(c.pages.support.subtitle)}
      >
        <Stack direction="column" alignItems="center" spacing={6}>
          <Button
            color="secondary"
            endIcon={<ArrowForwardIcon />}
            onClick={() => push('/support')}
            sx={{ mb: 7 }}
          >
            {t(c.pages.support.buttonText)}
          </Button>
          <img
            alt="Fairness-Illustration-Question_Mark-Focus"
            src={complianceQuestionMarkFocus}
            width="50%"
            height="100%"
          />
        </Stack>
      </ContentSection>
    </ContentSection>
  );
}
