import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'sterling-redux/store';
import { SterlingButtonGroup } from './templates/SterlingButton.Group';
import { useLanguage } from 'components/util/Language';
import { setGroupType } from 'sterling-redux/reducers/activeSelections';

export function SelectGroupType() {
  const activeGroupType = useSelector((state: AppState) => state.activeSelections.groupType);
  const dispatch = useDispatch();
  const lang = useLanguage();

  const config = useSelector((state: AppState) => state.configuration.data?.config);

  const values = [
    {
      value: 'region',
      label: { EN: config?.region_label_en, FR: config?.region_label_fr }[lang] ?? '',
    },
    {
      value: 'district',
      label: { EN: config?.district_label_en, FR: config?.district_label_fr }[lang] ?? '',
    },
    { value: 'all', label: { EN: `All`, FR: 'Tous' }[lang] },
  ];

  return (
    <SterlingButtonGroup
      currentValue={activeGroupType}
      setFunction={(val: string) => {
        dispatch(setGroupType(val));
      }}
      buttonValues={values}
      label={{ EN: 'Filter by', FR: 'Filtrer par' }[lang]}
    />
  );
}
