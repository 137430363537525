import React from 'react';
import { toTitleCase, Obj } from 'utility';
import { useLanguage } from 'components/util/Language';
import { ErrorHandle } from 'components';

export interface RankListProps {
  title: string;
  list: Obj[] | undefined;
  fieldName: string;
  numToDisplay: number;
}

export function RankedList(props: RankListProps) {
  const { title, list: propList, fieldName, numToDisplay } = props;
  const lang = useLanguage();

  if (!propList) {
    return <ErrorHandle error="Invalid list provided to RankedList." />;
  }

  return (
    <>
      <h3 className="ranked-list" style={{ textAlign: 'left' }}>
        {title}
      </h3>
      <hr style={{ width: '95%', marginLeft: 0 }} />
      {propList.length === 0 ? (
        <p>
          {lang === 'EN' ? `No review data avilable.` : `Aucune donnée d’examen disponible.`}
        </p>
      ) : (
        propList.slice(0, numToDisplay).map((listItem, index) => {
          return (
            <p key={index} className="ranked-list-text">
              {index + 1}. {toTitleCase(listItem[fieldName])}
            </p>
          );
        })
      )}
    </>
  );
}
