import { useHistory, useLocation } from 'react-router-dom';
import { LanguageCode } from 'generated';
import i18next from 'components/util/Translate';

export const useLanguageController = () => {
  const history = useHistory();
  const location = useLocation();

  const setURLLanguage = (newLang: LanguageCode) => {
    const path = location.pathname;
    const isFrench = /^\/fr/.test(path);
    i18next.changeLanguage({EN: 'en', FR: 'fr'}[newLang]);

    if (isFrench && newLang === 'EN') {
      history.push(window.location.pathname.replace(/^\/fr/, ''));
    } else if (!isFrench && newLang === 'FR') {
      history.push(`/fr${window.location.pathname}`);
    }
  };

  return { setURLLanguage };
};
