import React from 'react';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { AppState } from 'sterling-redux/store';
import { useSegmentsManyQuery } from 'generated';
import { Loading, ErrorHandle } from 'components';
import { RankedDealersSegmented } from '../lists/RankedDealersSegmented';

export function TopTenListGrid() {
  const printMode = useSelector((state: AppState) => state.activeSelections.printMode);

  const segmentsManyQuery = useSegmentsManyQuery();

  if (segmentsManyQuery.loading) return <Loading debug="useSegmentsManyQuery" />;
  if (segmentsManyQuery.error) return <ErrorHandle gqlError={segmentsManyQuery.error} />;

  return (
    <Grid container spacing={3}>
      {segmentsManyQuery.data?.segmentsMany
        .filter((seg) => seg.hierarchy === 0)
        .map((seg, index) => {
          return (
            <Grid key={`${seg.value}-${index}`} item xs={printMode ? 6 : 12} sm={6}>
              <RankedDealersSegmented segment={seg} />
            </Grid>
          );
        })}
    </Grid>
  );
}
