import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  validStatusTransitions,
  StrictComplianceCase,
  addDealerNotesForStatusUpdate,
  dateSort,
  CaseStatsUpdateType,
} from 'suites/compliance/app/business-logic';
import { Button } from 'components';
import { StTextInput } from 'suites/sterling/molecules/inputs/text/TextInput';
import { StRadioGroup } from 'suites/sterling/molecules/inputs/radio-group/RadioGroup';
import { CaseStatusTransitionChip } from 'suites/compliance/components/chips/case-status-transition/CaseStatusTransitionChip';
import { StModal } from 'suites/sterling/atomics/modals/Modal';
import { complianceAppContent as c } from 'suites/compliance/ComplianceApp.content';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import {
  Compliance_Cases,
  Compliance_CasesHistory,
  useComplianceCaseUpdateStatusMutation,
  Compliance_CasesStatusTransition,
} from 'generated';
import { useComplianceRole } from 'suites/compliance/app/context/compliance-role';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { StSelect } from 'suites/sterling/molecules/inputs/select/Select';
import { toTitleCase } from 'utility';

interface CaseUpdateModalFormState extends Omit<Compliance_CasesHistory, 'date'> {
  agree: 'YES' | 'NO';
  outcome: Compliance_Cases['outcome'] | undefined;
}

const OUTCOMES: readonly Compliance_Cases['outcome'][] = ['VIOLATION', 'ABSOLVED'] as const;

interface CaseUpdateModalProps {
  selectedCase: StrictComplianceCase;
  open: boolean;
  onClose: () => void;
  onStatusChange: (updateType: CaseStatsUpdateType) => void;
  setStatusTransition: (transition: Compliance_CasesStatusTransition) => void;
}

export function CaseUpdateModal({
  selectedCase,
  open,
  onClose,
  onStatusChange,
  setStatusTransition,
}: CaseUpdateModalProps) {
  const t = useTranslation();
  const { role } = useComplianceRole();
  const transition = selectedCase.statusTransition;

  const isHyundai = role === 'HYUNDAI' && transition;
  const mostRecentCaseNotes = selectedCase.history.slice().sort(dateSort('DESC'))?.[0];

  const form = useForm<CaseUpdateModalFormState>({
    shouldUnregister: false,
    defaultValues: { agree: 'YES' },
  });
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = form;

  const [updateCase] = useComplianceCaseUpdateStatusMutation();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const newStatus = watch('status');
  const agree = watch('agree');

  const onFormSubmit: SubmitHandler<CaseUpdateModalFormState> = async (data) => {
    setDisableSubmit(true);

    const updateResult = await updateCase({
      variables: {
        caseId: selectedCase.caseId,
        ...data,
        outcome: data.outcome ?? 'UNDETERMINED',
      },
    });

    if (!updateResult.errors) {
      setDisableSubmit(false);

      onClose();

      onStatusChange(role === 'BLVD' ? 'REQUESTED' : 'CHANGED');

      setStatusTransition({ from: selectedCase.status, to: newStatus });
    }
  };

  const showOutcome = role === 'COMMITTEE' && newStatus === 'CLOSED';

  const requiresDealerNotes = addDealerNotesForStatusUpdate(newStatus);

  const disableInputs = isHyundai && agree === 'YES';

  useEffect(() => {
    if (agree === 'YES' && transition) {
      form.setValue('dealerNotes', mostRecentCaseNotes.dealerNotes);
      form.setValue('internalNotes', mostRecentCaseNotes.internalNotes);
      form.setValue('internalNotesName', mostRecentCaseNotes.internalNotesName);
      form.setValue('status', transition.to);
    }
  }, [agree, transition]);

  // Reset the outcome if user is no longer closing the case
  useEffect(() => {
    if (showOutcome === false) {
      form.setValue('outcome', undefined);
    }
  }, [showOutcome]);

  return (
    <StModal open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Stack direction="column" alignItems="flex-start" gap={5}>
          <Typography variant="h6">{t(c.components.caseUpdateModal.title)}</Typography>
          <Typography variant="body2">
            {t(c.components.caseUpdateModal.textSummarizeReason)}
          </Typography>
          {isHyundai && (
            <>
              <CaseStatusTransitionChip {...transition} />
              <Controller
                name="agree"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <StRadioGroup
                      row
                      id="agree"
                      label={t(c.components.caseUpdateModal.textAgreeWithBLVD)}
                      value={value}
                      setValue={onChange}
                      error={!!errors.agree}
                      helperText={errors.agree?.message}
                      options={['YES', 'NO'].map((op) => ({
                        label: t(
                          c.components.caseUpdateModal.agreeWithBLVDOption[op as 'YES' | 'NO']
                        ),
                        value: op,
                      }))}
                    />
                  );
                }}
                rules={{
                  required: t({ EN: 'Required', FR: 'Requis' }),
                }}
              />
            </>
          )}

          <Controller
            name="status"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <StSelect
                  disabled={disableInputs}
                  id="status"
                  label={t(c.terms.action)}
                  value={value}
                  setValue={onChange}
                  width="300px"
                  options={validStatusTransitions(selectedCase.status).map((stat) => ({
                    label: t(c.caseStatusToAction[stat]),
                    value: stat,
                  }))}
                  error={!!errors.status}
                  helperText={errors.status?.message}
                />
              );
            }}
            rules={{
              required: t({ EN: 'Required', FR: 'Requis' }),
            }}
          />

          {showOutcome && (
            <Controller
              name="outcome"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <StRadioGroup
                    row
                    id="outcome"
                    label={t(c.components.caseUpdateModal.whatIsOutcome)}
                    value={value}
                    setValue={onChange}
                    error={!!errors.outcome}
                    helperText={errors.outcome?.message}
                    options={OUTCOMES.map((op) => ({
                      label: toTitleCase(op),
                      value: op,
                    }))}
                  />
                );
              }}
              rules={{
                required: showOutcome && t({ EN: 'Required', FR: 'Requis' }),
              }}
            />
          )}

          <Controller
            name="internalNotes"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => {
              return (
                <StTextInput
                  disabled={disableInputs}
                  id="internalNotes"
                  label={t(c.terms.internalNotes)}
                  placeholder={t(c.components.caseUpdateModal.inputPlaceholder)}
                  value={value}
                  setValue={onChange}
                  error={!!errors.internalNotes}
                  helperText={errors.internalNotes?.message}
                  multiline
                  rows={5}
                  width="565px"
                />
              );
            }}
            rules={{
              required: t({ EN: 'Required', FR: 'Requis' }),
            }}
          />

          <Controller
            name="internalNotesName"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => {
              return (
                <StTextInput
                  disabled={disableInputs}
                  id="internalNotesName"
                  label={t(c.terms.internalNotesName)}
                  placeholder={t(c.components.caseUpdateModal.inputPlaceholderName)}
                  value={value}
                  setValue={onChange}
                  error={!!errors.internalNotesName}
                  helperText={errors.internalNotesName?.message}
                  width="565px"
                />
              );
            }}
            rules={{
              required: t({ EN: 'Required', FR: 'Requis' }),
            }}
          />

          {requiresDealerNotes && (
            <Controller
              name="dealerNotes"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => {
                return (
                  <StTextInput
                    disabled={disableInputs}
                    id="dealerNotes"
                    label={t(c.terms.dealerNotes)}
                    placeholder={t(c.components.caseUpdateModal.inputPlaceholder)}
                    value={value}
                    setValue={onChange}
                    error={!!errors.dealerNotes}
                    helperText={errors.dealerNotes?.message}
                    multiline
                    rows={5}
                    width="565px"
                  />
                );
              }}
              rules={{
                required: requiresDealerNotes && t({ EN: 'Required', FR: 'Requis' }),
              }}
            />
          )}
          <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }} gap={2}>
            <Button variant="text">{t(c.components.caseUpdateModal.buttonLabelCancel)}</Button>
            <Button disabled={disableSubmit} type="submit">
              {t(c.components.caseUpdateModal.buttonLabelSubmit)}
            </Button>
          </Stack>
        </Stack>
      </form>
    </StModal>
  );
}
