import { Theme, createTheme, responsiveFontSizes, adaptV4Theme } from '@mui/material/styles';
import { colours, typography } from 'assets/styles/themes/resources';
import { css } from 'styled-components';

const config = {
  colour: {
    default: colours.black,
    focused: colours['mitsubishi-red'],
    blurred: colours.black,
    active: colours['mitsubishi-red'],
    buttonOutline: colours.black,
    titleBorder: colours['mitsubishi-red'],
    requiredDot: colours['mitsubishi-red'],
    rewardsTable: colours.black,
    tag: {
      standard: {
        text: colours.white,
        background: colours['mitsubishi-red'],
      },
      alternate: {
        text: colours.white,
        background: colours['mitsubishi-red'],
      },
    },
    calendarTile: {
      background: {
        upcoming: colours['mitsubishi-anthracite-gray'],
        upNext: colours.black,
        TBA: colours['mitsubishi-cool-gray'],
      },
      text: {
        upcoming: colours.white,
        upNext: colours.white,
        TBA: colours.black,
      },
    },
    text: {
      default: colours.black,
      anchor: colours['mitsubishi-red'],
    },
    indicator: {
      active: colours.white,
      inactive: colours['mitsubishi-cool-gray'],
    },
    background: {
      primary: colours['mitsubishi-concrete-gray'],
      secondary: colours['mitsubishi-concrete-gray'],
      toggleTrack: colours['mitsubishi-cool-gray'],
    },
    graph: {
      primary: colours['mitsubishi-anthracite-gray'],
      track: colours['mitsubishi-concrete-gray'],
      bar: {
        primary: colours['mitsubishi-anthracite-gray'],
        secondary: colours['mitsubishi-cool-gray'],
      },
    },
    nav: {
      background: colours.black,
      mobileBackground: colours.black,
      link: {
        default: colours.white,
        selected: colours.white,
        selectedOnWhite: colours['mitsubishi-red'],
        hover: colours.white,
        background: {
          selected: colours['mitsubishi-red'],
          hover: colours.black,
        },
        tertiaryText: colours['mitsubishi-anthracite-gray'],
      },
    },
    preferredVendorPage: {
      modalBackground: colours.white,
    },
  },
  font: {
    headerBold: typography['mitsubishi-bold'],
    headerMedium: typography['mitsubishi-medium'],
    light: typography['mitsubishi-regular'],
    regular: typography['mitsubishi-regular'],
    medium: typography['mitsubishi-medium'],
    bold: typography['mitsubishi-bold'],
    colonHeader: typography['mitsubishi-medium'],
    link: typography['mitsubishi-bold'],
    subNav: typography['mitsubishi-regular'],
  },
  fontSize: {
    p: '20px',
    h1: '40px',
    h2: '24px',
    h3: '20px',
  },
  mobileFontSize: {
    p: '16px',
    h1: '25px',
    h2: '19px',
    h3: '16px',
  },
  figma: {
    colour: {
      base: {
        light: colours['mitsubishi-concrete-gray'],
        lightSecondary: colours['mitsubishi-cool-gray'],
        dark: colours.black,
        accent: colours['mitsubishi-red'],
        hoverOnLight: colours.black,
        hoverOnDark: colours['mitsubishi-cool-gray'],
      },
      contrast: {
        light: colours.black,
        dark: colours.white,
        accent: colours.white,
        hoverOnLight: colours.white,
        hoverOnDark: colours.black,
      },
    },
  },
};

const mitsubishiMuiTheme: Theme = responsiveFontSizes(
  createTheme(adaptV4Theme({
    palette: {
      text: {
        // hint: config.colour.active,
        primary: colours.black,
        secondary: colours.white,
      },
    },
  }))
);

export const mmscTheme = {
  ...config,
  materialUI: mitsubishiMuiTheme,
  tweaks: {
    mainLogoHeight: '130px',
    subLogoHeight: '130px',
    page_title_top_border_css: `
      border-top: 2px solid ${colours['mitsubishi-red']};
    `,
    guide_header_margin: '105px 0 55px',
    guide_subheader_margin: '0 0 6px',
    list_css: `${css`
      /*
      margin-left: 0;margin-left
      padding-left: 1.2em;

      list-style: none;
      text-indent: -1.2em;

      :before {
        content: '\2022';
        display: block;
        float: left;
        width: 1.2em;
      }
      */
    `}`,
    navArrow: `${css`
      transform: translate(0px, 2px);
    `}`,
    newTagText: 'translate(0px, -3px)',
    expandIconPosition: `${css`
      top: 40%;
    `}`,
    selectBoxHeight: 'auto',
    buttonTextPadding: '4px',
    textVertAlign: `${css`
      transform: translateY(-10%);
    `}`,
    mainNavHoverText: colours.black,
    mainNavHoverBackground: colours['mitsubishi-concrete-gray'],
    resourceTextPositioning: `${css`
      input {
        transform: translateY(-2px);
      }

      button {
        .MuiButton-label {
          transform: translateY(-2px);
        }
      }
    `}`,
    fontBaselineFix: `${css`
      height: 1.65em;
    `}`,
    semiBoldFont: `${css`
      font-weight: 500;
    `}`,
  },
};
