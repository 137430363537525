import { useContext, useEffect } from 'react';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { ComplianceReportContext } from '../../ComplianceSubmitReport.model';
import { Button } from 'components';
import Stack from '@mui/material/Stack';
import { ImageCard } from 'suites/sterling/molecules/cards/image/ImageCard';
import { PageShell } from 'suites/sterling/molecules/shells/page/PageShell';
import { ContentSection } from 'suites/sterling/molecules/sections/content/ContentSection';
import complianceDocumentFocus from 'assets/images/compliance/Fairness-Illustration-Document-Focus.png';
import cardImage1 from 'assets/images/compliance/Fairness-Illustration-Clock-Focus.png';
import cardImage2 from 'assets/images/compliance/Fairness-Illustration-Whistle-Focus.png';
import cardImage3 from 'assets/images/compliance/Fairness-Illustration-Trophy-Focus.png';
import DownloadIcon from '@mui/icons-material/Download';
import { LargeIconWithText } from 'suites/sterling/components/large-icon-with-text/LargeIconWithText';
import hyundaiBackgroundRoad from 'assets/images/compliance/Hyundai-Background-Road.png';
import { ReactComponent as Checkmark } from 'assets/images/icons/compliance/Checkmark.svg';
import { HaveQuestionsContentSection } from 'suites/compliance/components/sections/have-questions/HaveQuestions';
import { complianceAppContent as c } from 'suites/compliance/ComplianceApp.content';
import InfractionVideoEN from 'assets/videos/compliance/HACC_Infractions_EN.webm';
import InfractionVideoFR from 'assets/videos/compliance/HACC_Infractions_FR.webm';
import { useComplianceParams } from 'suites/compliance/app/hooks/useComplianceParams';
import { useScrollToTop } from 'suites/sterling/app/hooks/useScrollToTop';
import Typography from '@mui/material/Typography';
import { useLanguage } from 'suites/sterling/app/context/language';

export function ReportLanding() {
  useScrollToTop();
  const t = useTranslation();
  const lang = useLanguage();
  const { dispatchReportAction } = useContext(ComplianceReportContext);
  const { caseId } = useComplianceParams();

  // This handles the process of skipping directly to form for returned cases
  useEffect(() => {
    if (caseId) {
      dispatchReportAction('CHECKLIST_CONFIRMED');
    }
  }, [caseId]);

  return (
    <PageShell variant="paper">
      <ContentSection
        noBottomPadding
        variant="paper"
        title={t(c.pages.submitReport.landing.sectionB.title)}
      >
        <ContentSection noBottomPadding noTopPadding>
          <video controls loop width="100%" height="auto" style={{ display: 'block' }}>
            <source src={lang === 'EN' ? InfractionVideoEN : InfractionVideoFR} />
            <track default kind="captions" />
            Sorry your browser does not support embedded videos
          </video>
        </ContentSection>
        <ContentSection
          variant="dark"
          subtitle={
            <Typography sx={{ width: '80%' }}>
              {t(c.pages.submitReport.landing.sectionB.text)}
            </Typography>
          }
        />
      </ContentSection>

      <ContentSection
        noBottomPadding
        variant="darkPaper"
        title={t(c.pages.submitReport.landing.sectionA.title)}
        subtitle={
          <Typography sx={{ width: '80%' }}>
            {t(c.pages.submitReport.landing.sectionA.subtitle)}
          </Typography>
        }
      >
        <a
          href={t(c.pages.landing.sectionC.cardB.marketingPolicyLink)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button startIcon={<DownloadIcon />} sx={{ mb: 13 }}>
            {t(c.pages.submitReport.landing.sectionA.buttonText)}
          </Button>
        </a>
        <ContentSection noBottomPadding noTopPadding>
          <img
            alt="Fairness-Illustration-Document-Focus"
            src={complianceDocumentFocus}
            width="100%"
            height="auto"
            style={{ display: 'block' }}
          />
        </ContentSection>
      </ContentSection>

      <ContentSection variant="paper" title={t(c.pages.submitReport.landing.sectionC.title)}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          gap={{ xs: 10, md: 4 }}
          justifyContent="space-around"
          alignItems={{ xs: 'center', md: 'stretch' }}
          sx={{
            width: '100%',
            margin: 'auto',
            '& > div': { width: { xs: '70%', md: '33%' } },
          }}
        >
          <ImageCard
            src={cardImage1}
            alt={t(c.pages.submitReport.landing.sectionC.cardA.title)}
            title={t(c.pages.submitReport.landing.sectionC.cardA.title)}
            subtitle={t(c.pages.submitReport.landing.sectionC.cardA.text)}
          />
          <ImageCard
            src={cardImage2}
            alt={t(c.pages.submitReport.landing.sectionC.cardB.title)}
            title={t(c.pages.submitReport.landing.sectionC.cardB.title)}
            subtitle={t(c.pages.submitReport.landing.sectionC.cardB.text)}
          />
          <ImageCard
            src={cardImage3}
            alt={t(c.pages.submitReport.landing.sectionC.cardC.title)}
            title={t(c.pages.submitReport.landing.sectionC.cardC.title)}
            subtitle={t(c.pages.submitReport.landing.sectionC.cardC.text)}
          />
        </Stack>
      </ContentSection>

      <ContentSection noBottomPadding backgroundImage={hyundaiBackgroundRoad}>
        <ContentSection
          variant="dark"
          title={t(c.pages.submitReport.landing.sectionD.title)}
          subtitle={
            <Typography sx={{ width: '80%' }}>
              {t(c.pages.submitReport.landing.sectionD.text)}
            </Typography>
          }
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            gap={10}
            justifyContent="space-around"
            sx={{ width: '70%', margin: 'auto' }}
          >
            <LargeIconWithText
              iconKey="Folder"
              title={t(c.pages.submitReport.landing.sectionD.cardA.title)}
            />
            <LargeIconWithText
              iconKey="Calendar"
              title={t(c.pages.submitReport.landing.sectionD.cardB.title)}
            />
            <LargeIconWithText
              iconKey="Car"
              title={t(c.pages.submitReport.landing.sectionD.cardC.title)}
            />
          </Stack>
        </ContentSection>
      </ContentSection>

      <ContentSection noTopPadding variant="darkPaper">
        <ContentSection
          variant="default"
          title={t(c.pages.submitReport.landing.sectionE.title)}
          subtitle={t(c.pages.submitReport.landing.sectionE.subtitle)}
        >
          <Stack direction="column" alignItems="center" gap={18}>
            <a href={t(c.infractionChecklistLink)} target="_blank" rel="noopener noreferrer">
              <Button startIcon={<DownloadIcon />} variant="text">
                {t(c.pages.submitReport.landing.sectionE.linkText)}
              </Button>
            </a>

            <Checkmark style={{ fontSize: '80px' }} />
          </Stack>
        </ContentSection>
      </ContentSection>

      <ContentSection noBottomPadding variant="paper">
        <ContentSection
          variant="dark"
          title={t(c.pages.submitReport.landing.sectionF.title)}
          subtitle={t(c.pages.submitReport.landing.sectionF.subtitle)}
        >
          <Button
            color="secondary"
            className="on-dark"
            onClick={() => dispatchReportAction('SUBMIT_AN_INFRACTION')}
          >
            {t(c.pages.submitReport.landing.sectionF.buttonText)}
          </Button>
        </ContentSection>
      </ContentSection>

      <HaveQuestionsContentSection />
    </PageShell>
  );
}
