import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { StPageSection } from 'suites/sterling/molecules/sections/page/PageSection';
import { PageShell } from 'suites/sterling/molecules/shells/page/PageShell';
import { complianceAppContent as c } from 'suites/compliance/ComplianceApp.content';

export function ComplianceRestrictedRoute() {
  const t = useTranslation();

  return (
    <PageShell>
      <StPageSection>
        <Typography variant="h5">
          {t(c.permissions.a)} <Link href="/reportingtool">{t(c.permissions.b)}:</Link>
        </Typography>
      </StPageSection>
    </PageShell>
  );
}
