import { fontSafe, colourSafe } from '../safe';

export const demoColours = {
  'sterling-light-teal': colourSafe('DEMO')('#a8c2b8'),
  'sterling-seafoam-blue': colourSafe('DEMO')('#60cdd9'),
  'sterling-purple-brown': colourSafe('DEMO')('#272223'),
  'sterling-bruise-black': colourSafe('DEMO')('#1e1428'),
  'sterling-gunmetal': colourSafe('DEMO')('#47505a'),
  'sterling-light-grey': colourSafe('DEMO')('#e5e4e4'),
  'sterling-dark-grey': colourSafe('DEMO')('#C4C8D0'),
  'sterling-magenta': colourSafe('DEMO')('#b8256a'),
  'sterling-purple': colourSafe('DEMO')('#642463'),
  'sterling-lead': colourSafe('DEMO')('#5C6770'),
};

export const demoTypography = {
  'sterling-bold': `${fontSafe('DEMO')('Helvetica Now Bold')}, Helvetica,  sans-serif`,
  'sterling-regular': `${fontSafe('DEMO')('Helvetica Now Regular')}, Helvetica,  sans-serif`,
  'sterling-medium': `${fontSafe('DEMO')('Helvetica Now Medium')}, Helvetica,  sans-serif`,
  'sterling-light': `${fontSafe('DEMO')('Helvetica Now Light')}, Helvetica,  sans-serif`,
};
