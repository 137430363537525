/* eslint-disable no-shadow */
import Typography from "@mui/material/Typography";
import {
  StPageSection,
  StPageSectionProps,
} from "suites/sterling/molecules/sections/page/PageSection";

export interface TitleSectionProps extends StPageSectionProps {
  title?: string;
  subtitle?: string;
}

export function TitleSection({
  title,
  subtitle,
  children,
  ...StPageSectionProps
}: TitleSectionProps) {
  return (
    <StPageSection {...StPageSectionProps}>
      {title && <Typography variant="h1">{title}</Typography>}
      {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
      {children}
    </StPageSection>
  );
}
