import Chip from '@mui/material/Chip';
import { Enumcompliance_CasesStatus } from 'generated';
import {
  ComplianceCaseStatusDisplay,
  renderStatusAs,
} from 'suites/compliance/app/business-logic';
import { useComplianceRole } from 'suites/compliance/app/context/compliance-role';
import { complianceAppContent as content } from 'suites/compliance/ComplianceApp.content';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import SearchIcon from '@mui/icons-material/Search';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import DoNotDisturbOutlinedIcon from '@mui/icons-material/DoNotDisturbOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

type HexCode = `#${string}`;
interface Colours {
  backgroundColor: HexCode;
  color: HexCode;
}

const CaseStatusChipColours: Record<ComplianceCaseStatusDisplay, Colours> = {
  SUBMITTED: {
    color: '#263238',
    backgroundColor: '#CFD8DC',
  },
  ESCALATED: {
    color: '#E65100',
    backgroundColor: '#FFE0B2',
  },
  RETURNED: {
    color: '#B71C1C',
    backgroundColor: '#FFCDD2',
  },
  CLOSED: {
    color: '#ffffff',
    backgroundColor: '#000000',
  },
  IN_PROGRESS: {
    color: '#01579B',
    backgroundColor: '#B3E5FC',
  },
  ASSISTANCE: {
    color: '#4A148C',
    backgroundColor: '#D1C4E9',
  },
  IN_REVIEW: {
    color: '#004D40',
    backgroundColor: '#B2DFDB',
  },
};

const ICONS: Record<
  ComplianceCaseStatusDisplay,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  OverridableComponent<SvgIconTypeMap<any, 'svg'>>
> = {
  IN_PROGRESS: TimelapseIcon,
  SUBMITTED: InfoOutlinedIcon,
  ESCALATED: WarningAmberOutlinedIcon,
  RETURNED: DoNotDisturbOutlinedIcon,
  CLOSED: CheckCircleOutlineOutlinedIcon,
  ASSISTANCE: HelpOutlineOutlinedIcon,
  IN_REVIEW: SearchIcon,
};

export interface CaseStatusChipProps {
  status: Enumcompliance_CasesStatus;
}

export function CaseStatusChip({ status }: CaseStatusChipProps) {
  const t = useTranslation();
  const { role } = useComplianceRole();

  if (!role) return null;

  const visibleStatus = renderStatusAs(status, role);

  const IconComponent = ICONS[visibleStatus];

  return (
    <Chip
      avatar={<IconComponent sx={{ fill: CaseStatusChipColours[visibleStatus].color }} />}
      style={CaseStatusChipColours[visibleStatus]}
      label={t(content.caseStatus[visibleStatus])}
    />
  );
}
