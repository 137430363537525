import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import { Logo } from 'components';
import { Link, useLocation, Redirect } from 'react-router-dom';
import { ClientRoute } from 'routing/Routes';
import { getTopLevelPath, pathMatch } from 'utility';
import { useLanguage } from 'components/util/Language';
import { SterlingIcon, IconName } from 'components/general/SterlingIcon';
import { useNavStyles, NavDiv } from './styled.Nav';

export function MainNav(props: { routes: ClientRoute[]; permittedRoutes: string[] }) {
  const { routes: propRoutes, permittedRoutes } = props;
  const classes = useNavStyles();
  const location = useLocation();
  const topPath = getTopLevelPath(location.pathname);
  const lang = useLanguage();
  const [open, setOpen] = useState(topPath === '/');

  const selectedRoute = propRoutes.find((route) =>
    pathMatch(topPath, getTopLevelPath(route.path), true)
  );

  const forcedNavOrder = [
    /\/admin/g,
    /\/program-updates/g,
    /\/management-report/g,
    /\/my-performance/g,
    /\/standards/g,
    /\/program-standards-2021/g,
    /\/preferred-vendors/g,
    /\/education/g,
    /\/changelog/g,
    /\/support/g,
  ];

  // Sort routes by forced order
  // This is do to wordpress routes combining with platform routes and having their own order
  const routes = propRoutes
    .filter((route) => route.path !== '/' && !route.path.includes('/notifications'))
    .map((route) => {
      let sortedRoute: boolean | ClientRoute = false;
      forcedNavOrder.forEach((routeName, newOrder) => {
        if (routeName.test(route.path)) {
          sortedRoute = { ...route, order: newOrder };
        }
      });
      return sortedRoute || route;
    })
    .sort((a, b) => a.order - b.order);

  useEffect(() => {
    setOpen(!!(topPath === '/' || (selectedRoute && selectedRoute.children.length === 0)));
  }, [location.pathname, selectedRoute, topPath]);

  if (!selectedRoute) return <Redirect to="/" />;

  return (
    <Drawer
        variant="permanent"
        sx={{width: 'auto'}}
        classes={{
          paper: clsx({
            [classes.drawerOpenMain]: open,
            [classes.drawerCloseMain]: !open,
            [classes.drawer]: true,
          }),
        }}
        open={open}
      >
        <Logo invis={!open} logoType="main" />
        <NavDiv className={classes.linkWrapper}>
          {routes.filter((r) => !r.hideNavLink).map((route, index) => {
            if (
              !permittedRoutes.some((permittedRoute) =>
                pathMatch(permittedRoute, getTopLevelPath(route.path), true)
              )
            ) {
              return null;
            }

            const highlighted = location.pathname.includes(route.path);
            const translatedTitle =
              lang === 'FR' && route.titleFr ? route.titleFr : route.title;

            const permittedChildren = route.children.filter((childRoute) =>
              permittedRoutes.some((r) => pathMatch(r, childRoute.path, true))
            );
            const pathWhenClicked =
              route.noContent && route.children?.length > 0 && permittedChildren?.[0]
                ? permittedChildren[0].pathFr && lang === 'FR'
                  ? permittedChildren[0].pathFr
                  : permittedChildren[0].path
                : route.pathFr && lang === 'FR'
                ? route.pathFr
                : route.path;

            return (
              <Link
                key={`nav-${index}`}
                to={pathWhenClicked}
                className={clsx(
                  'nav-link',
                  highlighted && 'nav-selected',
                  !open && 'nav-shrink'
                )}
              >
                <span className={clsx(highlighted && 'nav-selected', 'nav-icon')}>
                  <SterlingIcon name={route.itemtype as IconName} title={translatedTitle} />
                </span>
                <p title={translatedTitle} className={clsx('nav-text')}>
                  {translatedTitle}
                </p>
              </Link>
            );
          })}
        </NavDiv>
      </Drawer>
  );
}
