import Typography from '@mui/material/Typography';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { complianceAppContent as content } from 'suites/compliance/ComplianceApp.content';
import { CaseCard } from 'suites/compliance/components/cards/case/CaseCard';

export function NoCasesCard() {
  const t = useTranslation();

  return (
    <CaseCard sx={{height: 130}} justifyContent="center">
      <Typography align="center" sx={{ fontWeight: 'bold', opacity: 0.6 }}>{t(content.terms.noCases)}</Typography>
    </CaseCard>
  );
}
