import { ComplianceRole } from 'generated';
import { useComplianceRole } from 'suites/compliance/app/context/compliance-role';

export interface ComplianceRoleControllerProps {
  roles: ComplianceRole[];
  excludeRoles?: ComplianceRole[];
  children?: React.ReactNode;
  fallback?: React.ReactNode;
}

export function ComplianceRoleController(props: ComplianceRoleControllerProps) {
  const { roles, excludeRoles, children, fallback = null } = props;
  const { role } = useComplianceRole();

  if (!role) return null;

  if (excludeRoles?.includes(role)) {
    return <>{fallback}</>;
  }

  if (roles.includes(role)) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
}
