import { createContext, useContext, useState } from 'react';
import { getToken } from 'utility';

interface AuthContext {
  refreshAuth: () => void;
  authorized: boolean;
  token?: UserToken;
}

export const AuthorizationContext = createContext<AuthContext>({
  refreshAuth: () => undefined,
  authorized: false,
});

export const useAuthorization = () => {
  return useContext(AuthorizationContext);
};

export function AuthorizationProvider(props: { children: React.ReactNode }) {
  const [token, setToken] = useState(getToken());
  const refreshAuth = () => {
    setToken(getToken());
  };

  return (
    // Authchecks should be interuptive - https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/jsx-no-constructed-context-values.md
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AuthorizationContext.Provider value={{ authorized: !!token, refreshAuth, token }}>
      {props.children}
    </AuthorizationContext.Provider>
  );
}

export const AuthorizationConsumer = AuthorizationContext.Consumer;
