import React from 'react';
import { Link } from 'react-router-dom';
import { KeyboardArrowRight as ArrowRight, KeyboardArrowLeft as ArrowLeft } from '@mui/icons-material';
import { Hidden } from '@mui/material';
import { useLanguage } from 'components/util/Language';

export function NavArrow(props: {
  direction: 'next' | 'previous';
  path: string;
  title: string;
}) {
  const { direction, path, title } = props;
  const style = !path
    ? { color: 'grey', fill: 'grey', display: 'inline' }
    : { display: 'inline-block' };
  const lang = useLanguage();

  return (
    <Link className="nav-arrow-link" to={!path ? '#' : path}>
      {direction === 'previous'
        ? path && (
            <>
              <ArrowLeft className="content-navigation-arrow" style={{ ...style }} />
              <Hidden mdDown>
                {title ? (
                  <span style={{ ...style }} dangerouslySetInnerHTML={{ __html: title }} />
                ) : (
                  <span style={{ ...style }}>
                    {{ EN: 'Previous', FR: 'Précédent' }[lang]}{' '}
                  </span>
                )}
              </Hidden>
            </>
          )
        : path && (
            <>
              <Hidden mdDown>
                {title ? (
                  <span style={{ ...style }} dangerouslySetInnerHTML={{ __html: title }} />
                ) : (
                  <span style={{ ...style }}>{{ EN: 'Next', FR: 'Suivant' }[lang]} </span>
                )}
              </Hidden>
              <ArrowRight className="content-navigation-arrow" style={{ ...style }} />
            </>
          )}
    </Link>
  );
}
