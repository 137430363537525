import React, { ComponentProps } from 'react';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import {Autocomplete, Box} from '@mui/material';
import { Analytics } from 'components/util/AnalyticEvents';
import { DropdownWrapperDiv, InteriorInputWrapperDiv } from './styled.SearchDropdown';
import { useSearchDropdown, DropdownSelectorProps } from './model.SearchDropdown';

export function SearchDropdown(props: DropdownSelectorProps) {
  const { clear, noIcon } = props;
  const { handleClear, autoCompleteProps, textFieldProps, logValue } = useSearchDropdown(props);
  handleClear(clear);

  Analytics.Interaction({
    field: props.placeholder,
    value: logValue,
  });
  return (
    <Box sx={{background: 'white'}}>
      <DropdownWrapperDiv>
        <Autocomplete
          {...autoCompleteProps}
          sx={{borderRadius: '4px'}}
          renderInput={(param: ComponentProps<typeof TextField>) => {
            return (
            <InteriorInputWrapperDiv noIcon={noIcon}>
              { !noIcon && <SearchIcon style={{ width: '25px', marginLeft: '10px' }} /> }
              <TextField {...textFieldProps} {...param}/>
            </InteriorInputWrapperDiv>
          )}}
          />
      </DropdownWrapperDiv>
    </Box>
  );
}
