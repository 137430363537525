import { ThunkAction } from 'redux-thunk';
import {
  Action,
  Dispatch,
  Middleware,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

// Reducers
import activeSelections from 'sterling-redux/reducers/activeSelections';
import configuration from 'sterling-redux/reducers/configuration';
import { fetchConfiguration } from './reducers/configuration';

const loggerMiddleware = createLogger();

const rootReducer = combineReducers({
  activeSelections,
  configuration,
});

export type AppState = ReturnType<typeof rootReducer>;

export type ThunkActionComplete = ThunkAction<void, AppState, null, Action>;

const middleware: Middleware<NonNullable<unknown>, unknown, Dispatch<Action>>[] = [];

if (process.env.NODE_ENV === 'development') {
  middleware.push(
    loggerMiddleware as Middleware<NonNullable<unknown>, unknown, Dispatch<Action>>
  );
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (generalMiddleware) => generalMiddleware().concat(middleware),
});

store.dispatch(fetchConfiguration() as unknown as Action);
