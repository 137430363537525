/* eslint-disable react/no-unused-prop-types */
import React, { CSSProperties, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Analytics, AnalyticsConfig } from 'components/util/AnalyticEvents';
import { useDropdownSelectorStyles, useMenuItemStyles } from './styled.DropdownSelector';
import TextField from '@mui/material/TextField';

export interface DropdownOption {
  value: string;
  label: string;
}

export interface DropdownSelectorProps {
  dropdownOptions: DropdownOption[];
  initialValue: DropdownOption['value'];
  setFunction: (arg: string) => void;
  onChange?: (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  formHelperText?: string;
  style?: CSSProperties;
  buttonHeight?: boolean;
  small?: boolean;
  buttonLook?: boolean;
  name?: string;
  analytics_config?: AnalyticsConfig;
  id?: string;
}

export function DropdownSelector(props: DropdownSelectorProps) {
  const {
    id,
    dropdownOptions,
    initialValue,
    formHelperText,
    setFunction,
    analytics_config,
    name,
    style,
    onChange,
  } = props;

  const [selectedValue, setSelectedValue] =
    React.useState<DropdownOption['value']>(initialValue);

  const classes = useDropdownSelectorStyles(props);
  const menuClasses = useMenuItemStyles();

  const handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const responseValue = e.target.value as DropdownOption['value'];
    setFunction(responseValue);
    setSelectedValue(responseValue);
  };

  Analytics.Interaction(
    {
      field: formHelperText,
      value: selectedValue,
    },
    analytics_config
  );

  // Unsure why this is necessary.  A change in the initial value prop should update it automatically,
  // but as of the time of this writing that is not happening
  useEffect(() => {
    if (initialValue !== selectedValue) {
      setSelectedValue(initialValue);
    }
  }, [initialValue, selectedValue]);

  return (
    <TextField
      id={id}
      select
      name={name}
      value={selectedValue}
      label={formHelperText}
      InputLabelProps={
        selectedValue !== ''
          ? { classes: { root: classes.oemLabel } }
          : { classes: { root: classes.unfocused } }
      }
      classes={{ root: [classes.input, classes.input2].join(' ') }}
      onChange={onChange || handleChange}
      InputProps={{
        notched: false,
        classes: { root: classes.innerInput, focused: classes.inputFocused },
      }}
      style={style}
    >
      {dropdownOptions.map((option: DropdownOption, index: number) => {
        return (
          <MenuItem className={menuClasses.dropDownItems} key={index} value={option.value}>
            {option.label}
          </MenuItem>
        );
      })}
    </TextField>
  );
}
