import { CSSProperties, useState, useEffect } from 'react';
import { normalizeBetween } from 'utility';

export interface PaginatorProps {
  style?: CSSProperties;
  currentPage: number;
  totalPages: number;
  setCurrentPage: (p: number) => void;
}

export const arrayOfPages = ({ totalPages }: PaginatorProps) =>
  Array.from(Array(totalPages).keys());

export const pageValidator = (props: PaginatorProps, page: number) => {
  const { currentPage, totalPages } = props;

  if (totalPages <= 5) {
    return { displayEllipsis: false, displayPageNumber: true };
  }

  const pageNearStart = [0, 1].indexOf(page) > -1;
  const currentPageNearStart = [0, 1].indexOf(currentPage) > -1;
  const pageNearCurrent =
    [currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2].indexOf(
      page
    ) > -1;
  const pageNearEnd = [totalPages - 2, totalPages - 1].indexOf(page) > -1;
  const currentPageNearEnd = [totalPages - 2, totalPages - 1].indexOf(currentPage) > -1;

  let displayEllipsis =
    !pageNearStart && !pageNearEnd && (page === currentPage - 2 || page === currentPage + 2);

  const displayPageNumber = pageNearStart || pageNearCurrent || pageNearEnd;

  if (
    (currentPageNearStart || currentPageNearEnd) &&
    !displayEllipsis &&
    !displayPageNumber &&
    Math.round((totalPages - 1) / 2) === page
  ) {
    displayEllipsis = true;
  }

  return { displayEllipsis, displayPageNumber };
};

export const previousAndNext = (props: PaginatorProps) => {
  const { currentPage, totalPages, setCurrentPage } = props;

  const previousPage = () =>
    setCurrentPage(normalizeBetween(currentPage - 1, 0, totalPages - 1));
  const nextPage = () => setCurrentPage(normalizeBetween(currentPage + 1, 0, totalPages - 1));

  return { previousPage, nextPage };
};

export const usePagination = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setCurrentPage(0);
  }, [totalPages]);

  return {
    currentPage,
    setCurrentPage,
    currentPageSize,
    setCurrentPageSize,
    totalPages,
    setTotalPages,
  };
};

export const useResultPagination = (
  currentPageSize: number,
) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(0);

  const updateOffset = () => {
    setOffset(currentPage * currentPageSize)
  }

  useEffect(() => {
    updateOffset();
  }, [])

  return {
    offset,
    currentPage,
    setCurrentPage,
    updateOffset
  }
}
