import { useLogout } from 'suites/sterling/app/hooks/useLogout';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { Button } from 'components';
import { contentLogoutButton as content } from './LogoutButton.content';
import Logout from '@mui/icons-material/Logout';
import { useComplianceParams } from 'suites/compliance/app/hooks/useComplianceParams';

export function LogoutButton() {
  const t = useTranslation();
  const { logout } = useLogout();
  const { clearParam } = useComplianceParams();

  return (
    <Button
      endIcon={<Logout />}
      color="secondary"
      size="small"
      onClick={() => {
        clearParam('caseId');
        logout();
      }}
    >
      {t(content.buttonLabel)}
    </Button>
  );
}
