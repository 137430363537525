import { Theme, createTheme, responsiveFontSizes, adaptV4Theme } from '@mui/material/styles';
import { css } from 'styled-components';
import { colours, typography } from '../resources';

const config = {
  colour: {
    default: colours['sterling-bruise-black'],
    focused: colours['sterling-bruise-black'],
    blurred: colours['sterling-gunmetal'],
    active: colours['sterling-seafoam-blue'],
    buttonOutline: colours['sterling-bruise-black'],
    titleBorder: colours['sterling-seafoam-blue'],
    requiredDot: colours['sterling-seafoam-blue'],
    rewardsTable: colours['sterling-light-teal'],
    tag: {
      standard: {
        text: colours.white,
        background: colours['sterling-purple'],
      },
      alternate: {
        text: colours['sterling-purple'],
        background: colours.white,
      },
    },
    calendarTile: {
      background: {
        upcoming: colours['sterling-light-teal'],
        upNext: colours['sterling-bruise-black'],
        TBA: colours['sterling-dark-grey'],
      },
      text: {
        upcoming: colours.black,
        upNext: colours.white,
        TBA: colours.black,
      },
    },
    text: {
      default: colours.black,
      anchor: colours['sterling-magenta'],
    },
    indicator: {
      active: colours['sterling-seafoam-blue'],
      inactive: colours.white,
    },
    background: {
      primary: colours['sterling-light-grey'],
      secondary: colours['sterling-light-teal'],
      toggleTrack: colours['sterling-seafoam-blue'],
    },
    graph: {
      primary: colours['sterling-gunmetal'],
      track: colours['sterling-light-grey'],
      bar: {
        primary: colours['sterling-seafoam-blue'],
        secondary: colours['sterling-gunmetal'],
      },
    },
    nav: {
      background: colours.white,
      mobileBackground: colours.white,
      link: {
        default: colours['sterling-bruise-black'],
        selected: colours['sterling-bruise-black'],
        selectedOnWhite: colours['sterling-bruise-black'],
        hover: colours.white,
        background: {
          selected: colours['sterling-seafoam-blue'],
          hover: colours['sterling-bruise-black'],
        },
        tertiaryText: colours['sterling-gunmetal'],
      },
    },
    preferredVendorPage: {
      modalBackground: colours.white,
    },
  },
  font: {
    headerBold: typography['sterling-bold'],
    headerMedium: typography['sterling-regular'],
    light: typography['sterling-light'],
    regular: typography['sterling-regular'],
    medium: typography['sterling-regular'],
    bold: typography['sterling-bold'],
    default: typography['sterling-regular'],
    colonHeader: typography['sterling-bold'],
    link: typography['sterling-regular'],
    subNav: typography['sterling-regular'],
  },
  fontSize: {
    p: '20px',
    h1: '40px',
    h2: '24px',
    h3: '20px',
  },
  mobileFontSize: {
    p: '16px',
    h1: '25px',
    h2: '19px',
    h3: '16px',
  },
  figma: {
    colour: {
      base: {
        light: colours['sterling-light-grey'],
        lightSecondary: colours['sterling-lead'],
        dark: colours['sterling-bruise-black'],
        accent: colours['sterling-seafoam-blue'],
        hoverOnLight: colours['sterling-bruise-black'],
        hoverOnDark: colours['sterling-seafoam-blue'],
      },
      contrast: {
        light: colours['sterling-bruise-black'],
        dark: colours.white,
        accent: colours['sterling-bruise-black'],
        hoverOnLight: colours.white,
        hoverOnDark: colours['sterling-bruise-black'],
      },
    },
  },
};

const demoMuiTheme: Theme = responsiveFontSizes(
  createTheme(adaptV4Theme({
    palette: {
      text: {
        // hint: config.colour.active,
        primary: colours.black,
        secondary: colours['sterling-bruise-black'],
      },
    },
  }))
);

export const demoTheme = {
  ...config,
  materialUI: demoMuiTheme,
  tweaks: {
    recognitionBadgeHeight: '175px',
    mainLogoHeight: '50px',
    subLogoHeight: '50px',
    list_css: `${css`
      padding-left: 35px;
    `}`,
    resourceQuestionMargin: `${css`
      li {
        margin-left: 12px;
      }
    `}`,
    resourceDotPosition: `${css`
      left: -65px;
    `}`,
    expandIconPosition: `${css`
      top: 45%;
    `}`,
  },
};
