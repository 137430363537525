import React from 'react';
import { PieChart, Pie, ResponsiveContainer, Cell, Sector } from 'recharts';
import { useSelector } from 'react-redux';
import { AppState } from 'sterling-redux/store';
import { OemTheme } from 'assets/styles/themes/functions';

export interface ScorePieData {
  innerCircleText: string;
  value: number;
  link?: string;
}

export const pieColours = () => ({
  primary: OemTheme.colour.graph.primary,
  background: OemTheme.colour.graph.track,
});

export function ScorePieRender(props: React.ComponentProps<typeof Sector> & { fillOverride?: string; payload?: ScorePieData; fontSizeOverride?: number; }) {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fillOverride, payload, stroke, fontSizeOverride } = props;
  return (
    <g >
      <text x={cx} y={cy} dy={5} style={{fontSize: fontSizeOverride}} textAnchor="middle" className="text-percentage">
        {payload?.innerCircleText}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        style={{fill: fillOverride, stroke}}
      />
    </g>
  );
}

export function ScorePie(props: { data: ScorePieData[]; height?: number | string; fill?: string; fontSize?:number; fontWeight?:number; fullSizeBar?: true}) {
  const { data, height = 300, fill, fontSize, fontWeight, fullSizeBar } = props;
  const [activeIndex] = React.useState<number>(0);

  const printMode = useSelector((state: AppState) => state.activeSelections.printMode);

  const COLORS = [pieColours().primary, ...[...Array(7)].map(() => pieColours().background)];

  return (
    <ResponsiveContainer
      className="score-pie"
      width="100%"
      height={printMode ? 200 : height}
    >
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={<ScorePieRender fillOverride={fill || COLORS[0]} fontSizeOverride={fontSize} fontWeight={fontWeight}/>}
          fontWeight={fontWeight}
          data={data}
          innerRadius={fullSizeBar ? "75%": "65%"}
          outerRadius={fullSizeBar ? "110%": "85%"}
          dataKey="value"
        >
          {data.map((_entry, index) => (
            <Cell key={index} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
