import { useLanguage } from "suites/sterling/app/context/language";

import {
  OEM,
  LanguageCode,
  Translation,
  LanguageLabel,
} from "suites/sterling/app/types";
import { CurrentOem } from "utility";

/**
 * Supply an OEM and Language to generic a function that will extract the correct copy from a
 * Translation type object and use a default fallback if no OEM specific copy is supplied
 * @param oem An OEM
 * @param lang A langauge code of either EN or FR
 */
const translationComposer = (oem: OEM, lang: LanguageCode) => (
  copy: Translation | LanguageLabel
): string => {
  if ("EN" in copy) {
    return copy[lang];
  }
  return copy[oem]?.[lang] ?? copy.DEFAULT[lang];
};

export const useTranslation = () => {
  const lang = useLanguage();
  
  return translationComposer(CurrentOem, lang);
};
