import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { ReactComponent as Advertising } from 'assets/images/icons/compliance/Advertising.svg';
import { ReactComponent as Calendar } from 'assets/images/icons/compliance/Calendar.svg';
import { ReactComponent as Car } from 'assets/images/icons/compliance/Car.svg';
import { ReactComponent as Folder } from 'assets/images/icons/compliance/Folder.svg';
import { ReactComponent as MarketingArea } from 'assets/images/icons/compliance/MarketingArea.svg';
import { ReactComponent as Money } from 'assets/images/icons/compliance/Money.svg';
import { ReactComponent as EmailIcon } from 'assets/images/icons/compliance/Email.svg';
import { ReactComponent as CallIcon } from 'assets/images/icons/compliance/Call.svg';
import { ReactNode } from 'react';

const Icons = {
  Advertising: <Advertising />,
  Calendar: <Calendar />,
  Car: <Car />,
  Folder: <Folder />,
  MarketingArea: <MarketingArea />,
  Money: <Money />,
  Email: <EmailIcon />,
  Call: <CallIcon />,
} as const;

interface LargeIconWithTextProps {
  title: string;
  subtitle?: ReactNode;
  iconKey: keyof typeof Icons;
  content?: ReactNode;
}

export function LargeIconWithText(props: LargeIconWithTextProps) {
  const { title, subtitle, content, iconKey } = props;

  return (
    <Stack direction="column" alignItems="center" gap={3}>
      {Icons[iconKey]}
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: subtitle ? 'bold' : undefined, mt: 2 }}
      >
        {title}
      </Typography>
      {subtitle && <Typography variant="body2">{subtitle}</Typography>}
      {content}
    </Stack>
  );
}
