import { css } from 'styled-components';

export const materialGridStyles = css`
  .mb-0 {
    margin-bottom: 0 !important;
  }

  .mb-1 {
    margin-bottom: 5px !important;
  }

  .mt-0 {
    margin-top: 0 !important;
  }

  .mt-1 {
    margin-top: 5px !important;
  }

  .mt-2 {
    margin-top: 18px !important;
  }

  .m-0 {
    margin: 0 !important;
  }

  .w-50 {
    width: 50%;
  }

  .w-75 {
    width: 75%;
  }

  .h-50 {
    height: calc(50% - 12px) !important;
  }

  .MuiGrid-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
  }

  .MuiGrid-item {
    margin: 0;
    box-sizing: border-box;
  }

  .MuiGrid-zeroMinWidth {
    min-width: 0;
  }

  .MuiGrid-direction-xs-column {
    flex-direction: column;
  }

  .MuiGrid-direction-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .MuiGrid-direction-xs-row-reverse {
    flex-direction: row-reverse;
  }

  .MuiGrid-wrap-xs-nowrap {
    flex-wrap: nowrap;
  }

  .MuiGrid-wrap-xs-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .MuiGrid-align-items-xs-center {
    align-items: center;
  }

  .MuiGrid-align-items-xs-flex-start {
    align-items: flex-start;
  }

  .MuiGrid-align-items-xs-flex-end {
    align-items: flex-end;
  }

  .MuiGrid-align-items-xs-baseline {
    align-items: baseline;
  }

  .MuiGrid-align-content-xs-center {
    align-content: center;
  }

  .MuiGrid-align-content-xs-flex-start {
    align-content: flex-start;
  }

  .MuiGrid-align-content-xs-flex-end {
    align-content: flex-end;
  }

  .MuiGrid-align-content-xs-space-between {
    align-content: space-between;
  }

  .MuiGrid-align-content-xs-space-around {
    align-content: space-around;
  }

  .MuiGrid-justify-xs-center {
    justify-content: center;
  }

  .MuiGrid-justify-xs-flex-end {
    justify-content: flex-end;
  }

  .MuiGrid-justify-xs-space-between {
    justify-content: space-between;
  }

  .MuiGrid-justify-xs-space-around {
    justify-content: space-around;
  }

  .MuiGrid-justify-xs-space-evenly {
    justify-content: space-evenly;
  }

  .MuiGrid-spacing-xs-1 {
    width: calc(100% + 8px);
    margin: -4px;
  }

  .MuiGrid-spacing-xs-1 > .MuiGrid-item {
    padding: 4px;
  }

  .MuiGrid-spacing-xs-2 {
    width: calc(100% + 16px);
    margin: -8px;
  }

  .MuiGrid-spacing-xs-2 > .MuiGrid-item {
    padding: 8px;
  }

  .MuiGrid-spacing-xs-3 {
    width: calc(100% + 24px);
    margin: 0 -12px;
  }

  .MuiGrid-spacing-xs-3 > .MuiGrid-item {
    padding: 12px;
  }

  .MuiGrid-spacing-xs-4 {
    width: calc(100% + 32px);
    margin: -16px;
  }

  .MuiGrid-spacing-xs-4 > .MuiGrid-item {
    padding: 16px;
  }

  .MuiGrid-spacing-xs-5 {
    width: calc(100% + 40px);
    margin: -20px;
  }

  .MuiGrid-spacing-xs-5 > .MuiGrid-item {
    padding: 20px;
  }

  .MuiGrid-spacing-xs-6 {
    width: calc(100% + 48px);
    margin: -24px;
  }

  .MuiGrid-spacing-xs-6 > .MuiGrid-item {
    padding: 24px;
  }

  .MuiGrid-spacing-xs-7 {
    width: calc(100% + 56px);
    margin: -28px;
  }

  .MuiGrid-spacing-xs-7 > .MuiGrid-item {
    padding: 28px;
  }

  .MuiGrid-spacing-xs-8 {
    width: calc(100% + 64px);
    margin: -32px;
  }

  .MuiGrid-spacing-xs-8 > .MuiGrid-item {
    padding: 32px;
  }

  .MuiGrid-spacing-xs-9 {
    width: calc(100% + 72px);
    margin: -36px;
  }

  .MuiGrid-spacing-xs-9 > .MuiGrid-item {
    padding: 36px;
  }

  .MuiGrid-spacing-xs-10 {
    width: calc(100% + 80px);
    margin: -40px;
  }

  .MuiGrid-spacing-xs-10 > .MuiGrid-item {
    padding: 40px;
  }

  .MuiGrid-grid-xs-auto {
    flex-grow: 0;
    max-width: none;
    flex-basis: auto;
  }

  .MuiGrid-grid-xs-true {
    flex-grow: 1;
    max-width: 100%;
    flex-basis: 0;
  }

  .MuiGrid-grid-xs-1 {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%;
  }

  .MuiGrid-grid-xs-2 {
    flex-grow: 0;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
  }

  .MuiGrid-grid-xs-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
  }

  .MuiGrid-grid-xs-4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
  }

  .MuiGrid-grid-xs-5 {
    flex-grow: 0;
    max-width: 41.666667%;
    flex-basis: 41.666667%;
  }

  .MuiGrid-grid-xs-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
  }

  .MuiGrid-grid-xs-7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%;
  }

  .MuiGrid-grid-xs-8 {
    flex-grow: 0;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
  }

  .MuiGrid-grid-xs-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%;
  }

  .MuiGrid-grid-xs-10 {
    flex-grow: 0;
    max-width: 83.333333%;
    flex-basis: 83.333333%;
  }

  .MuiGrid-grid-xs-11 {
    flex-grow: 0;
    max-width: 91.666667%;
    flex-basis: 91.666667%;
  }

  .MuiGrid-grid-xs-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
  @media (min-width: 600px) {
    .MuiGrid-grid-sm-auto {
      flex-grow: 0;
      max-width: none;
      flex-basis: auto;
    }

    .MuiGrid-grid-sm-true {
      flex-grow: 1;
      max-width: 100%;
      flex-basis: 0;
    }

    .MuiGrid-grid-sm-1 {
      flex-grow: 0;
      max-width: 8.333333%;
      flex-basis: 8.333333%;
    }

    .MuiGrid-grid-sm-2 {
      flex-grow: 0;
      max-width: 16.666667%;
      flex-basis: 16.666667%;
    }

    .MuiGrid-grid-sm-3 {
      flex-grow: 0;
      max-width: 25%;
      flex-basis: 25%;
    }

    .MuiGrid-grid-sm-4 {
      flex-grow: 0;
      max-width: 33.333333%;
      flex-basis: 33.333333%;
    }

    .MuiGrid-grid-sm-5 {
      flex-grow: 0;
      max-width: 41.666667%;
      flex-basis: 41.666667%;
    }

    .MuiGrid-grid-sm-6 {
      flex-grow: 0;
      max-width: 50%;
      flex-basis: 50%;
    }

    .MuiGrid-grid-sm-7 {
      flex-grow: 0;
      max-width: 58.333333%;
      flex-basis: 58.333333%;
    }

    .MuiGrid-grid-sm-8 {
      flex-grow: 0;
      max-width: 66.666667%;
      flex-basis: 66.666667%;
    }

    .MuiGrid-grid-sm-9 {
      flex-grow: 0;
      max-width: 75%;
      flex-basis: 75%;
    }

    .MuiGrid-grid-sm-10 {
      flex-grow: 0;
      max-width: 83.333333%;
      flex-basis: 83.333333%;
    }

    .MuiGrid-grid-sm-11 {
      flex-grow: 0;
      max-width: 91.666667%;
      flex-basis: 91.666667%;
    }

    .MuiGrid-grid-sm-12 {
      flex-grow: 0;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
  @media (min-width: 900px) {
    .MuiGrid-grid-md-auto {
      flex-grow: 0;
      max-width: none;
      flex-basis: auto;
    }

    .MuiGrid-grid-md-true {
      flex-grow: 1;
      max-width: 100%;
      flex-basis: 0;
    }

    .MuiGrid-grid-md-1 {
      flex-grow: 0;
      max-width: 8.333333%;
      flex-basis: 8.333333%;
    }

    .MuiGrid-grid-md-2 {
      flex-grow: 0;
      max-width: 16.666667%;
      flex-basis: 16.666667%;
    }

    .MuiGrid-grid-md-3 {
      flex-grow: 0;
      max-width: 25%;
      flex-basis: 25%;
    }

    .MuiGrid-grid-md-4 {
      flex-grow: 0;
      max-width: 33.333333%;
      flex-basis: 33.333333%;
    }

    .MuiGrid-grid-md-5 {
      flex-grow: 0;
      max-width: 41.666667%;
      flex-basis: 41.666667%;
    }

    .MuiGrid-grid-md-6 {
      flex-grow: 0;
      max-width: 50%;
      flex-basis: 50%;
    }

    .MuiGrid-grid-md-7 {
      flex-grow: 0;
      max-width: 58.333333%;
      flex-basis: 58.333333%;
    }

    .MuiGrid-grid-md-8 {
      flex-grow: 0;
      max-width: 66.666667%;
      flex-basis: 66.666667%;
    }

    .MuiGrid-grid-md-9 {
      flex-grow: 0;
      max-width: 75%;
      flex-basis: 75%;
    }

    .MuiGrid-grid-md-10 {
      flex-grow: 0;
      max-width: 83.333333%;
      flex-basis: 83.333333%;
    }

    .MuiGrid-grid-md-11 {
      flex-grow: 0;
      max-width: 91.666667%;
      flex-basis: 91.666667%;
    }

    .MuiGrid-grid-md-12 {
      flex-grow: 0;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
  @media (min-width: 1280px) {
    .MuiGrid-grid-lg-auto {
      flex-grow: 0;
      max-width: none;
      flex-basis: auto;
    }

    .MuiGrid-grid-lg-true {
      flex-grow: 1;
      max-width: 100%;
      flex-basis: 0;
    }

    .MuiGrid-grid-lg-1 {
      flex-grow: 0;
      max-width: 8.333333%;
      flex-basis: 8.333333%;
    }

    .MuiGrid-grid-lg-2 {
      flex-grow: 0;
      max-width: 16.666667%;
      flex-basis: 16.666667%;
    }

    .MuiGrid-grid-lg-3 {
      flex-grow: 0;
      max-width: 25%;
      flex-basis: 25%;
    }

    .MuiGrid-grid-lg-4 {
      flex-grow: 0;
      max-width: 33.333333%;
      flex-basis: 33.333333%;
    }

    .MuiGrid-grid-lg-5 {
      flex-grow: 0;
      max-width: 41.666667%;
      flex-basis: 41.666667%;
    }

    .MuiGrid-grid-lg-6 {
      flex-grow: 0;
      max-width: 50%;
      flex-basis: 50%;
    }

    .MuiGrid-grid-lg-7 {
      flex-grow: 0;
      max-width: 58.333333%;
      flex-basis: 58.333333%;
    }

    .MuiGrid-grid-lg-8 {
      flex-grow: 0;
      max-width: 66.666667%;
      flex-basis: 66.666667%;
    }

    .MuiGrid-grid-lg-9 {
      flex-grow: 0;
      max-width: 75%;
      flex-basis: 75%;
    }

    .MuiGrid-grid-lg-10 {
      flex-grow: 0;
      max-width: 83.333333%;
      flex-basis: 83.333333%;
    }

    .MuiGrid-grid-lg-11 {
      flex-grow: 0;
      max-width: 91.666667%;
      flex-basis: 91.666667%;
    }

    .MuiGrid-grid-lg-12 {
      flex-grow: 0;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
  @media (min-width: 1920px) {
    .MuiGrid-grid-xl-auto {
      flex-grow: 0;
      max-width: none;
      flex-basis: auto;
    }

    .MuiGrid-grid-xl-true {
      flex-grow: 1;
      max-width: 100%;
      flex-basis: 0;
    }

    .MuiGrid-grid-xl-1 {
      flex-grow: 0;
      max-width: 8.333333%;
      flex-basis: 8.333333%;
    }

    .MuiGrid-grid-xl-2 {
      flex-grow: 0;
      max-width: 16.666667%;
      flex-basis: 16.666667%;
    }

    .MuiGrid-grid-xl-3 {
      flex-grow: 0;
      max-width: 25%;
      flex-basis: 25%;
    }

    .MuiGrid-grid-xl-4 {
      flex-grow: 0;
      max-width: 33.333333%;
      flex-basis: 33.333333%;
    }

    .MuiGrid-grid-xl-5 {
      flex-grow: 0;
      max-width: 41.666667%;
      flex-basis: 41.666667%;
    }

    .MuiGrid-grid-xl-6 {
      flex-grow: 0;
      max-width: 50%;
      flex-basis: 50%;
    }

    .MuiGrid-grid-xl-7 {
      flex-grow: 0;
      max-width: 58.333333%;
      flex-basis: 58.333333%;
    }

    .MuiGrid-grid-xl-8 {
      flex-grow: 0;
      max-width: 66.666667%;
      flex-basis: 66.666667%;
    }

    .MuiGrid-grid-xl-9 {
      flex-grow: 0;
      max-width: 75%;
      flex-basis: 75%;
    }

    .MuiGrid-grid-xl-10 {
      flex-grow: 0;
      max-width: 83.333333%;
      flex-basis: 83.333333%;
    }

    .MuiGrid-grid-xl-11 {
      flex-grow: 0;
      max-width: 91.666667%;
      flex-basis: 91.666667%;
    }

    .MuiGrid-grid-xl-12 {
      flex-grow: 0;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
`;
