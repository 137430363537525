import { OemTheme } from 'assets/styles/themes/functions';
import styled from 'styled-components';

export const LogoWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 35px 16px;

  img {
    width: 100%;
  }

  &.main-logo {
    background-color: ${OemTheme.colour.default};

    img {
      height: ${OemTheme.tweaks('mainLogoHeight', '35px')};
    }
  }

  &.sub-logo {
    background-color: white;

    img {
      height: ${OemTheme.tweaks('subLogoHeight', '35px')};
    }
  }

  &.hide-logo {
    display: none;
  }

  @media (max-width: 898px) {
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding: 0;
    z-index: 0;

    &.sub-logo img,
    img {
      margin-left: 5%;
      width: auto;
      height: ${OemTheme.tweaks('mobileLogoHeight', '70px')};
    }
  }
`;
