import { ReactComponent as UploadEvidence } from 'assets/images/icons/UploadEvidence.svg';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { ReactNode } from 'react';
import Box from '@mui/material/Box';

export interface StUploadCardProps {
  isDragActive: boolean;
  isActive: boolean;
  label: string;
  children: ReactNode;
}

export function StUploadCard(props: StUploadCardProps) {
  const { isDragActive, isActive, label, children } = props;

  return (
    <Box sx={{ border: isDragActive ? 'dashed 2px black' : 'solid 2px black' }}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={5}
        sx={{
          padding: (theme) => theme.spacing(5),
          backgroundColor: 'background.default',
          cursor: 'pointer',
          opacity: isActive ? 1 : 0.6,
          '&:hover': {
            opacity: 1,
          }
        }}
      >
        <Typography variant="subtitle1">{label}</Typography>
        <UploadEvidence />
        {children}
      </Stack>
    </Box>
  );
}
