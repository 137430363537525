import gql from 'graphql-tag';

export const MEDIA_TYPES_ALL_QUERY = gql`
  query getAdherenceCards($language: EnumAdherence_CardsLanguage!) {
    adherenceCardsMany(filter: {language: $language, type: "media-type"}, sort: TITLE_ASC) {
      title
      source
      image {
        src
        alt
      }
      language
      link_url
      link_label
      type
      featured
      description
      key
    }
  }
`;
export const BRAND_COMPONENTS_ALL_QUERY = gql`
  query getAdherenceComponentCards($language: EnumAdherence_CardsLanguage!) {
    adherenceCardsMany(filter: {language: $language, type: "component-standard"}, sort: ORDER_ASC) {
      title
      source
      image {
        src
        alt
      }
      language
      link_url
      link_label
      type
      featured
      description
      key
    }
  }
`;
export const MEDIA_TYPES_FEATURED_QUERY = gql`

  query getAdherenceMediaCards($language: EnumAdherence_CardsLanguage!) {
    mediaFeatured: adherenceCardsMany(filter: {featured: true, type: "media-type", language: $language}) {
      title
      source
      image {
        src
        alt
      }
      language
      link_url
      link_label
      type
      featured
      description
      key
    }

    componentsFeatured: adherenceCardsMany(filter: {featured: true, type: "component-standard", language: $language}) {
      title
      source
      image {
        src
        alt
      }
      language
      link_url
      link_label
      type
      featured
      description
      key
    }
  }

`;
