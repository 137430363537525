import { StDrawerProps } from 'suites/sterling/components/menus/Menu';
import { useScrollToTop } from 'suites/sterling/app/hooks/useScrollToTop';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { AdherenceReview } from 'suites/adherence/pages/review/AdherenceReview';
import { AdherenceReviewList } from 'suites/adherence/pages/list/AdherenceReviewList';
import { AdherenceReviewSubmit } from 'suites/adherence/pages/submit/AdherenceReviewSubmit';
import { AdherenceResources } from 'suites/adherence/pages/standards/AdherenceResources';
import { MenuItem } from 'suites/sterling/components/menus/AdherenceMenu';
import { AdherenceLanding } from 'suites/adherence/pages/media-types/AdherenceMediaLanding';
import { getToken } from 'utility';
import {
  AdherenceBrandComponentStandards,
  AdherenceMediaTypeStandards,
} from 'suites/adherence/pages/media-types/AdherenceStandards';

import { RestrictedApp } from 'suites/compliance/pages/restricted/RestrictedApp';
import { useAdherenceRole } from '../context/adherence-role';
import { AdherenceDashboard } from 'suites/adherence/pages/dashboard/AdherenceDashboard';

const REVIEWER_ROLES = ['DEVELOPER', 'ADHERENCE_REVIEWER'];
const DASHBOARD_ROLES = [...REVIEWER_ROLES, 'SUPER_ADMIN', 'ADMIN'];
const ADHERENCE_ROLES = [...REVIEWER_ROLES, 'VENDOR', 'UNIT', 'SUPER_ADMIN', 'ADMIN', 'MANAGER'];
export const generateAdherenceMenuProps = (
  supplementalMenuItems?: MenuItem[]
): StDrawerProps => {
  const token = getToken();

  const menuProps = {
    options: [
      ...(supplementalMenuItems?.length ? supplementalMenuItems : []),
      {
        label: { EN: 'Review', FR: 'Review' },
        to: '/adherence/review/list/',
      },
      {
        label: { EN: 'Dashboard', FR: 'Tableau de bord' },
        to: '/adherence/review/dashboard/',
      },
    ].filter((op) => {
      if (/(review\/list)/.test(op.to) && !REVIEWER_ROLES.includes(`${token?.coe_role}`))
        return false;
      if (/(review\/dashboard)/.test(op.to) && !REVIEWER_ROLES.includes(`${token?.coe_role}`))
      return false;

      if (!ADHERENCE_ROLES.includes(`${token?.coe_role}`)) return false;
      return true;
    }),
  };

  return menuProps;
};

export function AdherenceRoutes() {
  useScrollToTop();
  const matchPath = useRouteMatch().path;
  const { role } = useAdherenceRole();

  const hasRouteAccess = (enabledRoles: string[]) => enabledRoles.includes(`${role}`);

  return hasRouteAccess(ADHERENCE_ROLES) ? (
    <Switch>
      <Route exact path={`${matchPath}`}>
        <AdherenceLanding />
      </Route>
      <Route exact path={`${matchPath}/media-types(/?)`}>
        <AdherenceMediaTypeStandards />
      </Route>
      <Route exact path={`${matchPath}/brand-components(/?)`}>
        <AdherenceBrandComponentStandards />
      </Route>
      <Route
        exact
        path={[
          `${matchPath}/about-the-program(/?)`,
          `${matchPath}/support(/?)`,
          `${matchPath}/formatting-submissions(/?)`,
          `${matchPath}/media-types/:mediaType*`,
          `${matchPath}/brand-components/:standardsSection*`,
        ]}
      >
        <AdherenceResources />
      </Route>
      {hasRouteAccess(DASHBOARD_ROLES) && (
        <Switch>
          {hasRouteAccess(REVIEWER_ROLES) && (
            <Switch>
              <Route exact path={`${matchPath}/review/dashboard(/?)`}>
                <AdherenceDashboard />
              </Route>
              <Route exact path={`${matchPath}/review/list(/?)`}>
                <AdherenceReviewList />
              </Route>
              <Route exact path={`${matchPath}/review/id/:requestId`}>
                <AdherenceReview />
              </Route>
              <Route exact path={`${matchPath}/review/submit/:requestId`}>
                <AdherenceReviewSubmit />
              </Route>
              <Route path="*" render={() => <Redirect to="/adherence/" />} />
            </Switch>
          )}

        </Switch>
      )}
      <Route path="*" render={() => <Redirect to="/" />} />
    </Switch>
  ) : (
    <RestrictedApp />
  );
}
