import React from 'react';
import { ScoreBarThick, ErrorHandle } from 'components';
import { RankCount } from 'generated';
import { performanceRanks, getRank } from 'business-logic';
import { useLanguage } from 'components/util/Language';

export function RankBar(props: { myScore: number; rankCount?: RankCount }) {
  const { myScore, rankCount } = props;
  const lang = useLanguage();

  if (!rankCount) {
    return <ErrorHandle error="Rank Bar received invalid data." />;
  }

  const data = performanceRanks.map((rank) => {
    return {
      description: lang === 'EN' ? rank.description : rank.descriptionFr,

      value:
        rank.rankIndex === 0
          ? rankCount.score_90_and_above + 1
          : rank.rankIndex === 1
          ? rankCount.score_80_to_89 + 1
          : rank.rankIndex === 2
          ? rankCount.score_70_to_79 + 1
          : rank.rankIndex === 3
          ? rankCount.score_60_to_69 + 1
          : rankCount.score_59_and_below + 1,

      compValue: getRank(myScore).rankIndex,
    };
  });

  return <ScoreBarThick data={data}/>;
}
