import React from 'react';
import { ApolloConsumer, ApolloClient, ApolloQueryResult, gql } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { LogoutResponse } from 'generated';
import { RESET_ALL } from 'sterling-redux/reducers/activeSelections';
import { useLanguage } from 'components/util/Language';
import { useAuthorization } from 'suites/sterling/app/context/authorization';
import Button from '@mui/material/Button/Button';
import { SxProps, Theme } from '@mui/material/styles';

const LOGOUT_USER = gql`
  query logout {
    validateLogout: logout {
      success
    }
  }
`;

const logout = async (client: ApolloClient<unknown>) => {
  const result: ApolloQueryResult<{
    validateLogout: LogoutResponse;
  }> = await client.query({
    query: LOGOUT_USER,
    fetchPolicy: 'network-only',
  });

  const logoutResponse = result.data.validateLogout;

  if (logoutResponse.success) {
    return true;
  } else {
    console.error(logoutResponse);
  }
  return false;
};

export function LogoutButton({buttonStyleOverride}: {buttonStyleOverride?: SxProps<Theme>}) {
  const lang = useLanguage();
  const dispatch = useDispatch();
  const { refreshAuth } = useAuthorization();

  return (
    <ApolloConsumer>
      {(client: ApolloClient<unknown>) => (
        <Button
          id="logout-button"
          size='medium'
          variant='text'
          sx={buttonStyleOverride ?? {}}
          onClick={async () => {
            const loggedOut = await logout(client);
            if (loggedOut) {
              refreshAuth();
              // Reset the active selections in the store to initial values
              dispatch({ type: RESET_ALL });
              // Apollo cache garbage collection
              client.cache.gc();
              // Clears out any relative URL the application was previous active on when login screen is displayed
              window.history.replaceState({}, 'Login', {EN:'/', FR: '/fr/'}[lang]);
            }
          }}
        >
          {{EN:`Logout`, FR:`Se déconnecter`}[lang]}
        </Button>
      )}
    </ApolloConsumer>
  );
}
