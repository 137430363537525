import styled, { css } from 'styled-components';
import { OemTheme } from 'assets/styles/themes/functions';

interface SterlingButtonWrapperProps {
  active: boolean;
  buttonGroup?: boolean;
  noHover?: boolean;
}

export const SterlingButtonWrapper = styled.div<SterlingButtonWrapperProps>`
  display: inline-block;
  margin-right: 12px;

  .MuiButton-label {
    text-transform: none;
    text-align: center;
    font-size: 16px;
    font-family: ${OemTheme.font.regular};
    color: ${OemTheme.colour.buttonOutline};
    background-color: transparent;
    padding-bottom: ${OemTheme.tweaks('buttonTextPadding', '0')};
    white-space: nowrap;

    ${({ active }: SterlingButtonWrapperProps) =>
      active && css`
        color: ${OemTheme.colour.nav.link.selected};
        z-index: 1;
      `}

    [class^="SterlingIcon"] {
      display: inline-block;
      width: unset;
      height: unset;
      position: unset;
      transform: unset;
      margin-right: 8px;

      svg {
        width: auto;
        height: 1.25rem;
        position: relative;
        transform: unset;

        path {
          fill: ${OemTheme.colour.nav.link.selected};
        }
      }
    }
  }

  .MuiButton-contained,
  .MuiButton-containedPrimary {
    background-color: transparent;
    box-shadow: none;
  }

  .MuiButton-contained {
    ${({ buttonGroup }: SterlingButtonWrapperProps) =>  `border-radius: ${buttonGroup ?`25px`: `0`};`}
    ${({ active }: SterlingButtonWrapperProps) =>
      !active
        ? css`
            background-color: transparent;
          `
        : css`
            border-color: ${OemTheme.colour.active};
            background-color: ${OemTheme.colour.active};
          `}
    color: ${({ active }: SterlingButtonWrapperProps) => active ? `white` : `${OemTheme.colour.text.default}`};
  }

  button {
    padding: 5px 20px;
    min-width: 150px;
    min-height: 50px;
    width: 100%;
    @media (max-width: 898px) {
      min-width: 100px;
      min-height: 40px;
    }
  }

  ${({ noHover, buttonGroup }: SterlingButtonWrapperProps) =>
    noHover
      ? ''
      : css`
          /* Hover effect only active on desktop */
          @media (min-width: 898px) {
            .MuiButton-contained:hover,
            .MuiButton-containedPrimary:hover {
              border-radius: ${buttonGroup ? '25px' : '0'};
              color: ${OemTheme.colour.nav.link.hover};
            }

            button:hover {
              box-shadow: none;

              .MuiButton-label {
                color: ${OemTheme.colour.nav.link.hover};
                z-index: 1;

                svg path {
                  fill: ${OemTheme.colour.nav.link.hover};
                }
              }

              border-color: ${OemTheme.colour.nav.link.background.hover};
              background-color: ${OemTheme.colour.nav.link.background.hover};
            }
          }
        `}
`;

export const ButtonGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  .performance-report {
    padding: 10px 15px;
  }

  div {
    margin: 0 10px 10px 0;

    /* box-sizing: content-box; */
    max-width: max-content;
    /* max-width: 33%; */

    > div {
      margin: 0;
    }
  }
`;

export const ButtonAndLabelWrapper = styled.div`
  .button-group-label {
    font-size: 12px;
    color: ${OemTheme.colour.text.default};
    transform: translateY(-8px);
  }
`;
