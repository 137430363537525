import Box from '@mui/material/Box';
import { TypeBackground } from '@mui/material/styles/createPalette';

export interface PageShellProps {
  children: React.ReactNode;
  variant?: keyof TypeBackground;
  disableMargin?: true;
}

export function PageShell({
  children,
  variant = 'default',
  disableMargin,
}: PageShellProps) {
  return (
    <Box
      sx={{
        mt: disableMargin ? 0 : (theme) => theme.custom.appBar.height,
        minHeight: (theme) => `calc(100vh - ${theme.custom.appBar.height})`,
        backgroundColor: `background.${variant}`,
      }}
    >
      {children}
    </Box>
  );
}
