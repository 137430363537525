import Stack from '@mui/material/Stack';
import { CaseCard } from 'suites/compliance/components/cards/case/CaseCard';
import Typography from '@mui/material/Typography';
import {
  dateSort,
  determineAttentionContext,
  formatCaseId,
  StrictComplianceCase,
} from 'suites/compliance/app/business-logic';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {Button} from 'components';
import { CaseStatusChip } from 'suites/compliance/components/chips/case-status/CaseStatusChip';
import { CaseStatusTransitionChip } from 'suites/compliance/components/chips/case-status-transition/CaseStatusTransitionChip';

import { complianceAppContent as c } from 'suites/compliance/ComplianceApp.content';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { useComplianceHistory } from 'suites/compliance/app/hooks/useComplianceHistory';

import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Box from '@mui/material/Box';
import { Compliance_CasesHistory } from 'generated';
import { useComplianceRole } from 'suites/compliance/app/context/compliance-role';

export interface CaseAttentionCardProps {
  selectedCase: StrictComplianceCase;
  onClick: () => void;
}

export function CaseAttentionCard({ selectedCase, onClick }: CaseAttentionCardProps) {
  const t = useTranslation();
  const { push } = useComplianceHistory();
  const { role } = useComplianceRole();
  const attentionContext = determineAttentionContext(selectedCase);
  const { repeatCaseIds, history } = selectedCase;

  const latestHistory = history.slice().sort(dateSort('DESC'))?.[0] as
    | Compliance_CasesHistory
    | undefined;

  const showRepeatVersion =
    attentionContext === 'REPEAT' && role !== 'DEALER' && repeatCaseIds;

  return (
    <CaseCard
      title={
        <Typography
          variant="subtitle1"
          component={Stack}
          direction="row"
          alignItems="center"
          gap={1}
        >
          {showRepeatVersion ? <WarningAmberIcon /> : <InfoOutlined />}
          {t(c.components.caseAttentionCard[attentionContext].title)}
        </Typography>
      }
      titleOpposite={
        <Stack direction="row" gap={2}>
          {showRepeatVersion && <SyncProblemIcon />}
          <CaseStatusChip status={selectedCase.status} />
        </Stack>
      }
    >
      <Stack direction="column" alignItems="flex-start" gap={5}>
        <Typography variant="body1">
          {t(c.components.caseAttentionCard[attentionContext].textA)}
        </Typography>
        {attentionContext === 'ASSISTANCE' ? (
          <CaseStatusTransitionChip {...selectedCase.statusTransition} />
        ) : showRepeatVersion ? (
          <Stack direction="column" gap={2}>
            {repeatCaseIds.map((caseId) => (
              <Button
                key={caseId}
                variant="text"
                onClick={() => {
                  push(`/admin?caseId=${caseId}`);
                }}
              >
                {t(c.terms.case)} {formatCaseId(caseId)}
              </Button>
            ))}
          </Stack>
        ) : null}
        {/* Dealers will not see as API prevents internalNotes from being sent */}
        {latestHistory?.internalNotes && (
          <>
            <Typography variant="tableHeader" sx={{opacity: 0.6}}>{t(c.terms.internalNotes)}</Typography>
            <Box
              sx={{
                p: 3,
                marginRight: 5,
                backgroundColor: 'background.paper',
                opacity: 0.6,
                alignSelf: 'stretch',
              }}
            >
              <Typography variant="body2">{latestHistory.internalNotes}</Typography>
            </Box>
            {latestHistory?.internalNotesName && (
              <Typography variant="body2">{latestHistory.internalNotesName}</Typography>
            )}
          </>
        )}
        <Button onClick={onClick}>
          {t(c.components.caseAttentionCard[attentionContext].buttonLabel)}
        </Button>
      </Stack>
    </CaseCard>
  );
}
