import React from 'react';
import Stack from '@mui/material/Stack';
import { CaseCard } from 'suites/compliance/components/cards/case/CaseCard';

export function FormCaseCard(props: { children: React.ReactNode }) {
  return (
    <CaseCard sx={{ backgroundColor: 'background.default', p: 5 }}>
      <Stack spacing={5}>{props.children}</Stack>
    </CaseCard>
  );
}
