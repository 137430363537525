// Javascript version of variables
import { demoTypography, demoColours } from './demo/resources.demo';
import { haccTypography, haccColours } from './hacc/resources.hacc';
import { mmscColours, mmscTypography } from './mmsc/resources.mmsc';

export const colours = {
  black: '#000000',
  white: '#ffffff',
  grey: '#fbfafa',
  ...demoColours,
  ...haccColours,
  ...mmscColours,
};

export const typography = {
  ...demoTypography,
  ...haccTypography,
  ...mmscTypography,
};

const BREAKPOINTS = ['sm', 'md', 'lg', 'xl', 'xxl'] as const;

export type SterlingBreakpoint = typeof BREAKPOINTS[number];

export const breakpointsInt: Record<SterlingBreakpoint, number> = {
  sm: 600,
  md: 960,
  lg: 1024,
  xl: 1280,
  xxl: 1440,
};

const gte = (b1: SterlingBreakpoint, b2: SterlingBreakpoint) => {
  return breakpointsInt[b1] >= breakpointsInt[b2];
};

const lt = (b1: SterlingBreakpoint, b2: SterlingBreakpoint) => {
  return breakpointsInt[b1] < breakpointsInt[b2];
};

export const ScreenSize = { gte, lt };

export const breakpoints: Record<SterlingBreakpoint, string> = {
  sm: `${breakpointsInt.sm}px`,
  md: `${breakpointsInt.md}px`,
  lg: `${breakpointsInt.lg}px`,
  xl: `${breakpointsInt.xl}px`,
  xxl: `${breakpointsInt.xxl}px`,
};

export const SPACING_UNIT = 4;
