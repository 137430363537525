import React, { useState } from 'react';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts';
import { OemTheme } from 'assets/styles/themes/functions';

interface CellSegment {
  [key: string]: string | number;
}

// eslint-disable-next-line
export const PaddedPieGraph = (_props: { query?: unknown; compare?: boolean }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const data: CellSegment[] = [{ key: 1 }]; // sampleData;
  const onCellHover = (index: number) => setActiveIndex(index);
  const getFillColour = (index: number) =>
    activeIndex === index ? OemTheme.colour.focused : OemTheme.colour.blurred;

  return (
    <ResponsiveContainer width="100%" height={390}>
      <PieChart>
        <Pie
          data={data}
          cx={180}
          cy={180}
          innerRadius={140}
          outerRadius={180}
          fill="#8884d8"
          paddingAngle={5}
          startAngle={0}
          endAngle={360}
          dataKey="Sessions"
        >
          {data.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              onMouseEnter={() => onCellHover(index)}
              fill={getFillColour(index)}
            />
          ))}
        </Pie>
        <Legend
          layout="vertical"
          verticalAlign="middle"
          align="center"
          wrapperStyle={{
            transform: 'translate(-50px,-10px)',
            fontSize: '13px',
            width: '250px',
            padding: 0,
            margin: 0,
            textAlign: 'center',
          }}
          payload={Object.entries(data[activeIndex]).map((d, index) => ({
            value: `${d[0]}: ${d[1]}`.replace('Source / Medium: ', ''),
            type: 'line',
            id: `${d[0] + index}`,
          }))}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PaddedPieGraph;
