import {
  OemCode,
  CoeRole,
  Enumportal_Context_Progress_ReportQuestionsReviewedPriority,
} from 'generated';

export interface RankDetails {
  description: string;
  descriptionFr: string;
  lowerBound: number;
  upperBound: number;
  rankIndex: number;
  performanceLabel: string;
  certificationBadge: string;
}

export const performanceRanks: RankDetails[] = [
  {
    description: 'Genius (90% and Above)',
    descriptionFr: 'Génie (90 % et plus)',
    lowerBound: 90,
    upperBound: 101,
    rankIndex: 0,
    performanceLabel: 'genius',
    certificationBadge: 'platinum',
  },
  {
    description: 'Expert (80 - 89%)',
    descriptionFr: 'Expert (de 80 à 89 %)',
    lowerBound: 80,
    upperBound: 90,
    rankIndex: 1,
    performanceLabel: 'expert',
    certificationBadge: 'gold',
  },
  {
    description: 'Proficient (70 - 79%)',
    descriptionFr: 'Professionnel (de 70 à 79 %)',
    lowerBound: 70,
    upperBound: 80,
    rankIndex: 2,
    performanceLabel: 'proficient',
    certificationBadge: 'silver',
  },
  {
    description: 'Novice (60 - 69%)',
    descriptionFr: 'Novice (de 60 à 69 %)',
    lowerBound: 60,
    upperBound: 70,
    rankIndex: 3,
    performanceLabel: 'novice',
    certificationBadge: 'ghost',
  },
  {
    description: '(59% and Below)',
    descriptionFr: '(59 % et moins)',
    lowerBound: 0,
    upperBound: 60,
    rankIndex: 4,
    performanceLabel: 'ghost',
    certificationBadge: 'ghost',
  },
];

export const getRank = (score: number) => {
  const myRank = performanceRanks.find(
    (rank: RankDetails) => score >= rank.lowerBound && score < rank.upperBound
  );
  if (!myRank) throw new Error('Could not determine rank');
  return myRank;
};

export const validDomains = [
  'mmscan.localhost.io',
  'mmscan.dev.blvdagency.com',
  'mmscan.staging.blvdagency.com',
  'mmscan.blvdagency.com',
  'hac.localhost.io',
  'hac.dev.blvdagency.com',
  'hac.staging.blvdagency.com',
  'hac.blvdagency.com',
] as const;

export type ValidDomain = (typeof validDomains)[number];

export const SortByOptions = {
  GroupId: 'GROUP_ID',
  UnitsInGroup: 'UNITS_IN_GROUP',
  PercentInGroup: 'PERCENT_IN_GROUP',
  FinalScore: 'FINAL_SCORE',
  CategoryScore: 'CATEGORY_SCORE',

  // Only valid when GroupBy is UNIT_NAME
  UnitName: 'UNIT_NAME',
  RegionCode: 'REGION_CODE',
  PreferredVendor: 'PREFERRED_VENDOR',
  DistrictCode: 'DISTRICT_CODE',
  VendorName: 'VENDOR_NAME',
  ReviewerName: 'REVIEWER_NAME',
  Date: 'DATE',
};

export const CoeRolesNoAdmin: readonly CoeRole[] = ['READ_ONLY', 'UNIT', 'VENDOR', 'MANAGER'];
export const CoeRolesNoSuperAdmin: readonly CoeRole[] = [
  'READ_ONLY',
  'UNIT',
  'VENDOR',
  'MANAGER',
  'ADMIN',
];

export const CoeRoles: readonly CoeRole[] = [
  ...CoeRolesNoAdmin,
  'ADMIN',
  'SUPER_ADMIN',
  'DEVELOPER',
];

export const OemCodes: readonly OemCode[] = ['HACC', 'MMSC'];
export const OemCodesWithDemo: readonly OemCode[] = [...OemCodes, 'DEMO'];
export const NotificationCategories = [
  'EDUCATION',
  'PERFORMANCE',
  'REVIEWS',
  'STANDARDS',
  'UPDATES',
  'WEBINARS',
] as const;

export type NotificationCategory = (typeof NotificationCategories)[number];

export const ProgessQuestionPriorities: readonly Enumportal_Context_Progress_ReportQuestionsReviewedPriority[] =
  ['CRITICAL', 'SIGNIFICANT', 'FUNDAMENTAL', 'PROGRESSIVE', 'EVOLVED'];

export const MOST_RECENT_FINALIZED_ASSESSMENT: Record<OemCode, string> = {
  HACC: 'baseline-2022',
  MMSC: 'baseline-2022',
  DEMO: 'fall-2020',
};
