import Chip from '@mui/material/Chip';
import { ComplianceRole, Enumcompliance_CasesStatus } from 'generated';
import { assigneeFromStatus } from 'suites/compliance/app/business-logic';
import { complianceAppContent as content } from 'suites/compliance/ComplianceApp.content';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';

type HexCode = `#${string}`;
interface Colours {
  backgroundColor: 'transparent';
  color: HexCode;
}

const AssigneeChipColours: Record<ComplianceRole, Colours> = {
  DEALER: {
    color: '#01579B',
    backgroundColor: 'transparent',
  },
  BLVD: {
    color: '#642463',
    backgroundColor: 'transparent',
  },
  HYUNDAI: {
    color: '#002C5F',
    backgroundColor: 'transparent',
  },
  ZONE_MANAGER: {
    color: '#007FA5',
    backgroundColor: 'transparent',
  },
  COMMITTEE: {
    color: '#E65100',
    backgroundColor: 'transparent',
  },
};

export interface AssigneeChipProps {
  status: Exclude<Enumcompliance_CasesStatus, 'CLOSED'>;
}

export function AssigneeChip({ status }: AssigneeChipProps) {
  const t = useTranslation();

  const assignee = assigneeFromStatus(status);

  if (assignee === null) return null;

  return (
    <Chip
      sx={{
        ...AssigneeChipColours[assignee],
        border: `1px solid ${AssigneeChipColours[assignee].color}`,
      }}
      label={t(content.roles[assignee])}
    />
  );
}
