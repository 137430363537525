import { OemCode } from 'generated';
import { CurrentOem } from 'utility';
import { demoTheme } from './demo/theme.demo';
import { haccTheme } from './hacc/theme.hacc';
import { mmscTheme } from './mmsc/theme.mmsc';

type TweaksType = typeof demoTheme.tweaks &
  typeof haccTheme.tweaks &
  typeof mmscTheme.tweaks;
type TweaksRule = keyof TweaksType;

const oemConfigs = {
  HACC: haccTheme,
  MMSC: mmscTheme,
  DEMO: demoTheme,
};

const createOemTheme = (oem: OemCode = CurrentOem) => ({
  colour: oemConfigs[oem].colour,
  font: oemConfigs[oem].font,
  fontSize: oemConfigs[oem].fontSize,
  mobileFontSize: oemConfigs[oem].mobileFontSize,
  materialUI: oemConfigs[oem].materialUI,
  figma: oemConfigs[oem].figma,
  tweaks(ruleName: TweaksRule, fallbackValue = '') {
    const oemTweaks = oemConfigs[oem].tweaks as TweaksType;
    return ruleName in oemConfigs[oem].tweaks ? oemTweaks[ruleName] : fallbackValue;
  },
});

export const OemTheme = createOemTheme();
