import { css } from 'styled-components';
import { OemTheme } from './functions';

export const flexStylesRow = css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export const flexStylesColumn = css`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export const IE11Fix = (content: ReturnType<typeof css>) => css`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    ${content}
  }
`;

export const screenSize = (
  range: { min?: string; max?: string },
  content: ReturnType<typeof css>
) => css`
  @media ${range.min && `(min-width: ${range.min})`} ${range.min &&
    range.max &&
    ' and '} ${range.max && `(max-width: ${range.max})`} {
    ${content}
  }
`;

export const fontBaselineFix = () => {
  const tweak = OemTheme.tweaks('fontBaselineFix');
  return (
    tweak &&
    css`
      display: inline-block;
      line-height: 0;
      vertical-align: top;

      ::after {
        content: '';
        display: inline-block;
        ${tweak}
      }
    `
  );
};
