import { SuiteCode } from 'suites/sterling/app/types';
import { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { CurrentOem } from 'utility';

export const SuiteContext = createContext<SuiteCode>('PORTAL');

export const useSuite = (): SuiteCode => {
  return useContext(SuiteContext);
};

const isComplianceURL = /^(\/fr)?\/reportingtool\/?/i;
const isAdherenceURL = /^(\/fr)?\/adherence\/?/i;

export function URLSuiteProvider(props: { children: React.ReactNode }) {
  const { pathname } = useLocation();
  const isHacc = CurrentOem === 'HACC';

  return (
    <SuiteContext.Provider value={
      isComplianceURL.test(pathname) && isHacc ? 'COMPLIANCE'
      : isAdherenceURL.test(pathname) && isHacc ? 'ADHERENCE'
      : 'PORTAL'
      }>
      {props.children}
    </SuiteContext.Provider>
  );
}

export const SuiteConsumer = SuiteContext.Consumer;
