import React from 'react';
import Grid from '@mui/material/Grid';
import { ColourLegend } from 'components/charts/ColourLegend';
import { ChartCardWrapper } from './styled.ChartCard';
import { useLanguage } from 'components/util/Language';
import { Typography } from '@mui/material';

export interface ChartCardProps {
  children: JSX.Element;
  title: string;
  subtitle?: string;
  footer?: string | false;
  isNew?: true;
  showLegend?: boolean;
}

export function ChartCard({
  title,
  subtitle,
  footer,
  children,
  showLegend,
  isNew
}: ChartCardProps) {
  const lang = useLanguage();

  return (
    <ChartCardWrapper>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
        style={{ width: '95%' }}
      >
        <Grid item xs={12} sx={{textOverflow: 'ellipsis', overflow: 'hidden'}}>
          <Typography variant='h5' sx={{marginBottom: 3}}>
            {title}
          </Typography>
          {subtitle && <span title={subtitle} style={{cursor: 'context-menu', whiteSpace: 'nowrap'}}>{subtitle}&nbsp;</span>}
        </Grid>
        <Grid item>{showLegend && <ColourLegend lang={lang} />}</Grid>
      </Grid>
      <hr className="divider-line" />
      {isNew && <Typography sx={{
        position: 'absolute',
        background: (theme) => theme.palette.background.dark,
        color: (theme) => theme.palette.info.contrastText,
        px: '0.75rem',
        pt: '0.25rem',
        marginTop: '-0.5rem'
      }}
        variant='subtitle1'>New</Typography>}
      {children}
      {footer && (
        <Grid item textAlign="center">
          <Typography variant="caption">
            {footer}
          </Typography>
        </Grid>
      )}
    </ChartCardWrapper>
  );
}
