import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { TypeBackground } from '@mui/material/styles/createPalette';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

export interface CaseCardProps extends Omit<React.ComponentProps<typeof Stack>, 'title'> {
  title?: string | React.ReactNode;
  titleOpposite?: React.ReactNode;
  subtitle?: string;
  footer?: string;
  variant?: keyof TypeBackground;
  noDivider?: boolean;
  noBorder?: boolean;
}

export function CaseCard({
  children,
  title,
  titleOpposite,
  subtitle,
  footer,
  variant = 'default',
  sx,
  noDivider,
  noBorder,

  ...stackProps
}: CaseCardProps & { children: ReactNode }) {
  const hasDivider = (title || subtitle) && !noDivider;
  return (
    <Stack
      direction="column"
      spacing={1}
      component={Box}
      sx={{
        padding: (theme) => theme.spacing(5, 6),
        backgroundColor: `background.${variant}`,
        border: noBorder ? undefined : '1px solid rgba(0, 0, 0, 0.4)',
        cursor: stackProps.onClick ? 'pointer' : undefined,
        '&:hover': {
          opacity: stackProps.onClick ? 0.6 : undefined,
        },
        ...sx,
      }}
      {...stackProps}
    >
      {title && (
        <Stack direction="row" justifyContent="space-between">
          {typeof title === 'string' ? <Typography variant="h6">{title}</Typography> : title}
          {titleOpposite}
        </Stack>
      )}

      {subtitle && (
        <Typography sx={{ opacity: 0.6 }} variant="subtitle2">
          {subtitle}
        </Typography>
      )}
      {hasDivider && <Divider />}
      {hasDivider && <Box sx={{ pb: 1 }} />}
      {children}
      {footer && <Typography variant="subtitle2">{footer}</Typography>}
    </Stack>
  );
}
