import { StDrawer } from 'suites/sterling/components/menus/Menu';
import { StAppShell } from 'suites/sterling/molecules/shells/app/AppShell';
import { generateComplianceMenuProps, ComplianceRoutes } from './app/routes/ComplianceRoutes';
import { ComplianceRoleProvider, useComplianceRole } from './app/context/compliance-role';
import { RestrictedApp } from './pages/restricted/RestrictedApp';

const enableApp = process.env.REACT_APP_COMPLIANCE_ENABLE === 'true';

function ComplianceApp() {
  const { role } = useComplianceRole();

  return (
    <>
      {process.env.REACT_APP_DEBUG_MODE === 'true' && (
        <h1 style={{ position: 'fixed', bottom: 10, right: 20, zIndex: 9999, color: 'black' }}>
          Role: {role}
        </h1>
      )}
      <StAppShell
        menu={<StDrawer {...generateComplianceMenuProps()} />}
        page={<ComplianceRoutes />}
      />
    </>
  );
}

function ComplianceAppWithData() {
  if (!enableApp) {
    return <RestrictedApp />;
  }

  return (
    <ComplianceRoleProvider>
      <ComplianceApp />
    </ComplianceRoleProvider>
  );
}

export default ComplianceAppWithData;
