import { useLogin } from 'suites/sterling/app/hooks/useLogin';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { StTextInput } from 'suites/sterling/molecules/inputs/text/TextInput';
import { Button } from 'components';
import Stack from '@mui/material/Stack';
import FormHelperText from '@mui/material/FormHelperText';
import { OemLogo } from 'suites/sterling/components/logos/oem/OemLogo';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { loginFormContent as content } from './LoginForm.content';
import { loginScreenContent } from 'suites/sterling/pages/login/LoginScreen.content';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Link from '@mui/material/Link';
import { useSuite } from 'suites/sterling/app/context/suite';
import Box from '@mui/material/Box';

export function LoginForm() {
  const { login, error } = useLogin();
  const t = useTranslation();
  const suite = useSuite();

  const form = useForm<{ username: string; password: string }>({ shouldUnregister: false });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form;

  const onFormSubmit: SubmitHandler<{ username: string; password: string }> = (data) => {
    login({ variables: data });
  };

  const isError = !!error;

  return (
    <Fade in timeout={750}>
      <Stack
        direction={{ xs: 'column', lg: 'row' }}
        justifyContent="center"
        alignItems="center"
        sx={{ width: '100%' }}
        gap={15}
      >
        <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
          <img
            style={{
              width: '490px',
              height: '536px',
              objectFit: 'cover',
            }}
            src={t(loginScreenContent[suite].imageSrc)}
            alt="Compliance-Scale"
          />
        </Box>

        <Stack direction="column" sx={{ maxWidth: '100vw' }}>
          <Stack
            direction="row"
            alignContent="center"
            justifyContent="center"
            sx={{ width: '168px', height: '72px', alignSelf: 'center' }}
            mb={4}
          >
            <OemLogo />
          </Stack>
          <Typography variant="h4" mb={10}>
            {t(
              suite === 'COMPLIANCE'
                ? content.title.compliance
                : suite === 'ADHERENCE'
                ? content.title.adherence
                : content.title.sterling
            )}
          </Typography>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Stack
              direction="column"
              alignItems="flex-start"
              gap={4}
              p={4}
              sx={{ textAlign: 'left' }}
            >
              <Controller
                name="username"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => {
                  return (
                    <StTextInput
                      id="login-username"
                      label={t(content.terms.username)}
                      maxWidth='90vw'
                      placeholder={t(content.terms.username)}
                      value={value}
                      setValue={onChange}
                      error={!!errors.username}
                      helperText={errors.username?.message}
                    />
                  );
                }}
                rules={{
                  required: t({ EN: 'Required', FR: 'Requis' }),
                }}
              />

              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => {
                  return (
                    <StTextInput
                      type="password"
                      id="login-password"
                      label={t(content.terms.password)}
                      placeholder={t(content.terms.password)}
                      maxWidth='90vw'
                      value={value}
                      setValue={onChange}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                    />
                  );
                }}
                rules={{
                  required: t({ EN: 'Required', FR: 'Requis' }),
                }}
              />

              <Button
                id="login-submit"
                type="submit"
                color="secondary"
                endIcon={<ArrowForwardIcon />}
              >
                {t(content.submitButton)}
              </Button>

              <FormHelperText id="login-error-message" error={isError}>
                {error || <>&nbsp;</>}
              </FormHelperText>

              <Typography variant="caption" sx={{ fontSize: 12 }}>
                {t(content.supportMessage)}{' '}
                <Link
                  href={`mailto:${t(content.supportMessageLink)}`}
                  sx={{ color: 'info.main' }}
                >
                  {t(content.supportMessageLinkText)}
                </Link>
              </Typography>
            </Stack>
          </form>
        </Stack>
      </Stack>
    </Fade>
  );
}
