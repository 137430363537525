import { oemShortCodeFromUrl } from 'utility';
import { OemCode } from 'generated';

const domainOem = oemShortCodeFromUrl();

export const fontSafe = (oem: OemCode) => (font: string) => {
  const mismatch = oem !== domainOem;
  if (mismatch) {
    return 'Comic Sans MS';
  }
  return font;
};

export const colourSafe = (oem: OemCode) => (colour: string) => {
  const mismatch = oem !== domainOem;
  if (mismatch) {
    return '#ffff00'; // Yellow chosen as it's used nowhere else in the app
  }
  return colour;
};
