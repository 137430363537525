import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Info from '@mui/icons-material/Info';
import { PropsFor } from '@mui/system';
import { Box, Button } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import { Instance } from '@popperjs/core';
import { useRef } from 'react';

export function InfoTag({
  sx,
  tooltipContent,
  linkText,
  children,
  variant = 'outlined',
  ...rest
}: PropsFor<typeof InfoIcon> & {
  tooltipContent?: string;
  linkText?: string;
  variant?: 'outlined' | 'filled';
}) {
  const InfoIcon: SvgIconComponent = { outlined: InfoOutlinedIcon, filled: Info }[variant];

  const positionRef = useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = useRef<Instance>(null);
  const areaRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = (event: React.MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };
  return (
    <Tooltip
      sx={{ cursor: 'pointer' }}
      PopperProps={{
        sx: {
          [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: (theme) => theme.palette.common.black,
          },
          [`& .${tooltipClasses.arrow}`]: { color: (theme) => theme.palette.common.black },
        },
        popperRef,
        anchorEl: {
          getBoundingClientRect: () => {
            return new DOMRect(
              positionRef.current.x,
              // eslint-disable-next-line
              areaRef.current!.getBoundingClientRect().y,
              0,
              0
            );
          },
        },
      }}
      placement="top"
      arrow
      title={tooltipContent}
    >
      {linkText ? (
        <Box ref={areaRef} onMouseEnter={handleMouseMove} sx={{display: 'inline', cursor:'pointer'}}>
          <Button
            variant="text"
            sx={{ ...sx, color: (theme) => `${theme.palette.action.selected}` }}
          >
            {linkText}
            {children}
            <InfoIcon sx={{ fontSize: 10, ml: 2 }} />,
          </Button>
        </Box>
      ) : (
        <Box ref={areaRef} onMouseEnter={handleMouseMove} sx={{display: 'inline', cursor:'pointer'}}>
          {children}{' '}
          <InfoIcon {...rest} sx={{ ...sx, fill: (theme) => theme.palette.primary.main }} />
        </Box>
      )}
    </Tooltip>
  );
}
