import { fontSafe, colourSafe } from '../safe';

export const mmscColours = {
  'mitsubishi-concrete-gray': colourSafe('MMSC')('#e3e5e6'),
  'mitsubishi-cool-gray': colourSafe('MMSC')('#bcc0ca'),
  'mitsubishi-anthracite-gray': colourSafe('MMSC')('#686d71'),
  'mitsubishi-red': colourSafe('MMSC')('#ed0000'),
};

export const mmscTypography = {
  'mitsubishi-bold': `${fontSafe('MMSC')('Mitsubishi Bold')}, sans-serif`,
  'mitsubishi-regular': `${fontSafe('MMSC')('Mitsubishi Regular')}, sans-serif`,
  'mitsubishi-medium': `${fontSafe('MMSC')('Mitsubishi Medium')}, sans-serif`,
};
