import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { StActionCard } from 'suites/sterling/molecules/cards/icon/IconCard';
import { loginScreenContent as content } from './LoginScreen.content';
import { ReactComponent as SterlingLogo } from 'assets/icons/login-screen/SterlingLogo.svg';
import { ReactComponent as MMSCLogo } from 'assets/icons/login-screen/MMSCLogo.svg';
import { ReactComponent as HACCLogo } from 'assets/icons/login-screen/HACCLogo.svg';
import { CurrentOem, ENVIRONMENT, getEnvironment } from 'utility';
import { useSuite } from 'suites/sterling/app/context/suite';
import { useState } from 'react';
import Fade from '@mui/material/Fade';
import { LoginForm } from 'suites/sterling/molecules/forms/login/LoginForm';
import Box from '@mui/material/Box';
import { StLanguageToggle } from 'suites/sterling/components/buttons/language/LanguageToggle';

const returnQueryString = `?returnUrl=${encodeURIComponent(window.location.href)}`;

const organizationLoginClick = () => {
  const environment = getEnvironment();
  if (environment === ENVIRONMENT.Production) {
    window.location.href = `https://auth.blvdagency.com/sso/${CurrentOem.toLowerCase()}/sp-init-sso-redirect${returnQueryString}`;
  } else if (environment === ENVIRONMENT.Staging) {
    window.location.href = `https://auth.staging.blvdagency.com/sso/${CurrentOem.toLowerCase()}/sp-init-sso-redirect${returnQueryString}`;
  } else if (environment === ENVIRONMENT.Dev) {
    window.location.href = `https://auth.dev.blvdagency.com/sso/${CurrentOem.toLowerCase()}/sp-init-sso-redirect${returnQueryString}`;
  } else {
    // eslint-disable-next-line no-alert
    alert('Single Sign-On not supported in this environment.');
  }
};

const TRANSITION_MS = 1500;

const Logo = {
  DEMO: <SterlingLogo width="100%" height="auto" />,
  HACC: <HACCLogo width="100%" height="auto" />,
  MMSC: <MMSCLogo width="100%" height="auto" />,
};

export function LoginScreen() {
  const t = useTranslation();
  const theme = useTheme();
  const suite = useSuite();
  const [isPlatformLogin, setIsPlatformLogin] = useState(false);

  const backgroundColor = isPlatformLogin ? 'background.default' : 'background.paper';

  return (
    <Stack
      direction="row"
      sx={{ minHeight: '100vh', backgroundColor, py: { xs: 10, md: 25 } }}
      alignItems="center"
    >
      <Box sx={{ position: 'absolute', top: 0, right: 0 }} mt={6} mr={6}>
        <StLanguageToggle />
      </Box>
      <Container
        maxWidth="lg"
        sx={{ height: '100%', textAlign: 'center', maxWidth: '80%', mt: 10 }}
      >
        {isPlatformLogin ? (
          <LoginForm />
        ) : (
          <Stack direction="column" gap={6} alignItems="center">
            <Fade in timeout={TRANSITION_MS}>
              <Typography
                variant="h1"
                sx={{
                  fontFamily: theme.typography.h2,
                  fontSize: '48px !important',
                }}
              >
                {t(content[suite].title)}
              </Typography>
            </Fade>
            <Fade in timeout={TRANSITION_MS}>
              <Typography variant="h4">{t(content[suite].subtitle)}</Typography>
            </Fade>
            <Fade
              in
              timeout={TRANSITION_MS}
              style={{ transitionDelay: `${TRANSITION_MS / 4}ms` }}
            >
              <Typography variant="body1" sx={{ mb: 8, maxWidth: '70%' }}>
                {t(content[suite].description)}
              </Typography>
            </Fade>
            <Fade
              in
              timeout={TRANSITION_MS}
              style={{ transitionDelay: `${TRANSITION_MS / 2}ms` }}
            >
              <div>
                <Typography variant="subtitle1" sx={{ mb: 6 }}>
                  {t(content[suite].selectLoginType)}
                </Typography>
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  alignItems={{ xs: 'center', md: 'stretch' }}
                  gap={6}
                  sx={{ mb: 20 }}
                >
                  <StActionCard
                    title={t(content[suite].oemCard.title)}
                    subtitle={t(content[suite].oemCard.subtitle)}
                    background={theme.palette.background.dark}
                    onClick={organizationLoginClick}
                    IconComponent={Logo[CurrentOem]}
                  />
                  <StActionCard
                    title={t(content[suite].sterlingCard.title)}
                    subtitle={t(content[suite].sterlingCard.subtitle)}
                    background={theme.palette.background.sterling}
                    onClick={() => {
                      setIsPlatformLogin(true);
                    }}
                    IconComponent={<SterlingLogo width="100%" height="auto" />}
                  />
                </Stack>
              </div>
            </Fade>
          </Stack>
        )}
      </Container>
    </Stack>
  );
}
