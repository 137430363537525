import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { ImageCard } from 'suites/sterling/molecules/cards/image/ImageCard';
import { PageShell } from 'suites/sterling/molecules/shells/page/PageShell';
import { useComplianceHistory } from 'suites/compliance/app/hooks/useComplianceHistory';
import {Button} from 'components';
import Stack from '@mui/material/Stack';
import complianceScaleFocus from 'assets/images/compliance/Fairness-Illustration-Scale-Focus.png';
import hyundaiBackgroundRoad from 'assets/images/compliance/Hyundai-Background-Road.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LargeIconWithText } from 'suites/sterling/components/large-icon-with-text/LargeIconWithText';
import { ContentSection } from 'suites/sterling/molecules/sections/content/ContentSection';
import dealerConductImage1 from 'assets/images/compliance/Card-Dealer-Conduct-1.png';
import dealerConductImage1FR from 'assets/images/compliance/Card-Dealer-Conduct-1-FR.png';
import dealerConductImage2 from 'assets/images/compliance/Card-Dealer-Conduct-2.png';
import dealerConductImage2FR from 'assets/images/compliance/Card-Dealer-Conduct-2-FR.png';
import dealerConductImage3 from 'assets/images/compliance/Card-Dealer-Conduct-3.png';
import dealerConductImage3FR from 'assets/images/compliance/Card-Dealer-Conduct-3-FR.png';
import { complianceAppContent as c } from 'suites/compliance/ComplianceApp.content';
import Typography from '@mui/material/Typography';
import { HaveQuestionsContentSection } from 'suites/compliance/components/sections/have-questions/HaveQuestions';
import { useAuthorization } from 'suites/sterling/app/context/authorization';

export function ComplianceLanding() {
  const t = useTranslation();
  const { push } = useComplianceHistory();
  const { token } = useAuthorization();

  const isDealer = token?.compliance_role === 'DEALER';

  return (
    <PageShell variant="paper">
      <ContentSection
        noBottomPadding
        variant="paper"
        title={t(c.pages.landing.sectionA.title)}
        subtitle={
          <>
            <Typography variant="h4">{t(c.pages.landing.sectionA.subtitle)}</Typography>
            <Typography variant="body1" sx={{ width: '80%' }}>
              {t(c.pages.landing.sectionA.text)}
            </Typography>
          </>
        }
      >
        <ContentSection noBottomPadding noTopPadding>
          <img
            alt="Fairness-Illustration-Scale-Focus"
            src={complianceScaleFocus}
            width="100%"
            height="100%"
          />
        </ContentSection>
      </ContentSection>

      <ContentSection variant="darkPaper" noTopPadding>
        <ContentSection
          variant="dark"
          title={t(c.pages.landing.sectionB.title)}
          subtitle={t(c.pages.landing.sectionB.text)}
          maxWidth="lg"
        />
      </ContentSection>

      <ContentSection backgroundImage={hyundaiBackgroundRoad} noBottomPadding noTopPadding>
        <ContentSection variant="paper" title={t(c.pages.landing.sectionC.title)}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            gap={10}
            alignItems="center"
            justifyContent="space-around"
            sx={{
              width: '70%',
              margin: 'auto',
              '& > div': { width: { xs: '70%', md: '33%' } },
            }}
          >
            <LargeIconWithText
              iconKey="MarketingArea"
              title={t(c.pages.landing.sectionC.cardA.title)}
              subtitle={t(c.pages.landing.sectionC.cardA.subtitle)}
            />
            <LargeIconWithText
              iconKey="Advertising"
              title={t(c.pages.landing.sectionC.cardB.title)}
              subtitle={
                <>
                  {t(c.pages.landing.sectionC.cardB.subtitleA)}
                  <a
                    href={t(c.pages.landing.sectionC.cardB.marketingPolicyLink)}
                    target="_black"
                    rel="noopener"
                  >
                    {t(c.pages.landing.sectionC.cardB.subtitleB)}
                  </a>
                  {t(c.pages.landing.sectionC.cardB.subtitleC)}
                </>
              }
            />
            <LargeIconWithText
              iconKey="Money"
              title={t(c.pages.landing.sectionC.cardC.title)}
              subtitle={t(c.pages.landing.sectionC.cardC.subtitle)}
            />
          </Stack>
        </ContentSection>
      </ContentSection>

      <ContentSection
        variant="darkPaper"
        title={t(c.pages.landing.sectionD.title)}
        subtitle={
          <Typography sx={{ width: '80%' }}>{t(c.pages.landing.sectionD.subtitle)}</Typography>
        }
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          gap={{ xs: 10, md: 6 }}
          justifyContent="space-around"
          alignItems={{ xs: 'center', md: 'stretch' }}
          sx={{
            width: '100%',
            margin: 'auto',
            '& > div': { width: { xs: '70%', md: '33%' } },
          }}
        >
          <ImageCard
            src={t({ EN: dealerConductImage1, FR: dealerConductImage1FR })}
            alt="Promoting the Hyundai advantage"
            title={t(c.pages.landing.sectionD.cardA.title)}
            subtitle={t(c.pages.landing.sectionD.cardA.subtitle)}
          />
          <ImageCard
            src={t({ EN: dealerConductImage2, FR: dealerConductImage2FR })}
            alt="Market your dealership"
            title={t(c.pages.landing.sectionD.cardB.title)}
            subtitle={t(c.pages.landing.sectionD.cardB.subtitle)}
          />
          <ImageCard
            src={t({ EN: dealerConductImage3, FR: dealerConductImage3FR })}
            alt="Be competitive"
            title={t(c.pages.landing.sectionD.cardC.title)}
            subtitle={t(c.pages.landing.sectionD.cardC.subtitle)}
          />
        </Stack>
      </ContentSection>

      {isDealer && (
        <ContentSection noTopPadding backgroundImage={hyundaiBackgroundRoad}>
          <ContentSection
            variant="dark"
            title={t(c.pages.landing.sectionE.title)}
            subtitle={
              <Typography sx={{ width: '80%' }}>
                {t(c.pages.landing.sectionE.subtitle)}
              </Typography>
            }
          >
            <Button
              onClick={() => push('/report')}
              color="secondary"
              className="on-dark"
              endIcon={<ArrowForwardIcon />}
            >
              {t(c.pages.landing.sectionE.buttonText)}
            </Button>
          </ContentSection>
        </ContentSection>
      )}
      <HaveQuestionsContentSection />
    </PageShell>
  );
}
