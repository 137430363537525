import styled from 'styled-components';
import { OemTheme } from 'assets/styles/themes/functions';
import { flexStylesRow } from 'assets/styles/themes/mixins';

export const PaginationWrapper = styled.div`
  ${flexStylesRow};
  justify-content: space-between;
  min-width: 300px;

  div {
    width: 100%;
    ${flexStylesRow};
    justify-content: space-around;
  }

  span,
  svg,
  div span {
    cursor: pointer;
    user-select: none;
    font-size: 18px;
    color: ${OemTheme.colour.default};
    font-family: ${OemTheme.font.medium};
  }

  .page-arrow {
    padding: 5px;
    font-size: 50px;
  }
`;
