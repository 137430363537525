import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { CurrentOem } from 'utility';
import { LogoWrapper } from './styled.Logo';
import { useLanguage } from 'components/util/Language';

type LogoType = 'main' | 'sub';

export function Logo(props: { invis?: boolean; logoType: LogoType }) {
  const { invis, logoType } = props;
  const lang = useLanguage();

  return (
    <Link to={{ EN: '/', FR: '/fr/' }[lang]} className="nav-link" style={{ width: '100%' }}>
      <LogoWrapper
        className={clsx(logoType === 'main' ? 'main-logo' : 'sub-logo', invis && 'hide-logo')}
      >
        <img
          src={`/logos/${CurrentOem.toUpperCase()}_${lang?.toUpperCase()}${
            logoType === 'main' ? '' : '_SUB'
          }.svg`}
          alt={logoType === 'main' ? 'Main Logo' : 'Alt Logo'}
        />
      </LogoWrapper>
    </Link>
  );
}
