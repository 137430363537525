import {useEffect} from 'react';
import { CurrentOem, appName, oemNameFromShortCode } from 'utility';
import { useLanguage } from '../context/language';
import { useSuite } from '../context/suite';

const usePageTitle = (title: string) => {
  const suite = useSuite();
  const lang = useLanguage();
  const oemName = oemNameFromShortCode(CurrentOem);
  const productName =
    suite === 'PORTAL'
      ? appName[lang]
      : suite === 'ADHERENCE'
        ? 'Marketing Adherence'
        : 'Marketing Compliance Program';

        useEffect(() => {
          const titlePrefix = !title ? '' : `${title} | `;
          document.title = `${titlePrefix}${oemName} ${productName}`;
        }, [title]);
}

export default usePageTitle
