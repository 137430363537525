import React from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

// eslint-disable-next-line
export interface StModalProps extends React.ComponentProps<typeof Modal> {
  maxWidth?: Exclude<React.ComponentProps<typeof Container>['maxWidth'], false>;
}

export function StModal(props: StModalProps) {
  const { children, open, maxWidth, ...modalProps } = props;

  return (
    <Modal
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      {...modalProps}
    >
        <Stack
          spacing={4}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: (theme) => theme.breakpoints.values[maxWidth ?? 'sm'],
            bgcolor: 'white',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          {children}
        </Stack>
    </Modal>
  );
}
