import React from 'react';
import { AppState } from 'sterling-redux/store';
import { useSelector, useDispatch } from 'react-redux';
import { DropdownSelector, Loading, ErrorHandle } from 'components';
import { setUnitCode } from 'sterling-redux/reducers/activeSelections';
import { useLanguage } from 'components/util/Language';
import { useSterlingQuery } from 'generated';

export function SelectUnit() {
  const lang = useLanguage();
  const { unitCode } = useSelector((state: AppState) => ({
    unitCode: state.activeSelections.unitCode,
  }));
  const dispatch = useDispatch();

  const sterlingQuery = useSterlingQuery();

  if (sterlingQuery.loading) return <Loading />;
  if (sterlingQuery.error || !sterlingQuery.data) {
    return <ErrorHandle error="Invalid data (units)." />;
  }

  const { unitsManyByRole: units } = sterlingQuery.data;

  if (units.length === 0) {
    return <p>No dealers registered for this user.</p>;
  }

  return (
    <DropdownSelector
      id="select-unit"
      dropdownOptions={[...units]
        .sort((unitA, unitB) =>
          unitA.unit_name > unitB.unit_name ? 1 : unitB.unit_name > unitA.unit_name ? -1 : 0
        )
        .map((unit) => ({
          label: unit.unit_name,
          value: unit.unit_code,
        }))}
      initialValue={unitCode}
      formHelperText={lang === 'EN' ? `Dealership Name` : `Nom de la concession`}
      setFunction={(newUnitCode: string) => dispatch(setUnitCode(newUnitCode))}
    />
  );
}
