import React, { useEffect, useState } from 'react';
import { ContentSection } from 'suites/sterling/molecules/sections/content/ContentSection';
import { PageShell } from 'suites/sterling/molecules/shells/page/PageShell';
import { ErrorHandle, Loading, LinearProgressWithLabel } from 'components';
import { Button, Stack, Box, Typography, Grid } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { KbdShortcut } from 'components/general/KbdShortcut';
import {
  useAdherenceReviewModel,
  useCriteriaListState,
} from 'suites/adherence/components/review-hooks';
import { Adherence_Criteria } from 'generated';
import { useParams } from 'react-router-dom';
import { useSubmissionImage } from './submission-content-hooks';
import { AdherenceContentViewer } from './components/AdherenceContentViewer';
import { useQuery, gql } from '@apollo/client';

// TMB Media API https://www.notion.so/blvdagency/TMB-API-f2bad11c00ec4c12b550b2f64e1587ad

export function AdherenceReview() {
  const { requestId } = useParams<{ requestId: string }>();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const {
    history,
    currentQuestionIndex,
    setCurrentQuestionIndex,
    standardsDisplayToggle,
    setStandardsDisplayToggle,
    redirectToSubmissionPage,
    loading,
    error,
    data,
  } = useAdherenceReviewModel();

  const {
    locallyStoredReview,
    reviewState,
    setReviewState,
    criteriaCategorySort,
    setReviewDetails,
    deleteAnswerFromAnswerState,
    handleReviewStateUpdate,
  } = useCriteriaListState(`${requestId}`);

  const {
    imageRotationState,
    rotateImage,
    skipCurrentContentItem,
    backToPreviousContentItem,
    submissionMedia,
    mediaIndexString,
  } = useSubmissionImage(`${requestId}`);

  const [sortedCriteria, setSortedCriteria] = useState<Adherence_Criteria[]>([]);

  const filteredQuestions = sortedCriteria?.filter(
    (q) =>
      !q.question_dependencies?.length ||
      q.question_dependencies?.some((dep) => locallyStoredReview?.answerState?.[dep] !== false)
  );

  const criteriaQuestion = filteredQuestions[currentQuestionIndex];

  const getPreviousQuestion = () => {
    deleteAnswerFromAnswerState(criteriaQuestion.question_key);
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }

    setStandardsDisplayToggle(true);
  };

  const finishReview = () => {
    redirectToSubmissionPage();
  };

  const getNextQuestion = () => {
    setStandardsDisplayToggle(true);
    if (currentQuestionIndex < filteredQuestions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
    if (currentQuestionIndex >= filteredQuestions.length) {
      setReviewDetails({
        mediaFilename: submissionMedia.filename,
        mediaChannel: data?.adherenceSubmissionOne?.mediaChannel,
      });
      finishReview();
    }
  };

  const imageContentIsPortrait = () => imageRotationState % 2 === 1;

  const answerQuestion = (isCorrect: boolean, isRecursive?: boolean) => () => {
    if (!isRecursive) {
      if (criteriaQuestion) {
        setReviewState((currentState) => ({
          ...currentState,
          [criteriaQuestion.question_key]: isCorrect,
        }));
        // Condition for instant fail condition (instafail)
        if (criteriaQuestion.category === 'Formatting Submissions' && !isCorrect) {
          return redirectToSubmissionPage();
        }
      }
    }
    // only get next question if the answer is in local storage answerState
      if (criteriaQuestion) {
        if(JSON.parse(window.localStorage.getItem(`review-${requestId}`) ?? '')?.answerState?.[criteriaQuestion.question_key] != null) {
        getNextQuestion();
      } else {
        setTimeout(() => {
          answerQuestion(isCorrect, true)();
        },500);
      }
    }
  };

  const existingReviewQuery = useQuery(
    gql`
      query reviewExistenceQuery($requestId: String!) {
        adherenceReviewFindOne(filter: { requestId: $requestId }) {
          requestId
          mediaFilename
          feedback
          answerState
        }
      }
    `,
    { variables: { requestId }, fetchPolicy: 'no-cache' }
  );

  useEffect(() => {
    if (!sortedCriteria?.length && data) {
      setSortedCriteria(
        [...(data?.adherenceSubmissionOne.criteria ?? [])].sort(criteriaCategorySort)
      );
    }
  }, [data?.adherenceSubmissionOne]);

  useEffect(() => {
    if (isFirstLoad && Object.keys(locallyStoredReview?.answerState ?? {}).length) {
      setIsFirstLoad(false);
      setCurrentQuestionIndex(Object.keys(locallyStoredReview?.answerState ?? {}).length);
      setReviewState(locallyStoredReview.answerState);
    }
  }, [locallyStoredReview, locallyStoredReview.answerState, isFirstLoad]);

  // Ensures that a redirect happens when loading state from storage
  useEffect(() => {
    const numberOfQuestions = filteredQuestions?.length;
    if (numberOfQuestions) {
      if (currentQuestionIndex >= numberOfQuestions) {
        setTimeout(getNextQuestion, 500);
      }
    }
  }, [currentQuestionIndex, filteredQuestions]);

  useEffect(() => {
    if (existingReviewQuery?.data?.adherenceReviewFindOne?.answerState) {
      setReviewState(existingReviewQuery?.data?.adherenceReviewFindOne?.answerState);
      // if insta-fail question answer reloaded and answered incorrectly redirect
      if (
        existingReviewQuery?.data?.adherenceReviewFindOne?.answerState?.media_submitted ===
          false ||
        existingReviewQuery?.data?.adherenceReviewFindOne?.answerState
          ?.submitted_media_relevant === false
      ) {
        redirectToSubmissionPage();
      }
    }
  }, [existingReviewQuery?.data]);

  useEffect(() => {
    // Fix for review details not binding on instafail
    setReviewDetails({
      mediaFilename: submissionMedia.filename,
      mediaChannel: data?.adherenceSubmissionOne?.mediaChannel,
    });
  }, []);

  useEffect(() => {
    handleReviewStateUpdate(reviewState);
  }, [reviewState]);

  if (loading || criteriaQuestion === undefined) return <Loading />;
  if (error) return <ErrorHandle error={JSON.stringify(error)} />;

  return (
    <PageShell>
      <KbdShortcut char="w" onTap={() => window.scrollTo(0, 0)} />
      <KbdShortcut
        char="s"
        onTap={() =>
          window.scrollTo(0, (document.getElementById('review-anchor')?.offsetTop ?? 0) - 80)
        }
      />
      <KbdShortcut char="x" onTap={() => window.scrollTo(0, window.scrollY + 475)} />

      <ContentSection
        noBottomPadding
        title={
          <Stack direction="column" alignSelf="flex-start" alignItems="flex-start" gap={6}>
            <Typography variant="h3">
              {data?.adherenceSubmissionOne.invoiceNumber
                ? `Invoice # ${data?.adherenceSubmissionOne.invoiceNumber}`
                : `Request # ${requestId}`}
            </Typography>
            <Typography variant="h6">{`${data?.adherenceSubmissionOne?.campaign}`}</Typography>
            <Typography variant="h6">{`${data?.adherenceSubmissionOne?.mediaChannel}`}</Typography>
          </Stack>
        }
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          sx={{ marginBottom: (theme) => theme.spacing(24) }}
        >
          <Box>
            <KbdShortcut char="esc" inline onTap={() => history.push(`../list`)}>
              <Button variant="text" startIcon={<ChevronLeftIcon />} href="../list/">
                Back to &quot;Things to Review&quot;
              </Button>
            </KbdShortcut>
          </Box>
        </Stack>
        {submissionMedia && (
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ paddingBottom: (theme) => theme.spacing(8) }}
          >
            <Box sx={{ width: '35%' }} id="review-anchor">
              <Typography variant="h5" align="left">
                Percentage Complete
              </Typography>
              <LinearProgressWithLabel
                value={(currentQuestionIndex / (filteredQuestions?.length ?? 1)) * 100}
              />
            </Box>
            <Box sx={{ width: 'auto', textAlign: 'right' }} alignSelf="flex-end">
              <strong>Question:</strong> {currentQuestionIndex + 1} /{' '}
              {filteredQuestions?.length}
            </Box>
          </Stack>
        )}
      </ContentSection>
      {submissionMedia && (
        <ContentSection noTopPadding>
          <Stack
            direction="column"
            gap={10}
            justifyContent="space-around"
            sx={{
              background: (theme) => theme.palette.background.paper,
              padding: (theme) => theme.spacing(12),
            }}
          >
            <Stack direction="column" alignItems="flex-start" gap={10}>
              <Stack
                direction="row"
                alignItems="flex-start"
                justifyContent="space-between"
                sx={{ width: '100%' }}
              >
                {!currentQuestionIndex ? (
                  <Grid item />
                ) : (
                  <KbdShortcut char="q" inline onTap={getPreviousQuestion}>
                    <Button
                      variant="text"
                      startIcon={<ChevronLeftIcon />}
                      onClick={getPreviousQuestion}
                    >
                      Back to Previous Question
                    </Button>
                  </KbdShortcut>
                )}
                <KbdShortcut
                  char="e"
                  inline
                  onTap={() => setStandardsDisplayToggle(!standardsDisplayToggle)}
                >
                  <Button
                    variant="text"
                    onClick={() => setStandardsDisplayToggle(!standardsDisplayToggle)}
                  >
                    {standardsDisplayToggle ? 'Hide' : 'Show'} Standards Viewer
                  </Button>
                </KbdShortcut>
              </Stack>
              <Stack direction="row" gap={10} justifyContent="space-between" width="100%">
                <Stack direction="row" justifyContent="space-between" width="45%">
                  <KbdShortcut char="o" left onTap={backToPreviousContentItem}>
                    <Button variant="text" onClick={backToPreviousContentItem}>
                      <ChevronLeftIcon /> Previous Media
                    </Button>
                  </KbdShortcut>
                  <Typography>{mediaIndexString()}</Typography>
                  <KbdShortcut char="p" onTap={skipCurrentContentItem}>
                    <Button variant="text" onClick={skipCurrentContentItem}>
                      Next Media <ChevronRightIcon />
                    </Button>
                  </KbdShortcut>
                </Stack>
                <Stack direction="row" justifyContent="space-between" gap={10}>
                  {/image/.test(submissionMedia?.type) && (
                    <KbdShortcut char="r" inline onTap={rotateImage}>
                      <Button variant="text" onClick={rotateImage}>
                        Rotate Image
                      </Button>
                    </KbdShortcut>
                  )}
                </Stack>
              </Stack>
              <Typography width="100%" variant="h4" align="left">
                {criteriaQuestion?.question}
              </Typography>
            </Stack>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              alignItems="left"
              gap={10}
              justifyContent="space-around"
            >
              <ContentSection noTopPadding noBottomPadding variant="paper" sx={{ flex: 1 }}>
                <Stack
                  sx={{
                    img: {
                      transform: `rotate(${90 * imageRotationState}deg)`,
                    },
                    paddingY: (theme) => (imageContentIsPortrait() ? theme.spacing(18) : 0),
                  }}
                >
                  <AdherenceContentViewer media={submissionMedia} />
                </Stack>
                <Stack
                  justifyContent="space-around"
                  direction="row"
                  sx={{ padding: (theme) => theme.spacing(4) }}
                >
                  <KbdShortcut
                    char="a"
                    onTap={answerQuestion(criteriaQuestion?.correct_response === 'Yes')}
                  >
                    <Button
                      onClick={answerQuestion(criteriaQuestion?.correct_response === 'Yes')}
                    >
                      Yes
                    </Button>
                  </KbdShortcut>
                  <KbdShortcut
                    char="d"
                    onTap={answerQuestion(criteriaQuestion?.correct_response === 'No')}
                  >
                    <Button
                      onClick={answerQuestion(criteriaQuestion?.correct_response === 'No')}
                    >
                      No
                    </Button>
                  </KbdShortcut>
                </Stack>
              </ContentSection>
              {standardsDisplayToggle && (
                <ContentSection
                  noBottomPadding
                  noTopPadding
                  variant="paper"
                  sx={{ width: '50%' }}
                >
                  {criteriaQuestion?.criteria_image ? (
                    <img
                      width="100%"
                      src={`https://${
                        /localhost\.io/.test(window.location.hostname)
                          ? 'hac.dev.blvdagency.com'
                          : window.location.hostname
                      }${criteriaQuestion?.criteria_image}`}
                      alt={criteriaQuestion?.question}
                    />
                  ) : (
                    // Placeholder for viewer component
                    <Typography>{criteriaQuestion.question_key}</Typography>
                  )}
                </ContentSection>
              )}
            </Stack>
          </Stack>
        </ContentSection>
      )}
    </PageShell>
  );
}
