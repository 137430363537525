import { StDrawer, MenuItem } from 'suites/sterling/components/menus/AdherenceMenu';
import { StAppShell } from 'suites/sterling/molecules/shells/app/AppShell';
import { generateAdherenceMenuProps, AdherenceRoutes } from './app/routes/AdherenceRoutes';
import { useLanguage } from 'components/util/Language';
import { useAdherenceContentNavOptionsQuery } from 'generated';
import { useEffect, useState } from 'react';
import { RestrictedApp } from 'suites/compliance/pages/restricted/RestrictedApp';
import { AdherenceRoleProvider, useAdherenceRole } from './app/context/adherence-role';

interface AdherenceContentRoute {
  title: string,
  titleFr: string,
  path: string,
  noContent: boolean,
  children?: AdherenceContentRoute[]
}

const enableApp = process.env.REACT_APP_ADHERENCE_ENABLE === 'true';

function AdherenceApp() {
  const { role } = useAdherenceRole();
  const language = useLanguage();
  const [navOptions, setNavOptions] = useState<MenuItem[]>([])

  // Generated from app/routes/queries.AdherenceRoutes.ts
  const {loading, data, error} = useAdherenceContentNavOptionsQuery({variables: { language }});

  const formatNavOptions = (opt: AdherenceContentRoute): MenuItem => ({
    label: {EN: opt.title, FR: opt.titleFr},
    to: opt.path,
    children: opt.children?.map(formatNavOptions),
  })

  /**
   *    /adherence is the root page listing in the adherence_content collection.
   *    so we have to navigate down one nesting (child) (hence the [0] before mapping)
   * */

  const updateNavOptions = () => {
    const formattedNavOptions = data?.adherenceContentMany?.[0]?.children.map(formatNavOptions)
    setNavOptions(formattedNavOptions?.length ? formattedNavOptions : [])
  };

  useEffect(() => {
    if (!loading && !error && data){
      updateNavOptions()
    }
  }, [data])

  return (
    <>
      {process.env.REACT_APP_DEBUG_MODE === 'true' && (
        <h1 style={{ position: 'fixed', bottom: 10, right: 20, zIndex: 9999, color: 'black' }}>
          Role: {role}
        </h1>
      )}
      <StAppShell
        menu={<StDrawer {...generateAdherenceMenuProps(navOptions)} />}
        page={<AdherenceRoutes />}
      />
    </>
  );
}

function AdherenceAppWithData() {
  if (!enableApp) {
    return <RestrictedApp />;
  }

  return (
    <AdherenceRoleProvider>
      <AdherenceApp />
    </AdherenceRoleProvider>
  );
}

export default AdherenceAppWithData;
