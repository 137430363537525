import React from 'react';
import { DropdownSelector } from 'components/fields/selectors/templates/DropdownSelector';
import { useLanguage } from 'components/util/Language';

interface RowsPerPageDropdownProps {
  pageSize: number;
  setPageSize: (p: number) => void;
}

const pageSizeOptions = [
  { label: '10', value: '10' },
  { label: '15', value: '15' },
  { label: '20', value: '20' },
  { label: '25', value: '25' },
];

export function RowsPerPageDropdown(props: RowsPerPageDropdownProps): JSX.Element {
  const { pageSize, setPageSize } = props;
  const lang = useLanguage();

  return (
    <DropdownSelector
      dropdownOptions={pageSizeOptions}
      initialValue={pageSize.toString()}
      formHelperText={lang === 'EN' ? `Rows Per Page` : `Lignes par Page`}
      setFunction={(newPage: string) => setPageSize(parseInt(newPage, 10))}
      small
    />
  );
}
