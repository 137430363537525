import {
  Compliance_Cases,
  Compliance_CasesReported,
  Compliance_CasesReporter,
  Enumcompliance_CasesStatus,
  Compliance_CasesStatusTransition,
  ComplianceRole,
} from 'generated';

export const COMPLIANCE_ROUTE = 'reportingtool' as const;

export const COMPLIANCE_CASE_STATUSES: readonly Enumcompliance_CasesStatus[] = [
  'SUBMITTED',
  'ASSISTANCE',
  'ESCALATED',
  'IN_REVIEW',
  'RETURNED',
  'CLOSED',
];

export type StrictComplianceCase = Compliance_Cases;

// = Omit<
//   Compliance_Cases,
//   'categoryOther' | 'statusTransition'
// > &
//   Pick<Partial<Compliance_Cases>, 'categoryOther' | 'statusTransition'>;

// export type ComplianceCaseStatus = typeof COMPLIANCE_CASE_STATUSES[number];

export type ComplianceCaseStatusTransitionChip = {
  from: Exclude<Enumcompliance_CasesStatus, 'ASSISTANCE'>;
  to: Exclude<Enumcompliance_CasesStatus, 'ASSISTANCE'>;
};

export type ComplianceCaseStatusDisplay = Enumcompliance_CasesStatus | 'IN_PROGRESS';

export const COMPLIANCE_ROLES: readonly ComplianceRole[] = [
  'DEALER',
  'BLVD',
  'HYUNDAI',
  'ZONE_MANAGER',
  'COMMITTEE',
] as const;

export const COMPLIANCE_CASE_CATEGORIES = ['PMA', 'ADVERTISING', 'PRICING', 'OTHER'] as const;

export type ComplianceCaseCategory = typeof COMPLIANCE_CASE_CATEGORIES[number];

export interface ComplianceAccused {
  dealerCode: string;
}

export const COMPLIANCE_CASE_NO_ID = undefined as unknown as Compliance_Cases['caseId'];
export const COMPLIANCE_CASE_NO_CATEGORY_OTHER =
  undefined as unknown as Compliance_Cases['caseInformation'][number]['categoryOther'];
export const COMPLIANCE_CASE_NO_TRANSITION =
  undefined as unknown as Compliance_CasesStatusTransition;

export const dateSort =
  (direction: 'ASC' | 'DESC') =>
  (a: { modifiedDate: string }, b: { modifiedDate: string }): number => {
    const sortNum = direction === 'ASC' ? 1 : -1;
    return new Date(a.modifiedDate) > new Date(b.modifiedDate) ? sortNum : -sortNum;
  };

export interface ReportFormState {
  reporterName: Compliance_CasesReporter['name'];
  reporterDealerCode: Compliance_CasesReporter['dealerCode'];
  reporterEmail: Compliance_CasesReporter['email'];
  reportedDealerCode: Compliance_CasesReported['dealerCode'];
  caseCategory: Compliance_Cases['caseInformation'][number]['category'];
  caseOtherCategory: Compliance_Cases['caseInformation'][number]['categoryOther'];
  caseOngoing: Compliance_Cases['caseInformation'][number]['ongoing'];
  caseDate: Compliance_Cases['caseInformation'][number]['infractionDate']; // This requires parsing into a string, it is intentional as dates are not serializable
  modifiedDate: Compliance_Cases['caseInformation'][number]['modifiedDate'];
  caseLocation: Compliance_Cases['caseInformation'][number]['infractionLocation'];
  caseSummary: Compliance_Cases['caseInformation'][number]['summary'];
  caseEvidence: File[]; // serialize
}

export const formToCreateCase = (
  form: ReportFormState,
  dealerName: string
): StrictComplianceCase => {
  return {
    _id: COMPLIANCE_CASE_NO_ID,
    reporter: {
      name: form.reporterName,
      dealerName: form.reporterName,
      dealerCode: form.reporterDealerCode,
      email: form.reporterEmail,
    },
    reported: {
      dealerName,
      dealerCode: form.reportedDealerCode,
    },

    caseInformation: [
      {
        infractionDate: form.caseDate.toString(),
        infractionLocation: form.caseLocation,
        category: form.caseCategory,
        categoryOther: form.caseCategory === 'OTHER' ? form.caseOtherCategory : '',

        ongoing: form.caseOngoing,
        summary: form.caseSummary,
        evidence: form.caseEvidence.map((evid) => ({
          filename: evid.name.replace(/[^a-zA-Z0-9_.-]+/g, '_'), // evid.name,
          mimetype: evid.type,
        })),
        modifiedDate: new Date().toUTCString(),
        revision: 0,
      },
    ],

    // evidence: form.caseEvidence.map((ev) => ev.name),

    inProgress: false,

    history: [],
    outcome: 'UNDETERMINED',
    outcomeDate: undefined as unknown as string,
    caseId: undefined as unknown as string,

    status: 'SUBMITTED',
    statusTransition: COMPLIANCE_CASE_NO_TRANSITION,
    statusPrevious: undefined as unknown as Enumcompliance_CasesStatus,
    repeatCaseIds: undefined as unknown as string[],
    testCase: false,
  };
};

export const generateArrayOfFunc =
  (qty: number) =>
  <T extends () => unknown>(func: T) =>
    [...new Array(qty)].map(func) as ReturnType<T>[];

export const validStatusTransitions = (
  status: StrictComplianceCase['status']
): Array<StrictComplianceCase['status']> => {
  switch (status) {
    case 'SUBMITTED':
    case 'ASSISTANCE':
      return ['ESCALATED', 'RETURNED', 'CLOSED'];
    case 'ESCALATED': {
      return ['RETURNED', 'CLOSED', 'IN_REVIEW'];
    }
    case 'IN_REVIEW': {
      return ['RETURNED', 'CLOSED'];
    }
    case 'RETURNED': {
      return [];
    }
    case 'CLOSED': {
      return [];
    }
  }
};

// This function is also used on the front-end.
// Ensure they remain synchronized if any changes occur
// (update on back-end and copy to front-end)
export const assigneeFromStatus = (
  status: Enumcompliance_CasesStatus
): ComplianceRole | null => {
  switch (status) {
    case 'SUBMITTED':
      return 'BLVD';
    case 'ASSISTANCE':
      return 'HYUNDAI';
    case 'ESCALATED':
      return 'ZONE_MANAGER';
    case 'IN_REVIEW':
      return 'COMMITTEE';
    case 'RETURNED':
      return 'DEALER';
    case 'CLOSED':
      return null;
  }
};

export const addDealerNotesForStatusUpdate = (status: Enumcompliance_CasesStatus): boolean => {
  switch (status) {
    case 'SUBMITTED':
      return false;
    case 'ASSISTANCE':
      return false;
    case 'ESCALATED': {
      return false;
    }
    case 'IN_REVIEW': {
      return false;
    }
    case 'RETURNED': {
      return true;
    }
    case 'CLOSED': {
      return true;
    }
  }
};

export const renderStatusAs = (
  status: Enumcompliance_CasesStatus,
  role: ComplianceRole
): ComplianceCaseStatusDisplay => {
  if (role === 'DEALER' && !['CLOSED', 'RETURNED'].includes(status)) {
    return 'IN_PROGRESS';
  }
  return status;
};

export const mockReportFormState: ReportFormState = {
  caseDate: new Date().toUTCString(),
  modifiedDate: new Date().toUTCString(),
  caseLocation: 'lorem ipsum lorem ipsum',
  reporterDealerCode: '41059',
  caseCategory: 'ADVERTISING',
  caseOngoing: 'YES',
  caseEvidence: [],
  caseOtherCategory: undefined as unknown as string,
  caseSummary:
    'lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum random number: ' +
    Math.random().toString(),
  reportedDealerCode: '41059',
  reporterEmail: 'test@test.com',
  reporterName: 'Person name',
};

export const canViewAdminPanel = (role: ComplianceRole): boolean => {
  switch (role) {
    case 'BLVD':
    case 'HYUNDAI':
      return true;
  }
  return false;
};

export type CaseAttentionContext = 'STANDARD' | 'RETURNED' | 'ASSISTANCE' | 'REPEAT';

export const determineAttentionContext = (
  aCase: StrictComplianceCase
): CaseAttentionContext => {
  if (aCase.repeatCaseIds) {
    return 'REPEAT';
  }

  switch (aCase.status) {
    case 'ASSISTANCE':
      return 'ASSISTANCE';
    case 'RETURNED':
      return 'RETURNED';
    default:
      return 'STANDARD';
  }
};

export const formatCaseId = (caseId: string): string => `${String(caseId).padStart(4, '0')}`;

export interface ComplianceEvidenceGetResponse {
  tempLink: string;
  name: string;
  type: string;
  thumnailTempLink: string;
}

export type CaseStatsUpdateType = 'REQUESTED' | 'CHANGED';

export const goToFormAnchor = (fieldName: string, caseId: string | null) => {
  setTimeout(() => {
    const params = caseId ? `?caseId=${caseId}` : '';
    window.location.href = '#' + fieldName + params; //Go to the target element.
    window.scrollBy(0, -150);
  }, 10);
};

export const casesSortedAscendingById = (cases: StrictComplianceCase[]): StrictComplianceCase[] => {
  return cases.slice().sort((a, b) => (Number(a.caseId) > Number(b.caseId) ? 1 : -1));
};

export const getLatestRevision = (
  aCase: Compliance_Cases
): Compliance_Cases['caseInformation'][number] | undefined => {
  const numberOfRevisions = aCase.caseInformation.length - 1;
  return aCase.caseInformation.find((info) => info.revision === numberOfRevisions);
};

export type ComplianceReportState =
| 'REPORT_LANDING'
| 'CONFIRM_CHECKLIST'
| 'REPORT_FORM'
| 'VERIFY_FORM'
| 'REPORT_SUBMITTED';
