import Typography from '@mui/material/Typography';
import React from 'react';
import {
  StPageSection,
  StPageSectionProps,
} from 'suites/sterling/molecules/sections/page/PageSection';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

interface ContentSectionProps extends StPageSectionProps {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
}

export function ContentSection(props: ContentSectionProps) {
  const { title, subtitle, children, ...passthroughProps } = props;

  // Add some additional spacing between the title/subtitle and the children content as per design
  const extraGap = children && (title || subtitle);

  return (
    <StPageSection {...passthroughProps} maxWidth={passthroughProps.maxWidth ?? 'xl'}>
      <Stack
        direction="column"
        alignItems="center"
        gap={6}
        sx={{ textAlign: 'center',
        // '& > *:not(div[class*=MuiBox])': { maxWidth: '70%' }
      }}
      >
        {typeof title === 'string' ? <Typography variant="h2">{title}</Typography> : title}
        {typeof subtitle === 'string' ? (
          <Typography variant="body1">{subtitle}</Typography>
        ) : (
          subtitle
        )}
        {extraGap && <Box sx={{ height: (theme) => theme.spacing(1) }} />}
        {children && <Box sx={{ width: '100%' }}>{children}</Box>}
      </Stack>
    </StPageSection>
  );
}
