import styled from 'styled-components';
import { OemTheme } from 'assets/styles/themes/functions';
import { resourceStyles } from '../coe-standards/styled.Resources';

export const HomeDiv = styled.div`
  ${resourceStyles};

  .reviews-start {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      color: ${OemTheme.colour.active};
    }
  }

  .intro {
    padding: 90px 0 100px;
  }

  .how-to {
    padding: 0 0 80px;

    @media (max-width: 898px) {
      padding: 90px 0 80px;
    }
  }

  .intro,
  .how-to {
    background-color: ${OemTheme.colour.background.secondary};
  }

  .features {
    @media (max-width: 898px) {
      padding: 90px 0 0;
    }
  }

  .program {
    padding: 0 0 80px;

    @media (max-width: 898px) {
      padding: 90px 0 0;
    }
  }

  .mmsc.content {
    h1 {
      border-top: none;
    }
  }

  .home-icon {
    color: #000;
    font-size: 90px;
    display: inline-block;
    text-decoration: none;
    margin-bottom: 30px;

    @media (max-width: 898px) {
      font-size: 60px;
    }
  }
`;
