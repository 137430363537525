import Box from "@mui/material/Box";
import React from "react";

// This should provide a non-blocking instant load while the interior components
// tight take time to render or make API calls

// Concept
// https://developers.google.com/web/fundamentals/architecture/app-shell

// The structure of it is taken from the MUI example
// https://mui.com/components/drawers/#permanent-drawer

interface AppProps {
  menu: React.ReactNode;
  page: React.ReactNode;
}

export function StAppShell(props: AppProps) {
  const { menu, page } = props;

  return (
    <Box sx={{ display: "flex" }}>
      {menu}
      <Box component="main" sx={{ flexGrow: 1, bgcolor: "background.default" }}>
        {page}
      </Box>
    </Box>
  );
}
