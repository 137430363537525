import { useDropzone } from 'react-dropzone';
import { StUploadCard } from '../../cards/upload/UploadCard';
import { Button } from 'components';
import { Galleryer } from './Galleryer';

export interface StDropzoneProps {
  label: string;
  setFiles?: (files: File[]) => void;
  files: File[];
  thumbnailHandler?: (f: File) => void;
}

export function StDropzone(props: StDropzoneProps) {
  const { label, setFiles, files, thumbnailHandler } = props;
  const onDrop = (acceptedFiles: File[]) => {
    // Keep it from uploading real files while testing
    // const onChange = (files: any) => uploadMutation({ variables: { file: files[0] } });
    // onChange(acceptedFiles);
    setFiles?.([...acceptedFiles]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <StUploadCard label={label} isDragActive={isDragActive} isActive={!!files.length}>
          <Galleryer
            filesOrLinks={{ data: files }}
            justifyContent="center"
            thumbnailHandler={thumbnailHandler}
          />
        </StUploadCard>
      </div>
      {files.length > 0 && (
        <Button
          sx={{ mt: 2 }}
          onClick={() => {
            setFiles?.([]);
          }}
        >
          Clear
        </Button>
      )}
    </>
  );
}
