import { fontSafe, colourSafe } from '../safe';

export const haccColours = {
  'hyundai-blue': colourSafe('HACC')('#002860'),
  'hyundai-red': colourSafe('HACC')('#e63312'),
  'hyundai-active-blue': colourSafe('HACC')('#007FA5'),
  'hyundai-sky-blue': colourSafe('HACC')('#AACAE6'),
  'hyundai-light-sand': colourSafe('HACC')('#F6F3F2'),
  'hyundai-sand': colourSafe('HACC')('#E4DCD3'),
  'hyundai-light-grey': colourSafe('HACC')('#f2efec'),
};

export const haccTypography = {
  'hyundai-bold-head': `${fontSafe('HACC')('Hyundai Bold Head')}, Helvetica, sans-serif`,
  'hyundai-regular-head': `${fontSafe('HACC')('Hyundai Regular Head')}, Helvetica, sans-serif`,
  'hyundai-medium-head': `${fontSafe('HACC')('Hyundai Medium Head')}, Helvetica, sans-serif`,
  'hyundai-bold-text': `${fontSafe('HACC')('Hyundai Bold Text')}, Helvetica, sans-serif`,
  'hyundai-regular-text': `${fontSafe('HACC')('Hyundai Regular Text')}, Helvetica, sans-serif`,
  'hyundai-medium-text': `${fontSafe('HACC')('Hyundai Medium Text')}, Helvetica, sans-serif`,
  'hyundai-light': `${fontSafe('HACC')('Hyundai Light')}, Helvetica, sans-serif`,
};
