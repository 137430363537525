import { Translation } from "suites/sterling/app/types";

const buttonLabel: Translation = {
  DEFAULT: {
    EN: "Logout",
    FR: "Se déconnecter",
  },
};

export const contentLogoutButton = {
  buttonLabel,
};
