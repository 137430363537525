import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { colours } from 'assets/styles/themes/resources';
import { OemTheme } from 'assets/styles/themes/functions';

export const buttonColours = {
  text: `${OemTheme.colour.blurred}`,
  border: colours.black,
  background: colours.white,
  textActive: colours.white,
  borderActive: `${OemTheme.colour.blurred}`,
  backgroundActive: `${OemTheme.colour.focused}`,
};

export const useButtonStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
        marginLeft: 0,
        textTransform: 'none',
        cursor: 'pointer',
      },
      oemButton: {
        color: buttonColours.text,
        backgroundColor: buttonColours.background,
        borderColor: buttonColours.border,
      },
      sterlingButton: {
        borderRadius: 0,
      },
      dashboardSelectors: {
        lineHeight: 1.25,
      },
      hoverOverride: {
        '&:hover': {
          color: buttonColours.textActive,
          backgroundColor: buttonColours.backgroundActive,
          borderColor: buttonColours.borderActive,
        },
      },
      noMargin: {
        margin: 0,
      },
      float: {
        width: 'auto',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        top: '0px',
        left: '100vw',
        transform: 'translateX(-100%)',
        'ms-transform': 'translateX(-200%)', // IE11
      },
      languageLogoutWrapper: {
        position: 'absolute',
        top: 0,
        right: 0,
        maxWidth: 'calc(100% - 380px) !important',
        zIndex: 1,
        marginRight: '16px',
        '& > div': {
          margin: '24px 8px 0 8px',
          '& > button': {
            marginTop: '3px !important'
          },
        },
      },
      hamburger: {
        top: '50%',
        transform: 'translate(-100%, -50%)',
        marginTop: 0,
      },
      buffer: {
        marginLeft: '8px',
      },
      loginButton: {
        width: '35%',
        [theme.breakpoints.down('md')]: {
          width: '60%',
        },
      },
    })
  )();
