import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import { setRegionCode, setDistrictCode } from 'sterling-redux/reducers/activeSelections';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'sterling-redux/store';
import clsx from 'clsx';
import { gql } from '@apollo/client';
import { useSegmentsByRoleQuery } from 'generated';
import { ErrorHandle, Loading, Button } from 'components';
import Grid from '@mui/material/Grid';
import { useButtonStyles, buttonColours } from './templates/styled.Button';
import { useLanguage } from 'components/util/Language';

// eslint-disable-next-line
gql`
  query segmentsByRole($assessmentCode: String!) {
    segmentsManyByRole(assessmentCode: $assessmentCode) {
      hierarchy
      name
      value
      parent
    }
  }
`;

export function SelectRegion() {
  const configData = useSelector((state: AppState) => state.configuration.data);
  const classes = useButtonStyles();
  const assessmentCode = useSelector(
    (state: AppState) => state.activeSelections.assessmentCode
  );
  const regionCode = useSelector((state: AppState) => state.activeSelections.regionCode);
  const districtCode = useSelector((state: AppState) => state.activeSelections.districtCode);
  const dispatch = useDispatch();
  const lang = useLanguage();

  const segmentsByRoleQuery = useSegmentsByRoleQuery({
    variables: { assessmentCode },
  });

  if (segmentsByRoleQuery.loading) {
    return <Loading debug="Loading segmentsByRoleQuery." />;
  }
  if (segmentsByRoleQuery.error) {
    return <ErrorHandle gqlError={segmentsByRoleQuery.error} />;
  }

  const regions = [
    ...new Set(
      segmentsByRoleQuery.data?.segmentsManyByRole.map((seg) =>
        seg.hierarchy === 0 ? seg.value : seg.parent
      )
    ),
  ].sort();

  const districts = [
    ...new Set(
      segmentsByRoleQuery.data?.segmentsManyByRole
        .filter((seg) => seg.parent === regionCode)
        .map((seg) => (seg.hierarchy === 1 ? seg.value : ''))
        .filter((val) => val !== '')
    ),
  ].sort();

  return (
    <>
      <Grid item xs={12} sm={4}>
        <p style={{ display: 'inline-block' }}>
          {lang === 'EN'
            ? configData?.config.region_label_en
            : configData?.config.region_label_fr}
          :{' '}
        </p>
        <ButtonGroup
          className={classes.buffer}
          color="primary"
          aria-label="outlined primary button group"
        >
          {regions.map((segValue, index) => {
            const active = segValue === regionCode;
            return (
              <Button
                key={`${index}-${segValue}`}
                style={
                  active
                    ? {
                        color: buttonColours.textActive,
                        backgroundColor: buttonColours.backgroundActive,
                        borderColor: buttonColours.borderActive,
                      }
                    : {
                        borderColor: buttonColours.borderActive,
                      }
                }
                className={clsx(classes.oemButton, classes.hoverOverride)}
                onClick={() => {
                  dispatch(setDistrictCode(''));
                  if (active) {
                    dispatch(setRegionCode(''));
                  } else {
                    dispatch(setRegionCode(segValue));
                  }
                }}
              >
                {segValue}
              </Button>
            );
          })}
        </ButtonGroup>
      </Grid>
      <Grid item xs={12} sm={8}>
        {districts.length > 0 && (
          <>
            <p style={{ display: 'inline-block' }}>
              {lang === 'EN'
                ? configData?.config.district_label_en
                : configData?.config.district_label_fr}
              :{' '}
            </p>
            <ButtonGroup
              className={classes.buffer}
              color="primary"
              aria-label="outlined primary button group"
            >
              {districts.map((segValue, index) => {
                const active = segValue === districtCode;
                return (
                  <Button
                    key={`${index}-${segValue}`}
                    style={
                      active
                        ? {
                            color: buttonColours.textActive,
                            backgroundColor: buttonColours.backgroundActive,
                            borderColor: buttonColours.borderActive,
                          }
                        : {
                            borderColor: buttonColours.borderActive,
                          }
                    }
                    className={clsx(classes.oemButton, classes.hoverOverride)}
                    onClick={() =>
                      active
                        ? dispatch(setDistrictCode(''))
                        : dispatch(setDistrictCode(segValue))
                    }
                  >
                    {segValue}
                  </Button>
                );
              })}
            </ButtonGroup>
          </>
        )}
      </Grid>
    </>
  );
}
