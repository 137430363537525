import { StPageSection } from 'suites/sterling/molecules/sections/page/PageSection';
import { TitleSection } from 'suites/sterling/molecules/sections/title/TitleSection';
import Typography from '@mui/material/Typography';
import {
  dateSort,
  assigneeFromStatus,
  StrictComplianceCase,
  CaseStatsUpdateType,
} from 'suites/compliance/app/business-logic';
import { useComplianceParams } from 'suites/compliance/app/hooks/useComplianceParams';
import { CaseInformationCard } from 'suites/compliance/components/cards/case-information/CaseInformationCard';
import { ReporterInformationCard } from 'suites/compliance/components/cards/reporter-information/ReporterInformationCard';
import { useComplianceRole } from 'suites/compliance/app/context/compliance-role';
import { CaseAttentionCard } from '../../subcomponents/CaseAttentionCard';
import { CaseHistoryTile } from 'suites/compliance/components/tiles/case-history/CaseHistoryTile';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { complianceAppContent as c } from 'suites/compliance/ComplianceApp.content';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import { Button } from 'components';
import { useEffect, useState } from 'react';
import { CaseUpdateModal } from '../../subcomponents/CaseUpdateModal';
import { useComplianceHistory } from 'suites/compliance/app/hooks/useComplianceHistory';
import {
  Compliance_CasesStatusTransition,
  useComplianceCasesManyQuery,
  useComplianceCaseUpdateInProgressMutation,
} from 'generated';
import { StModal } from 'suites/sterling/atomics/modals/Modal';
import { CaseUpdateConfirmationCard } from '../../subcomponents/CaseUpdateConfirmationCard';

interface CaseDetailPageProps {
  selectedCase: StrictComplianceCase;
  allowCaseManagement: boolean;
}

export function CaseDetailPage(props: CaseDetailPageProps) {
  const { selectedCase, allowCaseManagement } = props;
  const { inProgress, status } = selectedCase;
  const { clearParam } = useComplianceParams();
  const { role } = useComplianceRole();
  const isNotDealer = role !== 'DEALER';

  const { push } = useComplianceHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [statusUpdateType, setStatusUpdateType] = useState<CaseStatsUpdateType>();
  const [statusTransition, setStatusTransition] = useState<Compliance_CasesStatusTransition>();
  const t = useTranslation();
  const [showAllRevisions, setShowAllRevisions] = useState(false);
  const [updateInProgress] = useComplianceCaseUpdateInProgressMutation();
  const { refetch } = useComplianceCasesManyQuery();

  if (!role) throw Error('Permission denied');

  // Sets the "in-progress" status of cases based on viewing the case detail page
  useEffect(() => {
    // Only sets "in-progress" if the role viewing the case is the role assigned to the case
    if (inProgress === false && status !== 'CLOSED' && role === assigneeFromStatus(status)) {
      updateInProgress({
        variables: { caseId: selectedCase.caseId, inProgress: true },
        refetchQueries: ['complianceCasesMany'],
      });
    }
  }, [inProgress, status]);

  const onClick = (() => {
    // Button click handler for resubmitting a case
    if (selectedCase.status === 'RETURNED' && role === 'DEALER') {
      return () => {
        push(`/report?caseId=${selectedCase.caseId}`);
      };
    }

    // Button click handler for updating case status
    return () => {
      setModalOpen(true);
    };
  })();

  const revisionsVisible = showAllRevisions ? selectedCase.history.length : 1;
  const roleResponsible = assigneeFromStatus(selectedCase.status);
  const multipleRevisionsExist = selectedCase.caseInformation.length > 1;

  const onCloseUpdateConfirmation = () => {
    clearParam('caseId');
    setStatusUpdateType(undefined);
    refetch();
  };

  return (
    <>
      {statusUpdateType && statusTransition && (
        <StModal open={!!statusUpdateType} onClose={onCloseUpdateConfirmation} maxWidth="md">
          <CaseUpdateConfirmationCard
            onClose={onCloseUpdateConfirmation}
            updateType={statusUpdateType}
            transition={statusTransition}
          />
        </StModal>
      )}
      <CaseUpdateModal
        selectedCase={selectedCase}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onStatusChange={setStatusUpdateType}
        setStatusTransition={setStatusTransition}
      />
      <StPageSection variant="paper" noBottomPadding sx={{ mb: 10 }}>
        <Button
          variant="text"
          startIcon={<ArrowBackIos />}
          onClick={() => clearParam('caseId')}
        >
          {t(c.terms.back.JUST_BACK)}
        </Button>
      </StPageSection>

      <TitleSection
        noTopPadding
        noBottomPadding
        variant="paper"
        title={`Case-${String(selectedCase.caseId).padStart(4, '0')}`}
        sx={{ mb: 6 }}
      />

      {allowCaseManagement && role === roleResponsible && (
        <StPageSection variant="paper" noTopPadding>
          <CaseAttentionCard selectedCase={selectedCase} onClick={onClick} />
        </StPageSection>
      )}

      <StPageSection variant="paper" noTopPadding noBottomPadding>
        <Stack direction="column" alignItems="flex-start" gap={3}>
          {selectedCase.caseInformation
            .slice()
            .sort(dateSort('DESC'))
            .filter((_hist, idx) => idx < revisionsVisible)
            .map((info, idx) => {
              return (
                <CaseInformationCard
                  key={idx}
                  fullCase={selectedCase}
                  currentRevision={info}
                />
              );
            })}
          {multipleRevisionsExist && (
            <Button onClick={() => setShowAllRevisions(!showAllRevisions)}>
              {showAllRevisions === false
                ? t(c.pages.reviewCases.caseDetail.buttonLabelShowAll)
                : t(c.pages.reviewCases.caseDetail.buttonLabelHideAll)}
            </Button>
          )}
        </Stack>
      </StPageSection>

      <StPageSection variant="paper" noTopPadding sx={{ paddingTop: 3 }}>
        {isNotDealer && <ReporterInformationCard {...selectedCase} />}
      </StPageSection>

      <StPageSection variant="paper" noTopPadding>
        <Typography variant="h4" mb={5}>
          {t(c.pages.reviewCases.caseDetail.caseHistory)}
        </Typography>

        <Stack gap={3}>
          {selectedCase.history
            .slice()
            .sort(dateSort('ASC'))
            .map((hist) => {
              return <CaseHistoryTile key={hist.modifiedDate} {...hist} />;
            })}
        </Stack>
      </StPageSection>
    </>
  );
}
