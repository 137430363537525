import { Translation, LanguageLabel } from 'suites/sterling/app/types';
import { ComplianceRole, Compliance_Cases } from 'generated';
import {
  CaseAttentionContext,
  CaseStatsUpdateType,
  ComplianceCaseCategory,
  ComplianceCaseStatusDisplay,
  ComplianceReportState,
  ReportFormState,
} from './app/business-logic';

// Terms

const caseStatus: Record<ComplianceCaseStatusDisplay | 'IN_PROGRESS', LanguageLabel> = {
  SUBMITTED: {
    EN: 'Submitted',
    FR: 'Soumis',
  },
  ESCALATED: {
    EN: 'Escalated',
    FR: 'Passé à l’étape suivante',
  },
  RETURNED: {
    EN: 'Returned',
    FR: 'Renvoyé',
  },
  CLOSED: {
    EN: 'Closed',
    FR: 'Fermé',
  },
  IN_PROGRESS: {
    EN: 'In-progress',
    FR: 'En progrès',
  },
  ASSISTANCE: {
    EN: 'Assistance',
    FR: 'Aide',
  },
  IN_REVIEW: {
    EN: 'In-review',
    FR: 'En examen',
  },
};

// Assorted

interface TermsContent {
  noCases: LanguageLabel;
  back: Record<
    | Extract<ComplianceReportState, 'CONFIRM_CHECKLIST' | 'REPORT_FORM' | 'VERIFY_FORM'>
    | 'JUST_BACK',
    LanguageLabel
  >;
  outcome: LanguageLabel;
  internalNotes: LanguageLabel;
  internalNotesName: LanguageLabel;
  dealerNotes: LanguageLabel;
  action: LanguageLabel;
  caseNumber: LanguageLabel;
  case: LanguageLabel;
  assignee: LanguageLabel;
  status: LanguageLabel;
  reporter: LanguageLabel;
  reported: LanguageLabel;
  viewCaseDetails: LanguageLabel;
}

const terms: TermsContent = {
  noCases: {
    EN: 'No current cases.',
    FR: 'Pas de signalement.',
  },
  // The < Back button
  back: {
    JUST_BACK: {
      EN: 'Back',
      FR: 'Retour',
    },
    CONFIRM_CHECKLIST: {
      EN: 'Back to what is an infraction',
      FR: 'Retour à la définition d’une infraction',
    },
    REPORT_FORM: {
      EN: 'Back to have you read the checklist',
      FR: 'Retour à la liste de vérification',
    },
    VERIFY_FORM: {
      EN: 'Back to submit an infraction',
      FR: 'Retour à la soumission d’infraction',
    },
  },
  outcome: {
    EN: 'Outcome',
    FR: 'Résultats',
  },
  internalNotes: {
    EN: 'Notes for internal use',
    FR: 'Notes à usage interne',
  },
  internalNotesName: {
    EN: 'Full Name for internal use',
    FR: 'Nom à usage interne',
  },
  dealerNotes: {
    EN: 'Notes for dealer',
    FR: 'Notes à l’intention du concessionnaire',
  },
  action: {
    EN: 'Action',
    FR: 'Action',
  },
  caseNumber: {
    EN: 'Case number',
    FR: 'Numéro de dossier',
  },

  case: {
    EN: 'Case',
    FR: 'Dossier',
  },

  assignee: {
    EN: 'Assignee',
    FR: 'Assigné à',
  },

  status: {
    EN: 'Status',
    FR: 'Statut',
  },

  reporter: {
    EN: 'Reporter',
    FR: 'Signalé par',
  },

  reported: {
    EN: 'Reported',
    FR: 'Signalé',
  },

  viewCaseDetails: {
    EN: 'View Case Details',
    FR: 'Voir les détails du dossier',
  },
};

const caseStatusToAction: Record<Compliance_Cases['status'], LanguageLabel> = {
  SUBMITTED: {
    EN: 'Submit',
    FR: 'Soumettre',
  },
  ESCALATED: {
    EN: 'Escalate to Zone Manager',
    FR: 'Envoyer au directeur de zone',
  },
  RETURNED: {
    EN: 'Return to Dealer',
    FR: 'Renvoyer au concessionnaire',
  },
  CLOSED: {
    EN: 'Close',
    FR: 'Fermer',
  },
  ASSISTANCE: {
    EN: 'Request Assistance',
    FR: 'Obtenir de l’aide',
  },
  IN_REVIEW: {
    EN: 'Escalate to NDAC',
    FR: 'Envoyer au NDAC',
  },
};

const roles: Record<ComplianceRole, LanguageLabel> = {
  DEALER: {
    EN: 'Dealer',
    FR: 'Concessionnaire',
  },
  HYUNDAI: {
    EN: 'Hyundai',
    FR: 'Hyundai',
  },
  BLVD: {
    EN: 'BLVD',
    FR: 'BLVD',
  },
  ZONE_MANAGER: {
    EN: 'Zone Manager',
    FR: 'Directeur de zone',
  },
  COMMITTEE: {
    EN: 'NDAC',
    FR: 'NDAC',
  },
};

const categories: Record<ComplianceCaseCategory, LanguageLabel> = {
  ADVERTISING: {
    EN: 'Advertising',
    FR: 'Publicité',
  },
  OTHER: {
    EN: 'Other',
    FR: 'Autre',
  },
  PMA: {
    EN: 'PMA',
    FR: 'RCP',
  },
  PRICING: {
    EN: 'Pricing',
    FR: 'Prix',
  },
};

// Form terms

const formTerms: Record<Exclude<keyof ReportFormState, 'caseOtherCategory'>, LanguageLabel> = {
  reporterName: { EN: 'Name', FR: 'Nom' },
  reporterDealerCode: { EN: 'Dealer Code', FR: 'Code de la concession' },
  reporterEmail: { EN: 'Email', FR: 'Courriel' },
  reportedDealerCode: { EN: 'Dealer Code', FR: 'Code de la concession' },
  caseCategory: { EN: 'Module', FR: 'Module' },
  modifiedDate: { EN: 'Date Reported', FR: 'Date' },
  caseDate: { EN: 'Date Observed', FR: 'Date observée' },
  caseLocation: { EN: 'Location', FR: 'Location' },
  caseOngoing: { EN: 'Still Occurring', FR: 'Toujours en cours' },
  caseSummary: { EN: 'Summary', FR: 'Sommaire' },
  caseEvidence: { EN: 'Evidence', FR: 'Preuve' },
};

// PAGES

// Landing Page

interface LandingPageContent {
  menuLabel: LanguageLabel;
  sectionA: {
    title: LanguageLabel;
    subtitle: LanguageLabel;
    text: LanguageLabel;
  };
  sectionB: {
    title: LanguageLabel;
    text: LanguageLabel;
  };
  sectionC: {
    title: LanguageLabel;
    cardA: {
      title: LanguageLabel;
      subtitle: LanguageLabel;
    };
    cardB: {
      title: LanguageLabel;
      subtitleA: LanguageLabel;
      subtitleB: LanguageLabel;
      subtitleC: LanguageLabel;
      marketingPolicyLink: LanguageLabel;
    };
    cardC: {
      title: LanguageLabel;
      subtitle: LanguageLabel;
    };
  };
  sectionD: {
    title: LanguageLabel;
    subtitle: LanguageLabel;
    cardA: {
      title: LanguageLabel;
      subtitle: LanguageLabel;
    };
    cardB: {
      title: LanguageLabel;
      subtitle: LanguageLabel;
    };
    cardC: {
      title: LanguageLabel;
      subtitle: LanguageLabel;
    };
  };
  sectionE: {
    title: LanguageLabel;
    subtitle: LanguageLabel;
    buttonText: LanguageLabel;
  };
}

const landing: LandingPageContent = {
  menuLabel: {
    EN: 'About the Program',
    FR: 'À propos du programme',
  },
  sectionA: {
    title: {
      EN: 'Hyundai marketing compliance program.',
      FR: 'Programme de conformité aux normes de marketing Hyundai.',
    },
    subtitle: {
      EN: 'Dealer reporting tool',
      FR: 'Outil de signalement des concessionnaires',
    },
    text: {
      EN: 'As dealers, you’re all in this business together, and we’re here to help you succeed. While there’s nothing wrong with a bit of friendly competition, we must always strive for fairness.',
      FR: 'Nous sommes dans ce commerce à vos côtés et nous sommes ici pour aider tous nos concessionnaires à réussir. Un peu de concurrence est toujours bienvenue, cependant, nous devons toujours nous efforcer de traiter chacun de façon équitable. ',
    },
  },
  sectionB: {
    title: {
      EN: 'Compliance is key.',
      FR: 'La conformité est indispensable.',
    },
    text: {
      EN: 'The Hyundai Marketing Compliance Program is a final recourse in settling dealerships’ disputes. Before deciding to use it, consider your particular situation in a sincere manner. Apply the needed care and diligence for involved parties to investigate and resolve the matter. While a possible infraction may affect your dealership, we ask that you handle the issue with respect and dignity towards the other dealership. When all dealerships get along, this reflects well on the Hyundai brand and the customers we serve. ',
      FR: 'Le Programme de conformité aux normes de marketing Hyundai est un recours pour régler les litiges entre concessions qui ne peuvent se régler autrement. Avant de l’utiliser, examinez votre situation spécifique avec toute la sincérité possible. Faites preuve de diligence et de considération envers toutes les parties prenantes et tentez d’abord de prendre en compte tous les paramètres et de résoudre le problème. Une infraction peut évidemment avoir un impact négatif sur votre concession; cependant, nous vous demandons de gérer la situation avec dignité et respect envers l’autre concession. Quand toutes les concessions s’entendent, cela reflète positivement sur la marque Hyundai et les clients que nous servons. ',
    },
  },
  sectionC: {
    title: {
      EN: 'What kinds of infractions are there?',
      FR: 'Quelles sont les différentes infractions possibles?',
    },
    cardA: {
      title: { EN: 'Primary Marketing Area', FR: 'Région commerciale principale' },
      subtitle: {
        EN: 'When a dealer advertises outside of their Primary Marketing Area or PMA, it is considered a PMA infraction.',
        FR: 'Lorsqu’une concession publie des publicités en dehors de sa région commerciale principale, ou RCP, elle commet une infraction de RCP.',
      },
    },
    cardB: {
      title: { EN: 'Advertising', FR: 'Publicité' },
      subtitleA: {
        EN: 'When a dealer breaks the rules outlined in the ',
        FR: 'Lorsqu’une concession enfreint les règles de la ',
      },
      subtitleB: {
        EN: 'Hyundai Marketing Policy',
        FR: 'Politique de marketing de Hyundai',
      },
      subtitleC: {
        EN: ' this is considered an advertising infraction.',
        FR: ', elle commet une infraction publicitaire.',
      },
      marketingPolicyLink: {
        EN: '/hyundai-reviews/uploads/2022/03/2022_Marketing_Guidelines_EN.pdf',
        FR: '/hyundai-reviews/uploads/2022/03/2022_Marketing_Guidelines_FR.pdf',
      },
    },
    cardC: {
      title: { EN: 'Pricing', FR: 'Prix' },
      subtitle: {
        EN: 'When a dealer lists a price that is not following applicable laws, regulations and governing automotive bodies, this is considered a pricing infraction.',
        FR: 'Lorsqu’une concession annonce un prix qui ne suit pas les lois, règlements et conventions automobiles, elle commet une infraction de prix.',
      },
    },
  },
  sectionD: {
    title: {
      EN: 'What is acceptable dealer conduct?',
      FR: 'Qu’est-ce qui constitue une conduite acceptable?',
    },
    subtitle: {
      EN: 'Hyundai provides guidelines for what constitutes a possible infraction. But sometimes, what may be perceived as an infraction might actually be acceptable. So much depends on the context around the perceived infraction, so we ask that dealers use their best judgment in the spirit of fairness before they consider submitting a claim. ',
      FR: 'Hyundai fournit des directives pour déterminer ce qui peut constituer une infraction. Cependant, il peut arriver que ce qui est perçu comme une infraction soit en réalité acceptable. Tout dépend du contexte de la suspicion d’infraction. Nous demandons donc aux concessionnaires de faire preuve d’impartialité lors de l’examen d’une situation et de discernement lors du signalement d’une suspicion d’infraction. ',
    },
    cardA: {
      title: {
        EN: 'Promoting the Hyundai advantage.',
        FR: 'Promouvoir l’avantage Hyundai.',
      },
      subtitle: {
        EN: 'A dealer can promote the Hyundai brand, its products, product features and benefits as a competitive advantage as long as they follow Hyundai’s image standards.',
        FR: 'Une concession peut promouvoir la marque Hyundai, les produits Hyundai, les caractéristiques et les avantages des produits Hyundai et en souligner les avantages concurrentiels, du moment qu’elle respecte les normes d’image de marque de Hyundai. ',
      },
    },
    cardB: {
      title: { EN: 'Market your dealership.', FR: 'Faire la promotion de sa concession.' },
      subtitle: {
        EN: 'Leverage your dealership’s brand as long as it is clearly communicated that messaging and activity is dealer specific and does not imply the involvement of Hyundai Canada Corp, Hyundai Capital or Hyundai Motor Finance while following Hyundai image standards.',
        FR: 'Vous pouvez faire la promotion de la marque de votre concession, à condition de spécifier clairement que le message et l’activité sont spécifiques à la concession. Il est essentiel de suivre les normes de l’image de marque de Hyundai et de ne pas suggérer que Hyundai Canada Corp, Hyundai Capital ou Hyundai Motor Finance sont impliqués, de quelque façon que ce soit. ',
      },
    },
    cardC: {
      title: { EN: 'Be competitive.', FR: 'Se montrer concurrentiel' },
      subtitle: {
        EN: 'Offer competitive rates, offers and pricing as long as all rates, offers and pricing adheres to all applicable laws and regulations, including those specific to the Dealer’s jurisdiction/PMA.',
        FR: 'Une concession peut proposer des taux, des offres et des prix concurrentiels, du moment que ces taux, offres et prix sont conformes à toutes les lois et réglementations en vigueur dans le pays, la région et la RCP de la concession. ',
      },
    },
  },
  sectionE: {
    title: {
      EN: 'Respecting each other.',
      FR: 'Faire preuve de respect les uns envers les autres.',
    },
    subtitle: {
      EN: 'It is important to remember that infractions affect every dealer involved. In fact, any infraction you might claim against another dealership may be put on your own dealership in the future.\n\nWe all do better when we work together and respect each other’s businesses. Keep it respectful, and you may rarely, if ever, need to use the dealer reporting tool.',
      FR: 'N’oublions pas que les infractions affectent tous les concessionnaires. Une infraction que vous pourriez signaler aujourd’hui pourrait vous être reprochée demain.\n\nTout se passe toujours mieux lorsque nous collaborons et nous nous respectons mutuellement dans toutes nos activités professionnelles. En faisant preuve de respect les uns envers les autres, nous aurons rarement, et peut-être jamais, besoin d’utiliser l’outil de signalement des concessionnaires. ',
    },
    buttonText: {
      EN: 'Prepare to report an infraction',
      FR: 'Se préparer à signaler une suspicion d’infraction',
    },
  },
};

// Submit Report Page

interface SubmitReportLandingPageContent {
  menuLabel: LanguageLabel;
  sectionA: {
    title: LanguageLabel;
    subtitle: LanguageLabel;
    buttonText: LanguageLabel;
  };
  sectionB: {
    title: LanguageLabel;
    text: LanguageLabel;
  };
  sectionC: {
    title: LanguageLabel;
    cardA: {
      title: LanguageLabel;
      text: LanguageLabel;
    };
    cardB: {
      title: LanguageLabel;
      text: LanguageLabel;
    };
    cardC: {
      title: LanguageLabel;
      text: LanguageLabel;
    };
  };
  sectionD: {
    title: LanguageLabel;
    text: LanguageLabel;
    cardA: {
      title: LanguageLabel;
    };
    cardB: {
      title: LanguageLabel;
    };
    cardC: {
      title: LanguageLabel;
    };
  };
  sectionE: {
    title: LanguageLabel;
    subtitle: LanguageLabel;
    linkText: LanguageLabel;
  };
  sectionF: {
    title: LanguageLabel;
    subtitle: LanguageLabel;
    buttonText: LanguageLabel;
  };
}

const submitReportLanding: SubmitReportLandingPageContent = {
  menuLabel: {
    EN: 'Report an Infraction',
    FR: 'Signaler une infraction',
  },
  sectionA: {
    title: { EN: 'What is an infraction?', FR: 'Qu’est-ce qu’une infraction?' },
    subtitle: {
      EN: 'Dealers are in this business together. Striving for fairness and mutual respect helps everyone and gives our customers a good feeling about the Hyundai brand. So when you see a dealership engage in an activity that seems unfair, it may be appropriate to report it as an infraction.',
      FR: 'Tous les concessionnaires sont en affaires ensemble. L’équité et le respect mutuel aident chacun d’entre nous et donnent à nos clients une perception positive de la marque Hyundai. De ce fait, si vous constatez qu’une concession prend part à des activités qui semblent déloyales, vous pourriez envisager de signaler l’infraction. ',
    },
    buttonText: {
      EN: 'Read Hyundai’s marketing policy',
      FR: 'Lire la politique de marketing de Hyundai',
    },
  },
  sectionB: {
    title: { EN: 'About the process/timeline.', FR: 'Processus et délais.' },
    text: {
      EN: 'The process for registering and responding to infraction claims is outlined here, and the timeline for resolution will vary depending on the complexity of the situation and how many judicial parties need to be involved.',
      FR: 'Nous soulignons ici le processus de signalement des suspicions d’infraction et le processus de réponse à ces signalements. Veuillez noter que les délais de résolution dépendront de la complexité de la situation et du nombre d’examens requis pour trouver une solution. ',
    },
  },
  sectionC: {
    title: { EN: 'Program benefits.', FR: 'Avantages du Programme.' },
    cardA: {
      title: { EN: 'Stay up-to-date.', FR: 'Se tenir à jour.' },
      text: {
        EN: 'Track the status of reported infractions and stay up to date on their progress.',
        FR: 'Vous pouvez suivre le statut des signalements et voir les progrès réalisés.',
      },
    },
    cardB: {
      title: { EN: 'Keep it fair.', FR: 'Garantir l’équité.' },
      text: {
        EN: 'Establish consistency and fairness across all dealer advertising to ensure no one dealer has the upper hand.',
        FR: 'En mettant en œuvre l’équité et la cohérence dans toutes les publicités des concessionnaires, nous nous assurons qu’une concession n’est pas avantagée par rapport à une autre. ',
      },
    },
    cardC: {
      title: { EN: 'We all win.', FR: 'Nous sommes tous gagnants.' },
      text: {
        EN: 'Enforcing consistency and fairness across dealer communications strengthens the perception of the Hyundai brand which in turn benefits your dealership.',
        FR: 'Assurer la cohérence et l’équité des communications de toutes les concessions renforce la perception de la marque Hyundai, ce qui a un impact bénéfique sur votre concession.',
      },
    },
  },
  sectionD: {
    title: { EN: 'Repeat infraction.', FR: 'Infractions répétées.' },
    text: {
      EN: 'To see that the program is fair for all, Hyundai will make sure all dealers remain professional and respectful throughout the reporting process. Additionally, Hyundai will handle repeat infractions directly. Upon identifying a repeat infraction, Hyundai will immediately escalate the case to the National Dealer Advisory Council (NDAC), ensuring the appropriate party is held responsible. Repeat infractions are identified as the following:',
      FR: 'Pour que le programme soit juste pour tous, Hyundai s’assurera que toutes les concessions restent professionnelles et respectueuses pendant toute la durée du processus de signalement. De plus, Hyundai gérera directement les infractions répétées. Hyundai signalera immédiatement toute infraction répétée au Conseil national des concessionnaires (CNC), afin que les parties responsables soient confrontées. Les infractions répétées sont définies comme suit:',
    },
    cardA: {
      title: { EN: 'Cases of the same category', FR: 'Des cas de même catégorie' },
    },
    cardB: {
      title: {
        EN: 'Cases matching within 12 months',
        FR: 'Des cas identiques dans une fourchette de 12 mois',
      },
    },
    cardC: {
      title: {
        EN: 'Cases regarding the same dealer',
        FR: 'Des cas impliquant la même concession',
      },
    },
  },
  sectionE: {
    title: { EN: 'Have everything ready?', FR: 'Vous avez tout préparé?' },
    subtitle: {
      EN: 'Before you submit an infraction claim, you must have all the necessary materials ready. ',
      FR: 'Avant de signaler une suspicion d’infraction, vous devez avoir tous les éléments nécessaires à portée de main.',
    },
    linkText: { EN: 'Download the checklist', FR: 'Télécharger la liste de vérification' },
  },
  sectionF: {
    title: { EN: 'Report a dealership.', FR: 'Signalez une concession.' },
    subtitle: {
      EN: 'Please remember that these are your colleagues. If you really feel that they are doing something that requires our attention, you can submit the infraction claim here.',
      FR: 'N’oubliez jamais qu’il s’agit de vos collègues. Si vous pensez vraiment qu’il se passe quelque chose dont nous devons être au courant, veuillez signaler une suspicion d’infraction ici.',
    },
    buttonText: { EN: 'Submit an infraction', FR: 'Signaler une infraction' },
  },
};

interface FormInputContent {
  label: LanguageLabel;
  placeholder: LanguageLabel;
  tooltip: LanguageLabel;
}

interface ReportFormPageContent {
  titleTop: LanguageLabel;
  titleMiddle: LanguageLabel;
  titleBottom: LanguageLabel;
  intro: {
    title: LanguageLabel;
    text: LanguageLabel;
  };
  formFields: {
    reporterName: FormInputContent;
    reporterDealerCode: FormInputContent;
    reporterEmail: FormInputContent;
    caseCategory: FormInputContent;
    caseOtherCategory: FormInputContent;
    caseOngoing: FormInputContent & {
      options: {
        yes: LanguageLabel;
        no: LanguageLabel;
        unsure: LanguageLabel;
      };
    };
    caseDate: FormInputContent;
    caseLocation: FormInputContent;
    reportedDealerCode: FormInputContent;
    caseSummary: FormInputContent;
    caseEvidence: FormInputContent & { dropzoneLabel: LanguageLabel };
  };
  buttonSubmitLabel: {
    UPDATE: LanguageLabel;
    NEW: LanguageLabel;
  };
  buttonCancelLabel: LanguageLabel;
  errorMessages: {
    max10FilesAnd100MB: LanguageLabel;
    minLength: LanguageLabel;
    maxLength: LanguageLabel;
    min1File: LanguageLabel;
    max10Files: LanguageLabel;
    max100MB: LanguageLabel;
  };
}

const reportForm: ReportFormPageContent = {
  titleTop: {
    EN: 'Submit an infraction.',
    FR: 'Signaler une infraction',
  },
  titleMiddle: {
    EN: 'What would you like to report?',
    FR: 'Vous avez des questions?',
  },
  titleBottom: {
    EN: 'Detailing the infraction.',
    FR: 'Détailler l’infraction.',
  },
  intro: {
    title: {
      EN: 'Please complete the outlined information within this form.',
      FR: 'Veuillez remplir les informations demandées dans ce formulaire.',
    },
    text: {
      EN: 'The completion of this form is critical to the case handling. All information must be completed for the report to be considered and reviewed. Please take your time filling out this form to ensure all information is accurate and complete.',
      FR: 'Il est essentiel de remplir correctement ce formulaire pour que nous puissions gérer correctement la situation. Nous examinerons les signalements uniquement si toutes les informations sont fournies. Veuillez prendre votre temps pour remplir ce formulaire et assurez-vous que toutes les informations fournies sont exactes et complètes.',
    },
  },
  formFields: {
    reporterName: {
      label: { EN: 'What is your full name?', FR: 'Comment vous appelez-vous?' },
      placeholder: { EN: 'First name Last name', FR: 'Prénom Nom de famille' },
      tooltip: {
        EN: 'We need to know who to address during contact around the reported infraction.',
        FR: 'Veuillez nous indiquer qui contacter concernant le signalement de l’infraction.',
      },
    },
    reporterDealerCode: {
      label: {
        EN: 'Please select the dealership you are representing',
        FR: 'Veuillez sélectionner la concession que vous représentez',
      },
      placeholder: { EN: '', FR: '' },
      tooltip: {
        EN: 'We need to know what dealership you represent in order to ensure we can associate what dealership is reporting the infraction.',
        FR: 'Veuillez nous indiquer la concession que vous représentez afin que nous puissions vous associer à la concession qui réalise le signalement.',
      },
    },
    reporterEmail: {
      label: { EN: 'What is your email?', FR: 'Quelle est votre adresse courriel?' },
      placeholder: { EN: 'placeholder@example.com', FR: 'nom@exemple.com' },
      tooltip: {
        EN: 'We need your email in order to be able to contact you and update you related to your report.',
        FR: 'Veuillez nous indiquer votre adresse courriel, afin que nous puissions vous tenir au courant de l’évolution de votre signalement.',
      },
    },
    caseCategory: {
      label: {
        EN: 'What is the category of the infraction?',
        FR: 'Quelle est la catégorie de l’infraction?',
      },
      placeholder: { EN: '', FR: '' },
      tooltip: {
        EN: 'We need to know the type of infraction being reported in order to address it accordingly.',
        FR: 'Veuillez nous indiquer le type d’infraction que vous signalez, afin que nous puissions la traiter correctement.',
      },
    },
    caseOtherCategory: {
      label: {
        EN: 'What is the infraction related to?',
        FR: 'À quoi l’infraction est-elle reliée?',
      },
      placeholder: { EN: 'Type of infraction', FR: 'Type de l’infraction' },
      tooltip: {
        EN: 'We need to know more about the type of the infraction',
        FR: 'Veuillez nous donner davantage de détails sur le type d’infraction que vous signalez.',
      },
    },
    caseOngoing: {
      label: {
        EN: 'Is the infraction still occurring?',
        FR: 'L’infraction est-elle en cours actuellement?',
      },
      placeholder: { EN: '', FR: '' },
      tooltip: {
        EN: 'We need to know if the infraction is still happening so that we can address infractions still occurring or to ensure infractions are not repeated. ',
        FR: 'Veuillez nous indiquer si l’infraction est toujours en cours, afin que nous puissions gérer les infractions en cours ou éviter la répétition d’une infraction à l’avenir.',
      },
      options: {
        yes: { EN: 'Yes', FR: 'Oui' },
        no: { EN: 'No', FR: 'Non' },
        unsure: { EN: 'Unsure', FR: 'Je ne sais pas' },
      },
    },
    caseDate: {
      label: {
        EN: 'Please select the date of the infraction',
        FR: 'Veuillez sélectionner la date de l’infraction',
      },
      placeholder: { EN: '', FR: '' },
      tooltip: {
        EN: 'We need to know if the infraction is still happening so that we can address infractions still occurring or to ensure infractions are not repeated. ',
        FR: 'Veuillez nous indiquer la date de l’infraction, afin que nous puissions gérer les infractions en cours ou éviter la répétition d’une infraction à l’avenir.',
      },
    },
    caseLocation: {
      label: { EN: 'Please describe where you saw the infraction.', FR: 'Veuillez décrire l’endroit où vous avez constaté l’infraction.' },
      placeholder: { EN: 'Location', FR: 'Location' },
      tooltip: {
        EN: 'We need to know the location of the reported infraction.',
        FR: 'Nous devons connaître le lieu de l’infraction signalée.',
      },
    },
    reportedDealerCode: {
      label: {
        EN: 'Please select the dealership you are reporting.',
        FR: 'Veuillez sélectionner le nom de la concession pour laquelle vous faites un signalement.',
      },
      placeholder: { EN: '', FR: '' },
      tooltip: {
        EN: 'We need to know the dealership that is potentially at fault in order to ensure we can address the infraction with the proper dealership.',
        FR: 'Veuillez nous indiquer la concession étant l’objet de votre suspicion d’infraction, afin que nous puissions gérer l’infraction avec la concession concernée.',
      },
    },
    caseSummary: {
      label: {
        EN: 'Please describe the infraction in detail.',
        FR: 'Veuillez décrire l’infraction en détail',
      },
      placeholder: {
        EN: 'What is the reason for reporting this dealer?',
        FR: 'Pour quelle raison faites-vous un signalement concernant cette concession?',
      },
      tooltip: {
        EN: 'We need to have a detailed description of what the infraction is and why you believe it to be an infraction in order to appropriately make a decision.',
        FR: 'Afin que nous puissions prendre une décision adaptée, veuillez décrire en détail l’infraction que vous signalez et pourquoi l’action de la concession constitue, à votre avis, une infraction.',
      },
    },
    caseEvidence: {
      label: {
        EN: 'Please include proof of the infraction.',
        FR: 'Veuillez inclure une preuve de l’infraction',
      },
      placeholder: { EN: '', FR: '' },
      tooltip: {
        EN: 'We require proof in order to be able to make an appropriate decision. Please ensure the image you upload is of high-quality and in-focus.',
        FR: 'Afin que nous puissions prendre une décision adaptée, veuillez nous fournir une preuve de l’infraction que vous signalez. Veuillez vous assurer que les fichiers que vous téléversez sont de bonne qualité et lisibles.',
      },
      dropzoneLabel: {
        EN: 'Drag files or click to upload',
        FR: 'Faites glisser les fichiers ou cliquez ici pour les téléverser',
      },
    },
  },
  buttonSubmitLabel: {
    NEW: {
      EN: 'Report this dealer',
      FR: 'Signaler cette concession',
    },
    UPDATE: {
      EN: 'Update Case',
      FR: 'Mettre le signalement à jour',
    },
  },
  buttonCancelLabel: {
    EN: 'I do not want to report this dealer',
    FR: 'Je ne souhaite pas signaler cette concession',
  },
  errorMessages: {
    max10FilesAnd100MB: {
      EN: 'Maximum 10 files and 100MB size',
      FR: 'Maximum de 10 fichiers, d’une taille totale de 100 Mb',
    },
    minLength: {
      EN: 'Minimum length 100 characters',
      FR: 'Longueur minimale de 100 caractères',
    },
    maxLength: {
      EN: 'Maximum length 5000 characters',
      FR: 'Longueur maximale de 5 000 caractères',
    },
    min1File: {
      EN: 'Must upload at least one file',
      FR: 'Vous devez téléverser au moins un fichier',
    },
    max10Files: {
      EN: 'Cannot upload more than 10 files',
      FR: 'Vous ne pouvez pas téléverser plus de 10 fichiers.',
    },
    max100MB: {
      EN: 'Cannot upload more than 100MB of files',
      FR: 'La taille totale des fichiers téléversés ne doit pas dépasser 100 Mb',
    },
  },
};

export const reportFormStateLabel = (key: keyof ReportFormState): LanguageLabel => {
  switch (key) {
    case 'reporterName':
      return { EN: 'your name', FR: 'Votre nom' };
    case 'reporterDealerCode':
      return { EN: 'your dealer code', FR: 'Le code de votre concession' };
    case 'reporterEmail':
      return { EN: 'your email', FR: 'Votre adresse courriel' };
    case 'reportedDealerCode':
      return { EN: 'reporting dealer', FR: 'Concession effectuant le signalement' };
    case 'caseCategory':
      return { EN: 'infraction type', FR: 'Type d’infraction' };
    case 'caseOtherCategory':
      return { EN: 'other infraction type', FR: 'Autre type d’infraction' };
    case 'caseDate':
      return { EN: 'infraction date', FR: 'Date de l’infraction' };
    case 'modifiedDate':
        return { EN: 'infraction date', FR: 'Date de l’infraction' };
    case 'caseLocation':
        return { EN: 'infraction location', FR: 'Lieu de l’infraction' };
    case 'caseOngoing':
      return { EN: 'still happening', FR: 'Infraction en cours' };
    case 'caseSummary':
      return { EN: 'summary', FR: 'Sommaire' };
    case 'caseEvidence':
      return { EN: 'provided evidence', FR: 'Preuve(s) fournie(s)' };
  }
};

// Form Review

interface FormReviewPageContent {
  title: LanguageLabel;
  subtitle: LanguageLabel;
  allCorrect: {
    title: LanguageLabel;
    subtitle: LanguageLabel;
  };
  button: {
    labelCancel: LanguageLabel;
    labelConfirm: {
      NEW: LanguageLabel;
      UPDATE: LanguageLabel;
    };
  };
}

const formReview: FormReviewPageContent = {
  title: {
    EN: 'Please ensure your report is correct.',
    FR: 'Veuillez vous assurer que le signalement est exact.',
  },
  subtitle: {
    EN: 'A report could result in a dealer losing their license and people losing their jobs. Hyundai urges you to review your submission to ensure it is a just claim before submitting it.',
    FR: 'Un signalement peut entraîner la résiliation d’une concession et le licenciement d’employés. Hyundai vous invite à examiner soigneusement votre signalement et à vous assurer qu’il est juste avant de le soumettre.',
  },
  allCorrect: {
    title: {
      EN: 'Are all form fields correct?',
      FR: 'Tous les champs de ce formulaire sont-ils remplis correctement?',
    },
    subtitle: {
      EN: 'If you have gone through and ensured all fields are correct you report will be submitted and will be reviewed by Hyundai and team.',
      FR: 'Une fois que vous aurez vérifié que toutes les informations sont exactes, votre signalement sera envoyé et examiné par Hyundai et ses équipes.',
    },
  },
  button: {
    labelCancel: {
      EN: 'I have changed my mind, cancel report',
      FR: 'J’ai changé d’avis. Veuillez annuler mon signalement.',
    },
    labelConfirm: {
      UPDATE: {
        EN: 'This is correct, update case',
        FR: 'Mon signalement est complet et exact, mettre le signalement à jour',
      },
      NEW: {
        EN: 'This is correct, report dealer',
        FR: 'Mon signalement est complet et exact, et je souhaite l’envoyer',
      },
    },
  },
};

// Form Submitted

interface FormSubmittedPageContent {
  title: LanguageLabel;
  subtitle: LanguageLabel;
  caseNumber: LanguageLabel;
  linkSupportLabel: LanguageLabel;
  buttonSubmittedLabel: LanguageLabel;
}

const formSubmitted: FormSubmittedPageContent = {
  title: {
    EN: 'Thank you for submitting.',
    FR: 'Nous vous remercions de nous avoir envoyé votre signalement.',
  },
  subtitle: {
    EN: `Your submission will be thoroughly reviewed and updated based on the information reported. You will receive critical updates regarding the case status via email. You're also welcome to check your submitted case(s) to view any changes from time to time.\n\n More information regarding the case steps is available in the video below. If you still have questions about the Hyundai marketing compliance program, please `,
    FR: `Votre signalement sera examiné en détail et mis à jour selon les informations fournies. Nous vous enverrons les mises à jour importantes concernant le statut du signalement par courriel. Si vous le souhaitez, vous pouvez également consulter votre ou vos signalements régulièrement pour voir si des changements ont été effectués. \n\n Vous pouvez obtenir des informations supplémentaires concernant les différentes étapes dans la vidéo ci-dessous. Si vous avez d’autres questions concernant le programme de conformité marketing de Hyundai, veuillez`,
  },
  caseNumber: {
    EN: 'Your case number is: ',
    FR: 'Le numéro de dossier est le : ',
  },
  linkSupportLabel: {
    EN: 'reach out to support.',
    FR: 'contacter l’assistance aux signalements.',
  },
  buttonSubmittedLabel: {
    EN: 'Go to submitted cases',
    FR: 'Voir les signalements',
  },
};

// Confirm checklist
interface ConfirmChecklistPageContent {
  title: LanguageLabel;
  text: LanguageLabel;
  linkChecklistLabel: LanguageLabel;
  linkMarketingLabel: LanguageLabel;
  titleDoYouUnderstand: LanguageLabel;
  checkboxLabelChecklist: LanguageLabel;
  checkboxLabelMarketing: LanguageLabel;
  checkboxLabelImpact: LanguageLabel;
  linkCancel: LanguageLabel;
  buttonReportLabel: LanguageLabel;
}

const checklist: ConfirmChecklistPageContent = {
  title: {
    EN: 'Have you viewed the checklist?',
    FR: 'Avez-vous consulté la liste de vérification?',
  },
  text: {
    EN: `Remember, it's important to note that potential infractions can escalate and result in penalties for the dealer failing to comply with Hyundai's marketing policies. Each claim is a serious matter—therefore, please ensure you have read and understood the Hyundai Marketing Policy Bulletin before reporting an infraction. \n\nWe have created and provided below an infraction report checklist to aid with your claim preparation.`,
    FR: `Il est important de savoir que les signalements peuvent entraîner des pénalités pour une concession qui ne respecte pas les politiques de marketing de Hyundai. Chaque signalement est grave. Nous vous invitons donc à lire et comprendre la Politique de marketing de Hyundai avant de signaler une infraction. \n\n Vous trouverez ci-dessous une liste de vérification des signalements d’infraction, afin de vous aider à préparer votre signalement.`,
  },
  linkChecklistLabel: {
    EN: 'Download the checklist',
    FR: 'Télécharger la politique de marketing',
  },
  linkMarketingLabel: {
    EN: 'Download the marketing policy',
    FR: 'Télécharger la politique de marketing',
  },
  titleDoYouUnderstand: {
    EN: 'Do you understand infractions and the impact of reporting one?',
    FR: 'Êtes-vous bien au clair concernant la définition des infractions et de ce qu’un signalement pourrait entraîner?',
  },
  checkboxLabelChecklist: {
    EN: 'Yes, I have read the checklist',
    FR: 'Oui, j’ai lu la liste de vérification',
  },
  checkboxLabelMarketing: {
    EN: 'Yes, I have read the marketing policy',
    FR: 'Oui, j’ai lu la politique de marketing',
  },
  checkboxLabelImpact: {
    EN: 'Yes, I understand the impact of reporting a dealer',
    FR: 'Oui, je suis bien conscient∙e de l’impact qu’un signalement pourrait avoir sur une concession',
  },
  linkCancel: {
    EN: 'Cancel, I have changed my mind',
    FR: 'J’ai changé d’avis. Veuillez annuler mon signalement.',
  },
  buttonReportLabel: { EN: 'Report a dealer', FR: 'Signaler une concession' },
};

// Review Cases Page

interface ReviewCasesPageContent {
  menuLabel: LanguageLabel;
  allCases: {
    title: Translation;
    submitted: Translation;
    archived: Translation;
  };
}

const reviewCases: ReviewCasesPageContent = {
  menuLabel: {
    EN: 'Submitted Cases',
    FR: 'Signalements',
  },
  allCases: {
    title: {
      DEFAULT: {
        EN: 'Submitted cases',
        FR: 'Signalements',
      },
    },
    submitted: {
      DEFAULT: {
        EN: 'Your submitted cases.',
        FR: 'Signalements soumis.',
      },
    },
    archived: {
      DEFAULT: {
        EN: 'Your archived cases.',
        FR: 'Signalements archivés.',
      },
    },
  },
};

// Case Detail page

interface CaseDetailPageContent {
  // caseSummary: LanguageLabel;
  caseInformation: LanguageLabel;
  submittedMedia: LanguageLabel;
  reporterInformation: LanguageLabel;
  caseHistory: LanguageLabel;
  buttonLabelShowAll: LanguageLabel;
  buttonLabelHideAll: LanguageLabel;
}

const caseDetail: CaseDetailPageContent = {
  // caseSummary: {
  //   EN: 'Case Summary.',
  //   FR: 'Sommaire du signalement.',
  // },
  caseInformation: {
    EN: 'Case information',
    FR: 'Informations sur les signalements',
  },
  submittedMedia: {
    EN: 'Submitted Media',
    FR: 'Média soumis',
  },
  reporterInformation: {
    EN: 'Reporter Information',
    FR: 'Coordonnées de la personne ayant réalisé le signalement',
  },
  caseHistory: {
    EN: 'Case History',
    FR: 'Historique du signalement',
  },
  buttonLabelShowAll: {
    EN: 'Show Revisions',
    FR: 'Afficher les révisions',
  },
  buttonLabelHideAll: {
    EN: 'Hide Revisions',
    FR: 'Cacher les révisions',
  },
};

// Support Page

interface SupportPageContent {
  menuLabel: LanguageLabel;
  subtitle: LanguageLabel;
  buttonText: LanguageLabel;
  email: {
    label: LanguageLabel;
    address: LanguageLabel;
    description: LanguageLabel;
  };
  telephone: {
    label: LanguageLabel;
    number: LanguageLabel;
    description: LanguageLabel;
  };
  contacting: LanguageLabel;
  frequentlyAsked: {
    title: LanguageLabel;
    subtitle: LanguageLabel;
  };
}

const SUPPORT_EMAIL: LanguageLabel = {
  EN: 'hyundai.support@blvdagency.com',
  FR: 'hyundai.support@blvdagency.com',
};

const SUPPORT_TELEPHONE: LanguageLabel = {
  EN: '1-888-894-5733',
  FR: '1-888-894-5733',
};

const support: SupportPageContent = {
  menuLabel: {
    EN: 'Get Help',
    FR: 'Obtenir de l’aide',
  },
  subtitle: {
    EN: 'Have questions? Reach out to a Hyundai infraction ambassador, and we will try our best to help you through this process. ',
    FR: 'Vous avez des questions? Veuillez contacter un ambassadeur des infractions de Hyundai. Nous nous efforcerons de vous aider pendant le processus de signalement.',
  },
  buttonText: { EN: 'View our support page', FR: 'Voir notre page d’aide' },
  contacting: {
    EN: 'Contacting support.',
    FR: 'Contacter l’assistance aux signalements.',
  },
  email: {
    address: SUPPORT_EMAIL,
    label: { EN: 'Email', FR: 'Courriel' },
    description: {
      EN: 'Expect a response back within 24 hours regarding your query.',
      FR: 'Nous répondrons à votre demande dans les 24 heures ouvrables.',
    },
  },
  telephone: {
    number: SUPPORT_TELEPHONE,
    label: { EN: 'Telephone', FR: 'Téléphone' },
    description: {
      EN: 'Our phone support is available Monday–Friday 9am to 5pm EST.',
      FR: 'Notre assistance téléphonique est disponible du lundi au vendredi, de 9 h à 17 h (HNE)',
    },
  },
  frequentlyAsked: {
    title: { EN: 'Frequently asked questions.', FR: 'Foire aux questions.' },
    subtitle: {
      EN: 'For your convenience, we have collected a variety of Frequently Asked Questions into one area. ',
      FR: 'Afin de vous aider, nous avons regroupé plusieurs questions fréquemment posées dans un seul et même document.',
    },
  },
};

// Admin Panel

interface AdminPanelContent {
  menuLabel: LanguageLabel;
  allCases: { title: LanguageLabel };
}

const adminPanel: AdminPanelContent = {
  menuLabel: {
    EN: 'Admin Panel',
    FR: 'Panneau de l’administrateur',
  },
  allCases: {
    title: {
      EN: 'Infraction History',
      FR: 'Historique d’infractions',
    },
  },
};

// Components

interface CaseAttentionCardContent {
  title: LanguageLabel;
  textA: LanguageLabel;
  textB?: LanguageLabel;
  buttonLabel: LanguageLabel;
}

const DEFAULT_ATTENTION_BUTTON: LanguageLabel = {
  EN: 'Update status',
  FR: 'Mettre à jour le statut',
};

const DEFAULT_ATTENTION_TITLE: LanguageLabel = {
  EN: 'This case requires your attention!',
  FR: 'Ce signalement exige votre attention!',
};

const caseAttentionCard: Record<CaseAttentionContext, CaseAttentionCardContent> = {
  STANDARD: {
    title: DEFAULT_ATTENTION_TITLE,
    textA: {
      EN: 'Please review the case and update the status accordingly.',
      FR: 'Veuillez examiner le signalement et mettre à jour son statut.',
    },
    textB: undefined,
    buttonLabel: DEFAULT_ATTENTION_BUTTON,
  },
  RETURNED: {
    title: {
      EN: 'Your case has been returned.',
      FR: 'Le signalement a été renvoyé.',
    },
    textA: {
      EN: 'Your case is missing information or contains unclear information. Please resubmit the claim with updated information so that Hyundai can review it.',
      FR: 'Le signalement que vous nous avez envoyé est incomplet ou contient des informations qui ne sont pas suffisamment claires. Veuillez soumettre à nouveau votre signalement et mettre les informations à jour, afin que Hyundai puisse l’examiner.',
    },
    textB: undefined,
    buttonLabel: { EN: 'Update submitted case', FR: 'Mettre à jour le signalement soumis' },
  },
  ASSISTANCE: {
    title: DEFAULT_ATTENTION_TITLE,
    textA: {
      EN: 'BLVD has reviewed the case and recommends a status change. Please review the case, the recommended change and update the status accordingly.',
      FR: 'BLVD a examiné le signalement et recommande un changement de statut. Veuillez prendre connaissance du signalement et du changement recommandé, et mettre le statut à jour.',
    },
    textB: undefined,
    buttonLabel: DEFAULT_ATTENTION_BUTTON,
  },
  REPEAT: {
    title: {
      EN: 'This case has been flagged.',
      FR: 'Nous attirons votre attention sur ce signalement.',
    },
    textA: {
      EN: 'This case has been flagged as a potential repeat of a previous offense',
      FR: 'Ce signalement semble être une infraction répétée',
    },
    textB: {
      EN: 'This case matches the following case(s):',
      FR: 'Ce signalement correspond aux signalement(s) suivant(s) :',
    },
    buttonLabel: DEFAULT_ATTENTION_BUTTON,
  },
};

interface CaseUpdateConfirmationCardContent {
  title: LanguageLabel;
  text: LanguageLabel;
}

const caseUpdateConfirmationCardContent: Record<
  CaseStatsUpdateType,
  CaseUpdateConfirmationCardContent
> = {
  CHANGED: {
    title: {
      EN: 'You have successfully changed the status',
      FR: 'Vous avez mis le statut à jour avec succès',
    },
    text: {
      EN: 'The status of this case has been updated to reflect your changes.',
      FR: 'Le statut de ce signalement a été modifié pour correspondre à vos changements.',
    },
  },
  REQUESTED: {
    title: {
      EN: 'You have successfully requested a status change',
      FR: 'Votre demande de changement de statut a bien été prise en compte',
    },
    text: {
      EN: 'Your update suggestion has been sent to HAC for confirmation.',
      FR: 'Votre suggestion de mise à jour a été envoyée à Hyundai pour confirmation.',
    },
  },
};

interface CaseUpdateModalContent {
  title: LanguageLabel;
  textSummarizeReason: LanguageLabel;
  textAgreeWithBLVD: LanguageLabel;
  agreeWithBLVDOption: { YES: LanguageLabel; NO: LanguageLabel };
  whatIsOutcome: LanguageLabel;
  inputPlaceholder: LanguageLabel;
  inputPlaceholderName: LanguageLabel;
  buttonLabelCancel: LanguageLabel;
  buttonLabelSubmit: LanguageLabel;
}

const caseUpdateModal: CaseUpdateModalContent = {
  title: {
    EN: 'Update Case',
    FR: 'Mettre le signalement à jour',
  },
  textSummarizeReason: {
    EN: 'If you have read the case and are ready to change the status please update the status below and summarize the reasoning.',
    FR: 'Si vous avez étudié le signalement et souhaitez modifier le statut, veuillez le mettre à jour ci-dessous et expliquer les raisons de votre changement.',
  },
  textAgreeWithBLVD: {
    EN: 'Do you agree with the status suggested by BLVD?',
    FR: 'Êtes-vous d’accord avec la conclusion de BLVD?',
  },
  agreeWithBLVDOption: {
    YES: { EN: 'Yes, confirm change', FR: 'Oui, veuillez confirmer les changements' },
    NO: { EN: 'No, choose different status', FR: 'Non, veuillez choisir un autre statut' },
  },
  whatIsOutcome: {
    EN: 'What is the outcome?',
    FR: 'Quels sont les résultats?',
  },
  inputPlaceholder: {
    EN: 'I am changing this status because...',
    FR: 'Je modifie le statut parce que…',
  },
  inputPlaceholderName: {
    EN: 'Full Name...',
    FR: 'Nom…',
  },
  buttonLabelCancel: {
    EN: 'I am not ready to update the status',
    FR: 'Je ne suis pas prêt∙e à modifier le statut',
  },
  buttonLabelSubmit: { EN: 'Submit status change', FR: 'Soumettre un changement de statut' },
};

// Assorted

const haveQuestions: LanguageLabel = {
  EN: 'Have questions?',
  FR: 'Vous avez des questions?',
};

// Error page

const permissionTextA: LanguageLabel = {
  EN: 'You do not have permission to view this page.',
  FR: 'Vous n’avez pas la permission de consulter cette page.',
};

const permissionTextB: LanguageLabel = {
  EN: 'Please click here to return home.',
  FR: 'Veuillez cliquer ici pour retourner à la page d’accueil',
};

const permissionTextC: LanguageLabel = {
  EN: 'If you believe you are seeing this message in error, please contact support: .',
  FR: 'If you believe you are seeing this message in error, please contact support: ',
};

const faq: Record<number, Record<'Q' | 'A', LanguageLabel>> = {
  1: {
    Q: {
      EN: 'How do I report a case?',
      FR: 'Comment puis-je signaler une suspicion d’infraction?',
    },
    A: {
      EN: 'After reading the marketing policy guidelines and the infraction checklist, a case can be reported by completing the form on the ‘report an infraction’ page.',
      FR: `Après avoir lu notre politique de marketing et consulté la liste de vérification des infractions, vous pouvez signaler une suspicion d’infraction en remplissant le formulaire que vous trouverez sur la page 'Signaler une infraction'`,
    },
  },
  2: {
    Q: {
      EN: `How can I check the status of a reported case?`,
      FR: `Comment puis-je vérifier le statut d’un signalement? `,
    },
    A: {
      EN: `You can check the status of a case either by checking your email for a notification when a case has been updated or by viewing the case detail page found on the ‘submitted cases’ page`,
      FR: `Pour vérifier le statut d’un signalement, consultez vos courriels. En effet, nous vous enverrons une notification lorsque nous mettrons le signalement à jour. Vous pouvez également consulter les détails de l’infraction sur la page des 'Signalements effectués'`,
    },
  },
  3: {
    Q: {
      EN: `How long will it take once I report a case?`,
      FR: `Combien de temps le processus prend-il après un signalement?`,
    },
    A: {
      EN: `The time it takes to complete a case varies as several parties must vet it throughout the process. You will be notified of key changes as the case moves through the process. Changes such as the case requires more information or when a case has been officially closed`,
      FR: `Cela dépend du signalement, car plusieurs parties doivent être impliquées dans le processus. Nous vous aviserons des changements importants au fur et à mesure que nous avançons, notamment si nous avons besoin de davantage de renseignements ou si le signalement est officiellement clos.`,
    },
  },
  4: {
    Q: {
      EN: `What does it mean when a case is returned?`,
      FR: `Que se passe-t-il si un signalement d’infraction est renvoyé?`,
    },
    A: {
      EN: `A case is returned when Hyundai requires more information about a particular aspect of the infraction you reported. You will be notified of the return and what specific information Hyundai needs to update the case when this happens. You will then be asked to update the form you initially submitted when reporting the infraction with that information. `,
      FR: `Nous renvoyons un signalement si Hyundai a besoin de davantage de renseignements sur un domaine particulier de l’infraction signalée. Nous vous aviserons du renvoi et des informations dont Hyundai a besoin pour poursuivre le processus. Nous vous demanderons ensuite de mettre à jour le formulaire que vous nous avez envoyé au moment du signalement, en incluant les informations demandées.`,
    },
  },
  5: {
    Q: { EN: `What is a repeat infraction?`, FR: `Qu’est-ce qu’une infraction répétée?` },
    A: {
      EN: `A repeat infraction is an infraction of the same category as one a dealer was already found to be in violation of within a 12 month period. For example, Dealer A was found to violate Hyundai’s Advertising policy when they offered iPads to anyone who purchased a vehicle from their dealership in October. In February, Dealer A was reported again for offering TVs to customers who purchased a vehicle. In this case, the second infraction is labelled as a repeat. It is flagged within the system, and Hyundai is made aware that Dealer A was penalized for this behaviour in the past. `,
      FR: `Une infraction répétée est une deuxième infraction de même catégorie qu’une autre infraction, commise par la même concession dans une période de 12 mois. Prenons un exemple précis : En octobre dernier, une concession a signalé Concession A parce qu’elle offrait un iPad à tous les clients qui achetaient un véhicule, ce qui représente une violation des publicités de Hyundai. En février, une autre concession a de nouveau signalé Concession A parce qu’elle offrait une télévision à tous les clients qui achetaient un véhicule. Dans cette situation, il s’agit d’une infraction répétée. Le système avertit donc Hyundai que Concession A a déjà été pénalisée pour ce genre de comportement il y a moins d’un an.`,
    },
  },
  6: {
    Q: {
      EN: `What does it mean when a case is closed?`,
      FR: `Que signifie un signalement clos?`,
    },
    A: {
      EN: `When a case is closed, it has completed the review process, and Hyundai has decided on the case. In most cases, your claim has either been dismissed, or the dealer responsible for the infraction has been penalized. Closed cases are clearly labelled as such and can be found within your’ archived cases’.`,
      FR: `Lorsqu’un signalement est clos, cela signifie que le processus d’examen est terminé et que Hyundai a pris une décision concernant la suspicion d’infraction. Dans la plupart des cas, la suspicion a été jugée infondée ou la concession responsable de l’infraction a été pénalisée. Les signalements clos sont clairement identifiés et vous pouvez les trouver dans la section 'Signalements archivés'.`,
    },
  },
};

const infractionChecklistLink: LanguageLabel = {
  EN: '/hyundai-reviews/uploads/2022/03/HAC-Infraction-Checklist-EN.pdf',
  FR: '/hyundai-reviews/uploads/2022/03/HAC-Infraction-Checklist-FR.pdf',
};

export const complianceAppContent = {
  infractionChecklistLink,
  permissions: {
    a: permissionTextA,
    b: permissionTextB,
    c: permissionTextC,
  },
  terms,
  formTerms,
  caseStatus,
  caseStatusToAction,
  roles,
  categories,
  haveQuestions,
  faq,
  pages: {
    landing,
    submitReport: {
      checklist,
      landing: submitReportLanding,
      form: reportForm,
      review: formReview,
      submitted: formSubmitted,
    },
    reviewCases: {
      landing: reviewCases,
      caseDetail,
    },
    support,
    adminPanel,
  },
  components: {
    caseAttentionCard,
    caseUpdateModal,
    caseUpdateConfirmation: caseUpdateConfirmationCardContent,
  },
};
