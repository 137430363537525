import styled from 'styled-components';
import { OemTheme } from 'assets/styles/themes/functions';

export const ChartCardWrapper = styled.div`
  .divider-line {
    border: 0;
    border-bottom: 2px solid ${OemTheme.colour.background.primary};
    width: 95%;
    margin-left: 0;
  }

  .bar-percentage {
    font-weight: bold;
    font-size: 12px;
    z-index: 1;
  }

  .recharts-surface,
  .recharts-label-list {
    overflow: visible;
  }

  svg:nth-child(1) > g:nth-child(4) > g:nth-child(1) > g:nth-child(1) > path:nth-child(2) {
    fill: ${OemTheme.colour.graph.bar.primary};
  }

  .text-percentage {
    fill: rgb(0, 0, 0);
    font-size: 30px;
    font-weight: bold;

    @media (max-width: 898px) {
      font-size: 20px;
    }
  }
`;
