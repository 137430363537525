import { Theme, createTheme, responsiveFontSizes, adaptV4Theme } from '@mui/material/styles';
import { colours } from 'assets/styles/themes/resources';
import { css } from 'styled-components';
import { haccTypography } from './resources.hacc';

const config = {
  colour: {
    default: colours['hyundai-blue'],
    focused: colours['hyundai-active-blue'],
    blurred: colours['hyundai-blue'],
    active: colours['hyundai-active-blue'],
    buttonOutline: colours.black,
    titleBorder: colours['hyundai-blue'],
    requiredDot: colours['hyundai-red'],
    rewardsTable: colours['hyundai-blue'],
    tag: {
      standard: {
        text: colours.black,
        background: colours['hyundai-sky-blue'],
      },
      alternate: {
        text: colours.black,
        background: colours['hyundai-sky-blue'],
      },
    },
    calendarTile: {
      background: {
        upcoming: colours['hyundai-sky-blue'],
        upNext: colours['hyundai-blue'],
        TBA: colours['hyundai-sand'],
      },
      text: {
        upcoming: colours.black,
        upNext: colours.white,
        TBA: colours.black,
      },
    },
    text: {
      default: colours.black,
      anchor: colours['hyundai-active-blue'],
    },
    indicator: {
      active: colours['hyundai-active-blue'],
      inactive: colours.white,
    },
    background: {
      primary: colours['hyundai-light-grey'],
      secondary: colours['hyundai-light-sand'],
      toggleTrack: colours['hyundai-active-blue'],
    },
    graph: {
      primary: colours['hyundai-active-blue'],
      track: colours['hyundai-light-grey'],
      bar: {
        primary: colours['hyundai-active-blue'],
        secondary: colours['hyundai-blue'],
      },
    },
    nav: {
      background: colours.white,
      mobileBackground: colours.white,
      link: {
        default: colours.black,
        selected: colours.white,
        selectedOnWhite: colours['hyundai-active-blue'],
        hover: colours.black,
        background: {
          selected: colours['hyundai-active-blue'],
          hover: colours['hyundai-sky-blue'],
        },
        tertiaryText: colours.black,
      },
    },
    preferredVendorPage: {
      modalBackground: colours['hyundai-sand'],
    },
  },
  font: {
    headerBold: haccTypography['hyundai-bold-head'],
    headerMedium: haccTypography['hyundai-medium-head'],
    light: haccTypography['hyundai-light'],
    regular: haccTypography['hyundai-regular-text'],
    medium: haccTypography['hyundai-medium-text'],
    bold: haccTypography['hyundai-bold-text'],
    colonHeader: haccTypography['hyundai-medium-text'],
    link: haccTypography['hyundai-medium-text'],
    subNav: haccTypography['hyundai-regular-text'],
  },
  fontSize: {
    p: '20px',
    h1: '40px',
    h2: '24px',
    h3: '20px',
  },
  mobileFontSize: {
    p: '16px',
    h1: '25px',
    h2: '19px',
    h3: '16px',
  },
  figma: {
    colour: {
      base: {
        light: colours['hyundai-light-sand'],
        lightSecondary: colours['hyundai-sand'],
        dark: colours['hyundai-blue'],
        accent: colours['hyundai-red'],
        hoverOnLight: colours['hyundai-sky-blue'],
        hoverOnDark: colours['hyundai-sky-blue'],
      },
      contrast: {
        light: colours.black,
        dark: colours.white,
        accent: colours.white,
        hoverOnLight: colours.black,
        hoverOnDark: colours.black,
      },
    },
  },
};

const hyundaiMuiTheme: Theme = responsiveFontSizes(
  createTheme(adaptV4Theme({
    palette: {
      text: {
        // hint: config.colour.active,
        primary: colours.black,
        secondary: colours['hyundai-blue'],
      },
    },
  }))
);

export const haccTheme = {
  ...config,
  materialUI: hyundaiMuiTheme,
  tweaks: {
    mainLogoHeight: '35px',
    subLogoHeight: '25px',
    mobileLogoHeight: '25px',
    period_on_h1: '.',
    period_on_h2_css: `content: '.';`,
    blvd_header_logo_height: '30px',
    valid_oem_code: 'HAC',
    navArrow: `${css`
      transform: translate(0px, -2px);
    `}`,
    expandIconPosition: `${css`
      top: 55%;
    `}`,
    fontBaselineFix: `${css`
      height: 1.6em;
    `}`,
    fontBaseline: '1.3em',
  },
};
