import React from 'react';
import { Theme } from '@emotion/react';
import { SxProps, Box, Typography } from '@mui/material';
import { themes } from 'v5-theme';
import { CurrentOem } from 'utility';

const getColorFromGrade = (grade: number) =>
grade < 40
  ? {
      backgroundColor: themes[CurrentOem].palette.common.error,
      color: themes[CurrentOem].palette.common.white,
    }
  : grade < 50
  ? {
      backgroundColor: themes[CurrentOem].palette.common.warning,
      color: themes[CurrentOem].palette.common.black,
    }
  : grade < 60
  ? {
      backgroundColor: themes[CurrentOem].palette.common.caution,
      color: themes[CurrentOem].palette.common.black,
    }
  : {
      backgroundColor: themes[CurrentOem].palette.common.success,
      color: themes[CurrentOem].palette.common.black,
    };

export function Bubble({sxBase, bubbleText, colorByGrade}: {sxBase?: SxProps<Theme>, bubbleText: string, colorByGrade?: number}) {
  return <Typography
  sx={{
    ...getColorFromGrade(colorByGrade ?? 0),
    ...sxBase,
    borderRadius: '50%',
    margin: '0 auto',
    mb: 3,
    height: '80px',
    width: '80px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}
>
  {bubbleText}
</Typography>
}

export function BubbleCard(props: {
  bubbleText: string;
  label: string;
  subtext?: string;
  colorByGrade?: number;
  bubbleTheme?: SxProps<Theme>;
}) {
  const { bubbleText, bubbleTheme, label, subtext, colorByGrade } = props;


  const themeToUse = bubbleTheme ?? getColorFromGrade(colorByGrade ?? 0)
  return (
    <Box sx={{ my: 2, border: '1px 0 solid', py: 4 }}>
      <Bubble sxBase={themeToUse} bubbleText={bubbleText} />
      <Typography sx={{ mb: 2 }}>{label}</Typography>
      <Typography variant="caption">{subtext}</Typography>
    </Box>
  );
}
