import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import { ReactNode } from 'react';

interface StActionCardProps {
  title: string;
  subtitle: string;
  background: string;
  onClick: () => void;
  IconComponent: ReactNode;
}

export function StActionCard(props: StActionCardProps) {
  const { title, subtitle, IconComponent, background, onClick } = props;

  return (
    <Card
      aria-label={title}
      sx={{
        maxWidth: 300,
        border: '1px solid rgba(0,0,0,0.4)',
        ':hover': {
          transition: 'transform 0.2s',
          transform: 'scale(1.02)',
        },
      }}
      // raised
    >
      <CardActionArea onClick={onClick} sx={{ height: '100%' }}>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            width: '300px',
            height: '200px',
            backgroundColor: background,
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ width: '140px', height: '140px' }}
          >
            {IconComponent}
          </Stack>
        </Stack>
        <CardContent
          sx={{
            p: 6,
            height: '100%',
            backgroundColor: (theme) => theme.palette.background.default,
          }}
        >
          <Typography variant="h6" sx={{ textAlign: 'left', fontWeight: 'bold', mb: 1 }}>
            {title}
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'left' }}>
            {subtitle}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
