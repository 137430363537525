import { useEffect, useState } from 'react';
import { SearchMenuSection, useSearchHistory } from './model.Search';

export function useSearchHistoryModel({ searchTerm, noFilter }: { searchTerm: string; noFilter?: boolean;  }) {
  const { searchHistory } = useSearchHistory();
  const [searchHistoryState, setSearchHistoryState] = useState<SearchMenuSection | undefined>();

  const resetSearchHistoryState = () => {
    setSearchHistoryState(undefined);
  };

  const updateSearchHistoryState = () => {
    const searchHistoryOptions = searchHistory
      .filter((item) => item.keys.some((key) => noFilter ? true : key.includes(`${searchTerm}`)))
      .sort((a, b) => b.rank - a.rank)
      .map((item) => ({ label: item.result.label, value: item.result.value }))
      .slice(0, 10);

    const newSearchHistoryState = {
      optionType: { EN: 'History', FR: 'Historique' },
      items: searchHistoryOptions,
    };

    if (searchHistoryOptions === undefined || searchHistoryOptions.length === 0) {
      return resetSearchHistoryState();
    }
    setSearchHistoryState(newSearchHistoryState);
  };

  useEffect(() => {
    updateSearchHistoryState();
  }, [searchTerm]);

  return {
    searchHistoryState,
  };
}
