import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LabelValue } from 'suites/sterling/app/types';
import {
  StInputWrapper,
  StInputWrapperProps,
} from 'suites/sterling/atomics/inputs/InputWrapper';

export interface StSelectProps extends StInputWrapperProps {
  options: Array<LabelValue>;
  value?: string;
  setValue?: (newValue: string) => void;
  onChange?: (evt?: SelectChangeEvent) => void;
  width?: string;
}

export function StSelect(props: StSelectProps) {
  const { value, setValue, options, width, onChange, ...rest } = props;

  const handleChange = (event: SelectChangeEvent) => {
    setValue?.(event.target.value);
    if (onChange) onChange(event);
  };

  return (
    <StInputWrapper {...rest}>
      <Select
        variant="standard"
        disabled={rest.disabled}
        labelId={props.id}
        id={props.id}
        value={value ?? ''}
        onChange={handleChange}
        sx={{ width: width || '400px', whiteSpace: 'unset' }}>
        {options.map((op, index) => (
          <MenuItem key={`${op.label}-${index}`} value={op.value}>
            {op.label}
          </MenuItem>
        ))}
      </Select>
    </StInputWrapper>
  );
}
