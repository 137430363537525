import React from 'react';
import { OemTheme } from 'assets/styles/themes/functions';
import { AnalyticsConfig, Analytics } from 'components/util/AnalyticEvents';
import { withStyles } from '@mui/styles';
import { Switch } from '@mui/material';

export function Toggle(props: {
  checked: boolean;
  disabled?: boolean;
  onChange?: (...args: unknown[]) => void;
  onClick?: () => void;
  analytics_config?: AnalyticsConfig;
}) {
  const InternalToggle = withStyles({
    switchBase: {
      color: OemTheme.colour.default,
      opacity: 1,
      '& + $track': {
        'background-color': OemTheme.colour.background.primary,
        opacity: 1,
      },
      '&$checked': {
        color: OemTheme.colour.default,
        opacity: 1,
        '& + $track': {
          opacity: 1,
          'background-color': OemTheme.colour.background.toggleTrack,
        },
      },
    },
    checked: {},
    track: {},
  })(Switch);

  Analytics.Interaction({
    field: `Toggle Button`,
  },
  props.analytics_config);
  return <InternalToggle {...props} />;
}
