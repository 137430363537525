import React from 'react';
import styled from 'styled-components';
import { LanguageCode } from 'generated';
import { CurrentOem } from 'utility';

const Img = styled.img`
  width: 100px;
`;

export function ColourLegend(props: { lang: LanguageCode }) {
  return <p>
    <Img
      src={`/icons/dashboard-legends/LEGEND_${CurrentOem}_${props.lang}.png`}
      alt={props.lang === 'EN' ? `Legend` : `Légende`}
    />
  </p>
}
