import { ThemeOptions } from '@mui/material/styles';
import 'assets/font-loaders/hacc-fonts.css';
import { COMMON_COLORS, STERLING_COLOURS, withFallbacks } from './theme-sterling';

// This should not be exported to make sure the custom colours stay in scope of the theme
const COLOURS = {
  ...STERLING_COLOURS,
  hyundaiBlue: '#002860',
  activeBlue: '#007fa5',
  skyBlue: '#aacae6',
  lightBlue: '#aacae614', // added as a tempory color for button hover
  sand: '#e4dcd3',
  lightSand: '#f6f3f2',
  hyundaiRed: '#e63312',
} as const;

const haccThemeOptions: ThemeOptions = {
  palette: {
    common: {
      ...COMMON_COLORS,
    },
    primary: {
      main: COLOURS.hyundaiBlue,
      contrastText: COLOURS.white,
    },
    secondary: {
      main: COLOURS.skyBlue,
      contrastText: COLOURS.black,
    },
    error: {
      main: COLOURS.hyundaiRed,
      contrastText: COLOURS.white,
    },
    warning: {
      main: COLOURS.tba,
      contrastText: COLOURS.tba,
    },
    info: {
      main: COLOURS.activeBlue,
      contrastText: COLOURS.white,
      light: COLOURS.lightBlue,
    },
    success: {
      main: COLOURS.green,
      contrastText: COLOURS.tba,
    },
    background: {
      default: COLOURS.white,
      secondary: COLOURS.lightSand,
      paper: COLOURS.lightSand,
      darkPaper: COLOURS.sand,
      dark: COLOURS.hyundaiBlue, // Custom
      sterling: COLOURS.skyBlue,
    },
    action: {
      selected: COLOURS.activeBlue,
    },
    divider: COLOURS.black,
  },
  custom: {
    navLogo: {
      paddingTop: 105,
    },
  },
  typography: {
    fontFamily: withFallbacks('Hyundai Sans Text Regular'),
    h1: {
      //fontFamily: withFallbacks('Hyundai Sans Head Medium'),
      fontFamily: withFallbacks('Hyundai Bold Head'),
      fontWeight: '700 !important',
      fontSize: '40px !important',
      '::after': {
        content: '"."',
      },
    },
    h2: {
      fontFamily: withFallbacks('Hyundai Sans Head Medium'),
    },
    h3: {
      fontFamily: withFallbacks('Hyundai Sans Head Medium'),
    },
    h4: {
      fontFamily: withFallbacks('Hyundai Sans Head Medium'),
    },
    h5: {
      fontFamily: withFallbacks('Hyundai Sans Head Medium'),
    },
    h6: {
      fontFamily: withFallbacks('Hyundai Sans Head Medium'),
    },
    subtitle1: {
      fontFamily: withFallbacks('Hyundai Sans Head Medium'),
    },
    subtitle2: {
      fontFamily: withFallbacks('Hyundai Sans Head Regular'),
    },
    body1: {
      fontFamily: withFallbacks('Hyundai Sans Text Regular'),
    },
    body2: {
      fontFamily: withFallbacks('Hyundai Sans Text Regular'),
    },
    caption: {
      fontFamily: withFallbacks('Hyundai Sans Text Regular'),
    },
    overline: {
      fontFamily: withFallbacks('Hyundai Sans Text Regular'),
    },
    button: {
      fontFamily: withFallbacks('Hyundai Sans Head Regular'),
    },
    tableHeader: {
      fontWeight: 700,
      fontFamily: withFallbacks('Hyundai Sans Head Regular'),
    },
    tableBody: {
      fontFamily: withFallbacks('Hyundai Sans Head Regular'),
    },
  },
};

export default haccThemeOptions;
