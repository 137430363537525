import React, { useEffect } from 'react';
import clsx from 'clsx';
import { getPrintMode } from 'sterling-redux/reducers/activeSelections';
import { useSelector } from 'react-redux';
import { useLanguage } from 'components/util/Language';

export const bindAccordianEvents = () => {
  const accordians = window.document.querySelectorAll('[id^="collapse-link"]');
  accordians.forEach((accordian) => {
    // eslint-disable-next-line
    (accordian as HTMLElement).onclick = (event: MouseEvent) => {
      event.preventDefault();
      if (accordian.parentElement) {
        if (accordian.parentElement.classList.contains('expanded')) {
          accordian.parentElement.classList.remove('expanded');
        } else {
          accordian.parentElement.classList.add('expanded');
        }
      }
    };
  });
};

export function Accordian(props: { whyContent?: string }) {
  const { whyContent } = props;
  const lang = useLanguage();
  const printMode = getPrintMode(useSelector);

  useEffect(() => {
    bindAccordianEvents();
  }, []);

  if (!whyContent) return null;

  return (
    <div className="accordian-wrapper">
      <a
        id="collapse-link-s1q1"
        className={clsx('why-important-bar', printMode && 'no-plus-icon')}
        data-toggle="collapse"
        href="#importance-explaination-s1q1"
        role="button"
        aria-expanded={printMode ? 'true' : 'false'}
        aria-controls="importance-explaination-s1q1"
      >
        {{EN: `Why is this important?`, FR: `En quoi est-ce important?`}[lang]}
      </a>
      <div className={printMode ? 'expanded' : 'collapse'} id="importance-explaination">
        <div
          className="importance-explaination"
          dangerouslySetInnerHTML={{ __html: whyContent }}
        />
      </div>
    </div>
  );
}
