import { Button } from 'components';
import { StPageSection } from 'suites/sterling/molecules/sections/page/PageSection';
import { PageShell } from 'suites/sterling/molecules/shells/page/PageShell';
import { ComplianceReportContext, useComplianceReport } from './ComplianceSubmitReport.model';
import { ConfirmChecklist } from './subcomponents/confirm-checklist/ConfirmChecklist';
import { ReportForm } from './subcomponents/report-form/ReportForm';
import { ReportLanding } from './subcomponents/report-landing/ReportLanding';
import { ReportSubmitted } from './subcomponents/report-submitted/ReportSubmitted';
import { ReportReview } from './subcomponents/report-review/ReportReview';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { complianceAppContent as c } from 'suites/compliance/ComplianceApp.content';
import { ComplianceRestrictedRoute } from '../restricted/RestrictedRoute';

const enableSubmitReportProcess =
  process.env.REACT_APP_COMPLIANCE_ENABLE_SUBMIT_REPORT_PROCESS === 'true';

const pageSwitch = {
  REPORT_LANDING: <ReportLanding />,
  CONFIRM_CHECKLIST: enableSubmitReportProcess ? (
    <ConfirmChecklist />
  ) : (
    <ComplianceRestrictedRoute />
  ),
  REPORT_FORM: <ReportForm />,
  VERIFY_FORM: <ReportReview />,
  REPORT_SUBMITTED: <ReportSubmitted />,
};

export function ComplianceSubmitReport() {
  const complianceReport = useComplianceReport();
  const t = useTranslation();

  return (
    <PageShell variant="paper">
      <ComplianceReportContext.Provider value={complianceReport}>
        <>
          {complianceReport.reportState !== 'REPORT_LANDING' &&
            complianceReport.reportState !== 'REPORT_SUBMITTED' && (
              <StPageSection variant="paper" noBottomPadding sx={{ mb: 10 }}>
                <Button
                  variant="text"
                  startIcon={<ArrowBackIos />}
                  onClick={() => {
                    switch (complianceReport.reportState) {
                      case 'REPORT_FORM':
                        complianceReport.dispatchReportAction('SUBMIT_AN_INFRACTION');
                        break;
                      case 'VERIFY_FORM':
                        complianceReport.dispatchReportAction('CHECKLIST_CONFIRMED');
                        break;
                      default:
                        complianceReport.form.reset();
                        complianceReport.dispatchReportAction('BACK_TO_LANDING');
                    }
                  }}
                >
                  {t(c.terms.back[complianceReport.reportState])}
                </Button>
              </StPageSection>
            )}
          {pageSwitch[complianceReport.reportState]}
        </>
      </ComplianceReportContext.Provider>
    </PageShell>
  );
}
