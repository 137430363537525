import Typography from '@mui/material/Typography';
import { StPageSection } from 'suites/sterling/molecules/sections/page/PageSection';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import { Button } from 'components';
import Stack from '@mui/material/Stack';
import { useComplianceHistory } from 'suites/compliance/app/hooks/useComplianceHistory';
import { complianceAppContent as c } from 'suites/compliance/ComplianceApp.content';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import Box from '@mui/material/Box';
import InfractionVideoEN from 'assets/videos/compliance/HACC_Infractions_EN.webm';
import InfractionVideoFR from 'assets/videos/compliance/HACC_Infractions_FR.webm';
import { useScrollToTop } from 'suites/sterling/app/hooks/useScrollToTop';
import Link from '@mui/material/Link';
import { useLanguage } from 'suites/sterling/app/context/language';
import { ComplianceReportContext } from '../../ComplianceSubmitReport.model';
import { useContext } from 'react';
import { useComplianceParams } from 'suites/compliance/app/hooks/useComplianceParams';

export function ReportSubmitted() {
  const { newCaseId } = useContext(ComplianceReportContext);
  const { caseId } = useComplianceParams();
  const { push } = useComplianceHistory();
  const t = useTranslation();
  const lang = useLanguage();
  useScrollToTop();

  return (
    <StPageSection variant="paper">
      <Stack alignItems="flex-start" gap={5}>
        <Typography variant="h2">{t(c.pages.submitReport.submitted.title)}</Typography>

        <Typography variant="subtitle1">
          {t(c.pages.submitReport.submitted.caseNumber)}
          {caseId ?? newCaseId}
        </Typography>

        <Box sx={{ maxWidth: { xs: '95%', md: '70%' } }}>
          <Typography variant="body1">
            {t(c.pages.submitReport.submitted.subtitle)}
            <Link href={`mailto:${t(c.pages.support.email.address)}`}>
              {t(c.pages.submitReport.submitted.linkSupportLabel)}
            </Link>
          </Typography>
        </Box>

        <Button
          sx={{ px: 0, mb: 10 }}
          variant="text"
          endIcon={<ArrowForwardIos />}
          onClick={() => push('/review')}
        >
          {t(c.pages.submitReport.submitted.buttonSubmittedLabel)}
        </Button>

        <video controls loop width="100%" height="auto" style={{ display: 'block' }}>
          <source src={lang === 'EN' ? InfractionVideoEN : InfractionVideoFR} />
          <track default kind="captions" />
          Sorry your browser does not support embedded videos
        </video>
      </Stack>
    </StPageSection>
  );
}
