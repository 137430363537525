import { Grid, Typography, Stack, Box, Button } from '@mui/material';
import Cookies from 'js-cookie';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ContentSection } from 'suites/sterling/molecules/sections/content/ContentSection';
import { RowCard } from 'components/tiles/RowCard';
import { PageShell } from 'suites/sterling/molecules/shells/page/PageShell';
import { ErrorHandle, Loading } from 'components';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Adherence_Submissions } from 'generated';
import {differenceInDays} from 'date-fns';
import { KbdShortcut } from 'components/general/KbdShortcut';
import { Paginator } from 'components/tables/components/Paginator';
import { useResultPagination } from 'components/tables/components/Paginator/model.Paginator';
import { StTextInput } from 'suites/sterling/molecules/inputs/text/TextInput';

function AdherenceReviewRowCard(props: { review: Adherence_Submissions, selected: boolean }) {
  const reviewable = props.review;
  const history = useHistory();
  const getDayDiff = (created: Date) => differenceInDays(Date.now(), created);
  const amountOfMedia = reviewable.attachments?.length;
  const redirectToReview = () => history.push(`/adherence/review/id/${reviewable?.requestId}`)
  return (
    <RowCard mb={(theme) => theme.spacing(3)} key={reviewable.requestId}>
      <Stack component={Grid} container direction="row" alignItems="center" >
        <Box component={Grid} xs={2} sx={{ display: 'flex' }} item >
          <Typography variant="tableHeader" sx={{overflow: 'hidden', textOverflow: 'ellipsis', }}>
            ID: {reviewable?.invoiceNumber || reviewable.requestId}
          </Typography>
        </Box>
        <Box component={Grid} xs={2} item sx={{ display: 'flex' }} justifyContent="flex-start">
          <Typography variant="tableBody" textAlign='left'>
            {reviewable?.dealershipName}
          </Typography>
        </Box>
        <Box component={Grid} xs={1} sx={{ display: 'flex' }} item>
          <Typography variant="tableBody" align='left'>
            {reviewable?.mediaChannel}
          </Typography>
        </Box>
        <Box component={Grid} xs={3} sx={{ display: 'flex', paddingLeft: (theme) => theme.spacing(12) }} item>
          <Typography variant="tableBody">
            {reviewable?.campaign}
          </Typography>
        </Box>
        <Box component={Grid} xs={1} sx={{ display: 'flex' }} item alignItems="flex-start">
          <Typography variant="tableBody">
            Files: {amountOfMedia || 'N/A'}
          </Typography>
        </Box>
        <Box component={Grid} xs={1} item>
          <Typography variant="tableBody">
            Age: {getDayDiff(new Date(reviewable.createdAt))} days
          </Typography>
        </Box>
        <Box component={Grid} xs={2} item sx={{ display: 'flex' }} justifyContent="flex-end">
          {props.selected ? (
            <KbdShortcut char="d" onTap={redirectToReview}>
              <Button color="secondary" onClick={redirectToReview}>Review</Button>
            </KbdShortcut>
          ) :
            <Button color="secondary" onClick={redirectToReview}>Review</Button>
          }
        </Box>
      </Stack>
    </RowCard>
  )
}

export function AdherenceReviewList() {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [currentPageSize] = useState<number>(20);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [campaignFilter, setCampainFilter] = useState<any>();

  const REQUESTED_PAGE_INFO = gql`
    query getAdherenceRequestedPageInfo($pageSize: Int!, $filter: FilterFindManyAdherence_SubmissionsInput) {
      adherenceSubmissionPagination(perPage: $pageSize, sort: CREATEDAT_DESC, filter: $filter) {
        pageInfo {
          pageCount
        }
      }
    }
  `

  const REQUESTED_REVIEW_QUERY = gql`
    query getAdherenceRequestedReviews($limit: Int!, $offset: Int!, $filter: FilterFindManyAdherence_SubmissionsInput) {
      adherenceSubmissionMany(filter: $filter, sort: CREATEDAT_DESC, limit: $limit, skip: $offset) {
        _id
        requestId
        dealershipName
        createdAt
        updatedAt
        lastUpdated
        status
        invoiceNumber
        campaign
        mediaChannel
        attachments {
          filename
        }
      }
    }
  `;

  const DEBUG_SET_ALL_REVIEWS_REQUESTED = gql`
    mutation setAllReviewsRequested {
      DEBUG_markAllReviewsAsRequested
    }
  `;

  const handleQueryFilterUpdate = (value: string) => {
    Cookies.set('adherence-campaign-filter', value.trimStart().trimEnd())
    if (value.trim() === '') return setCampainFilter({});
    const campaigns = value.split(',');
    const filterArray = campaigns ? { OR: campaigns?.map((a) => ({ campaign: a.trimStart().trimEnd() })) } : {}
    setCampainFilter(filterArray);
  }
  const STATUS_FILTER = 'review-creative'
  const requestedPageInfo = useQuery(REQUESTED_PAGE_INFO, { variables: { filter: { ...campaignFilter, status: STATUS_FILTER, reviewed: null }, pageSize: currentPageSize } });
  const { offset, currentPage, setCurrentPage, updateOffset } = useResultPagination(
    currentPageSize,
  );

  const { loading, error, data, fetchMore } = useQuery(REQUESTED_REVIEW_QUERY, { variables: { filter: { ...campaignFilter, status: STATUS_FILTER, reviewed: null }, limit: currentPageSize, offset } });
  const [DEBUG_MarkAllRequestedMut] = useMutation(DEBUG_SET_ALL_REVIEWS_REQUESTED);
  const pageCount = requestedPageInfo.data?.adherenceSubmissionPagination?.pageInfo.pageCount || 1;

  // Scroll the page by one list item height on each selected index change
  useEffect(() => {
    const reviewListItemHeight = (103 * selectedIndex) + 1;
    window.scrollTo(0, reviewListItemHeight)
  }, [selectedIndex])

  // Scroll top and reset selectedIndex on page change
  useEffect(() => {
    setSelectedIndex(0);
    window.scrollTo(0, 0)
  }, [currentPage])

  useEffect(() => {
    updateOffset();
    fetchMore({ variables: { filter: { ...campaignFilter, status: STATUS_FILTER, reviewed: null }, limit: currentPageSize, offset } });
  }, [currentPage])

  useEffect(() => {
    if (!loading) {
      fetchMore({ variables: { filter: { ...campaignFilter, status: STATUS_FILTER, reviewed: null }, limit: currentPageSize, offset } });
    }
  }, [campaignFilter])

  useEffect(() => {
    if (!campaignFilter && Cookies.get('adherence-campaign-filter')) {
      handleQueryFilterUpdate(`${Cookies.get('adherence-campaign-filter')}`)
    }
  }, [])

  if (error) return <ErrorHandle error={JSON.stringify(error)} />

  return (
    <PageShell variant="paper">
      <ContentSection
        noBottomPadding
        variant="paper"
        title={
          <Stack direction="column" alignSelf="flex-start" gap={10}>
            <Typography variant="h3" sx={{ display: 'flex' }}>Submissions</Typography>
            <Typography variant="body2">Use W and S keys to move up and down. Use Q and E keys to navigate pages.</Typography>
          </Stack>
        }
      >
        <Stack sx={{ mb: (theme) => theme.spacing(4) }} direction="column" alignItems="flex-start">
          <StTextInput label="Active Campaigns" id="active-campaigns" setValue={handleQueryFilterUpdate} value={Cookies.get('adherence-campaign-filter')} />
        </Stack>
        {loading ? <Loading /> : [...(data?.adherenceSubmissionMany ?? [])]
          .map((review: Adherence_Submissions, idx: number) => (
            <Fragment key={review.requestId}>
              <KbdShortcut
                inline
                char={selectedIndex === idx - 1
                  ? 's'
                  : selectedIndex === idx + 1
                    ? 'w' : ''}
                onTap={() => setSelectedIndex(idx)}
              />
              <AdherenceReviewRowCard {...{ review, selected: selectedIndex === idx }} />
            </Fragment>
          ))
        }
        {!data?.adherenceSubmissionMany.length && <Typography align='left' variant='h5'>No submissions available for review.</Typography>}
        <KbdShortcut char='`' onTap={() => { DEBUG_MarkAllRequestedMut().then(() => window.location.reload()) }} />
        {pageCount > 1 &&
          <Stack direction="column" alignItems="center">
            <Box sx={{ width: (theme) => theme.spacing(100), paddingY: (theme) => theme.spacing(10) }}>
              <KbdShortcut char='q' onTap={() => { if (currentPage > 0) setCurrentPage(currentPage - 1) }} />
              <KbdShortcut char='e' onTap={() => { if (pageCount > currentPage + 1) setCurrentPage(currentPage + 1) }} />
              <Paginator currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={pageCount} />
            </Box>
          </Stack>
        }
      </ContentSection>
    </PageShell>
  );
}
