import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { LogoutButton } from 'suites/sterling/components/buttons/logout/LogoutButton';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { StLanguageToggle } from '../buttons/language/LanguageToggle';

interface StHeaderProps {
  handleDrawerToggle: () => void;
}

export function StHeader(props: StHeaderProps) {
  const { handleDrawerToggle } = props;

  return (
    <AppBar>
      <Toolbar sx={{ alignItems: 'center', justifyContent: 'flex-end', columnGap: 5 }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          sx={{
            display: { md: 'none' },
            // Places the hamburger icon at the far left while
            // everything else goes right
            marginRight: 'auto',

          }}
          size="large">
          <MenuIcon />
        </IconButton>
        <LogoutButton />
        <StLanguageToggle />
      </Toolbar>
    </AppBar>
  );
}
