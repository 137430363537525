import { LanguageCode } from 'suites/sterling/app/types';
import { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';

export const LanguageContext = createContext<LanguageCode>('EN');

// If this hook is used outside the scope of a LanguageProvider it will return the default "EN"
export const useLanguage = (): LanguageCode => {
  return useContext(LanguageContext);
};

// Matches /fr or /fr/...
// eslint-disable-next-line -- linter doesn't like regex with escape characters
export const isFrenchURL = /^\/fr(\/?|\z)/i;

export function URLLanguageProvider(props: { children: React.ReactNode }) {
  const { pathname } = useLocation();

  return (
    <LanguageContext.Provider value={isFrenchURL.test(pathname) ? 'FR' : 'EN'}>
      {props.children}
    </LanguageContext.Provider>
  );
}

export const LanguageConsumer = LanguageContext.Consumer;
