import React from 'react';
import { Typography, Card, Box, CardMedia, CardActionArea } from '@mui/material';
import { useHistory } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface CardImage {
  src: string;
  alt: string;
}

interface SummaryCardProps {
  header: string;
  subheader: string;
  summary: string;
  link_url?: string;
  image?: CardImage;
  small?: true;
}

function MainCardContent(props: {header: string, subheader: string, summary: string}) {
  const {header, subheader, summary} = props;
  return <>
      <Box sx={{ marginBottom: (theme) => theme.spacing(4) }}>
        <Typography variant="body1">{header}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: (theme) => theme.palette.text.secondary,
            mb: (theme) => theme.spacing(2),
          }}
        >
          {summary.length > 120 ? `${summary.slice(0, 120)}…` : summary}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            lineHeight: 2,
            color: (theme) => theme.palette.info.main,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {subheader}
          <ArrowForwardIcon sx={{ marginLeft: (theme) => theme.spacing(2) }} />
        </Typography>
      </Box>
    </>
}

export function SummaryCard(props: SummaryCardProps) {
  const { header, subheader, summary, image, link_url, small } = props;
  const history = useHistory();

  return (
    <Card
      elevation={0}
      variant="outlined"
      sx={{
        textAlign: 'left',
        background: (theme) => theme.palette.background.default,
        borderRadius: '4px',
        cursor: link_url ? 'pointer' : 'default',
        minWidth: small ? 'unset' : 500,
      }}
    >
      {image ? (
        <CardActionArea
          sx={{
            display: 'flex',
            flexDirection: small ? 'column' : 'row',
            justifyContent: 'flex-start',
          }}
          onClick={() => history.push(`${link_url}`)}
          title={link_url}
        >
          <Box sx={{ maxWidth: small ? 'auto' : 215 }}>
            <CardMedia image={image?.src} component="img" />
          </Box>
          <Box
            sx={{
              width: small ? 'auto' : '65%',
              padding: (theme) => theme.spacing(6),
              alignSelf: 'flex-start',
              height: '100%',
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
            }}
          >
            <MainCardContent {...{header, subheader, summary}} />
          </Box>
        </CardActionArea>
      ) : (
        <CardActionArea
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: small ? 'column' : 'row',
            padding: (theme) => theme.spacing(4),
            height: '100%',
          }}
          onClick={() => history.push(`${link_url}`)}
          title={link_url}
        >
          <MainCardContent {...{header, subheader, summary}} />
        </CardActionArea>
      )}
    </Card>
  );
}
