import { StrictComplianceCase } from 'suites/compliance/app/business-logic';
import { useComplianceParams } from 'suites/compliance/app/hooks/useComplianceParams';
import { useComplianceCasesManyQuery } from 'generated';
import { AllCasesPage } from './subpages/all-cases/AllCasesPage';
import { CaseDetailPage } from './subpages/case-detail/CaseDetail';
import { PageShell } from 'suites/sterling/molecules/shells/page/PageShell';

export function ComplianceReviewCases() {
  const { caseId } = useComplianceParams();
  const casesQuery = useComplianceCasesManyQuery();

  if (!casesQuery.data) {
    return null;
  }

  const CASES = casesQuery.data.complianceCasesMany as StrictComplianceCase[];

  const selectedCase = CASES.find((el) => el.caseId === caseId);
  const archivedCases = CASES.filter((el) => el.status === 'CLOSED');
  const activeCases = CASES.filter((aCase) => !archivedCases.includes(aCase));

  return (
    <PageShell variant="paper">
      {selectedCase ? (
        <CaseDetailPage selectedCase={selectedCase} allowCaseManagement />
      ) : (
        <AllCasesPage activeCases={activeCases} archivedCases={archivedCases} />
      )}
    </PageShell>
  );
}
