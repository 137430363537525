import { useLocation } from 'react-router-dom';
import { getUrlLanguage } from 'utility';
import { LanguageCode } from 'generated';
/* eslint-enable */

export const useLanguage = () => {
  const location = useLocation();
  return getUrlLanguage(location.pathname);
};

/**
 * Provides data based on language context. Three returns given overloads:
 * 1. No args - Return language code
 * 2. Language code - Returns bool for whether passed language code matches current
 * 3. Translation content - Returns appropriate content string for the current language
 * @param languageCode 'EN' | 'FR'
 * @param translationContent {EN: string, FR: string}
 */

function useLangUtil(): 'EN' | 'FR';
function useLangUtil(languageCode: 'EN' | 'FR'): boolean;
function useLangUtil(translationContent: LanguageCode): string;
function useLangUtil(
  languageCode?: 'EN' | 'FR',
  translationContent?: { EN: string; FR: string }
): boolean | 'EN' | 'FR' | string {
  const lang = useLanguage();

  if (languageCode && !translationContent) return languageCode === lang;
  if (!languageCode && translationContent) return translationContent[lang];
  return lang;
}

export { useLangUtil };
