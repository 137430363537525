import { ReactComponent as HaccLogo } from 'assets/images/logos/HACC.svg';
import { ReactComponent as MmscLogo } from 'assets/images/logos/MMSC.svg';
import { CurrentOem } from 'utility';

export interface OemLogoProps {
  width?: string;
  height?: string;
}

export function OemLogo(props: OemLogoProps) {
  const { width = '100%', height = 'auto' } = props;

  // https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/preserveAspectRatio
  return CurrentOem === 'HACC' ? (
    <HaccLogo preserveAspectRatio="xMidYMid meet" width={width} height={height} />
  ) : CurrentOem === 'MMSC' ? (
    <MmscLogo preserveAspectRatio="xMidYMid meet" width={width} height={height} />
  ) : (
    <>no logo found</>
  );
}
