/* eslint-disable no-shadow */
import { CSSProperties } from '@mui/styles/withStyles';
import { Theme, createTheme, ThemeOptions } from '@mui/material/styles';

import haccThemeOptions from './theme-hacc';
import mmscThemeOptions from './theme-mmsc';
import sterlingThemeOptions from './theme-sterling';
import { OemTheme } from 'assets/styles/themes/functions';

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      border: CSSProperties;
      drawer: CSSProperties;
      appBar: CSSProperties;
      navLogo: CSSProperties;
      buttonFontPositionFix: CSSProperties & { smallSize: CSSProperties };
      inputFontPositionFix: CSSProperties;
      inputLabel: CSSProperties;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {
      border?: CSSProperties;
      drawer?: CSSProperties;
      appBar?: CSSProperties;
      navLogo?: CSSProperties;
      buttonFontPositionFix?: CSSProperties & { smallSize?: CSSProperties };
      inputFontPositionFix?: CSSProperties;
      inputLabel?: CSSProperties;
    };
  }
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    dark: string;
    darkPaper: string;
    secondary: string;
    sterling: string;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    tableBody: React.CSSProperties;
    tableHeader: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    tableBody?: React.CSSProperties;
    tableHeader?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    tableBody: true;
    tableHeader: true;
  }
}

const componentOptionsFromTheme: (theme: Theme) => ThemeOptions = (theme: Theme) => ({
  components: {
    // Input
    MuiAutocomplete: {
      defaultProps: {},
      styleOverrides: {
        root: {},
        inputRoot: {
          paddingTop: 0,
          paddingBottom: 2.5,
        },
        listbox: {
          ...theme.custom.border,
          backgroundColor: theme.palette.common.white,
        },
      },

      variants: [],
    },

    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },

      styleOverrides: {
        root: {},
      },

      variants: [],
    },

    MuiButton: {
      // Composed of MuiPaper
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        variant: 'contained',
        color: 'secondary',
      },

      styleOverrides: {
        root: {
          textTransform: 'none',
          ...theme.custom.buttonFontPositionFix,
        },
        sizeLarge: {
          [theme.breakpoints.up('xs')]: {
            fontSize: '14px',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '16px',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '18px',
          },
        },
        sizeMedium: {
          [theme.breakpoints.up('xs')]: {
            fontSize: '12px',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '14px',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '16px',
          },
        },
        sizeSmall: {
          [theme.breakpoints.up('xs')]: {
            fontSize: '10px',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '12px',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '14px',
          },
          ...theme.custom.buttonFontPositionFix?.smallSize,
        },
        outlinedSizeSmall: {
          borderWidth: '2px',
          fontWeight: 'bold',
        },
        text: {
          padding: '1.25em 0.65em 1.5em 0.5em !important',
          color: theme.palette.primary.main,
          fontWeight: 'bold',
        },
        contained: {
          ...theme.custom.border,
          borderWidth: '3px',
          backgroundColor: theme.palette.primary.main,
          padding: theme.spacing(3, 6),
          fontSize: theme.typography.button.fontSize,
          '&:hover': {
            color: theme.palette.secondary.contrastText,
            borderColor: theme.palette.secondary.main,
            backgroundColor: theme.palette.secondary.main,
            textDecoration: 'none !important',
          },
        },
        containedSecondary: {
          color: theme.palette.primary.contrastText,
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main,
          paddingBottom: 12,
          paddingTop: 12,
          '&.on-dark': {
            // For light buttons border needs to be dark on a light background (default)
            // Add 'on-dark' class to get a light border
            borderColor: theme.palette.primary.contrastText,
          },
          '&:hover': {
            color: theme.palette.secondary.contrastText,
            borderColor: theme.palette.secondary.main,
            backgroundColor: theme.palette.secondary.main,
          },
        },
        textSizeMedium: {
          fontWeight: 700,
          borderRadius: '4px',
          textDecoration: 'none',
          '&:hover': {
            backgroundColor: theme.palette.info.light,
            textDecoration: 'none',
          },
        },
        textSizeSmall: {
          textDecoration: 'underline',
        },
      },

      variants: [],
    },

    MuiOutlinedInput: {
      defaultProps: {},
      variants: [],
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.default,
        },
        input: {
          padding: theme.spacing(2, 3),
        },
        notchedOutline: {
          ...theme.custom.border,
          border: 'solid black 2px',
          borderWidth: '2px',
        },
      },
    },
    typography: {
      tableBody: {
        [theme.breakpoints.up('xs')]: {
          fontSize: '10px',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '14px',
        },
      },
    },
    // Typography
    MuiTypography: {
      defaultProps: {},
      styleOverrides: {
        body1: {
          [theme.breakpoints.up('xs')]: {
            fontSize: '14px',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '16px',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '20px',
          },
        },
        body2: {
          [theme.breakpoints.up('xs')]: {
            fontSize: '12px',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '14px',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '14px',
          },
        },
        subtitle1: {
          [theme.breakpoints.up('xs')]: {
            fontSize: '12px',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '16px',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '20px',
          },
        },
        subtitle2: {
          [theme.breakpoints.up('xs')]: {
            fontSize: '14px',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '16px',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '20px',
          },
        },
        caption: {
          [theme.breakpoints.up('xs')]: {
            fontSize: '10px',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '10px',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '12px',
          },
        },
        overline: {
          [theme.breakpoints.up('xs')]: {
            fontSize: '10px',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '10px',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '12px',
          },
        },
        button: {
          [theme.breakpoints.up('xs')]: {
            fontSize: '12px',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '14px',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '16px',
          },
        },
        h1: {
          [theme.breakpoints.up('xs')]: {
            fontSize: '36px',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '40px',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '48px',
          },
        },
        h2: {
          [theme.breakpoints.up('xs')]: {
            fontSize: '32px',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '36px',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '44px',
          },
        },
        h3: {
          [theme.breakpoints.up('xs')]: {
            fontSize: '28px',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '32px',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '40px',
          },
        },
        h4: {
          [theme.breakpoints.up('xs')]: {
            fontSize: '24px',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '28px',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '34px',
          },
        },
        h5: {
          [theme.breakpoints.up('xs')]: {
            fontSize: '18px',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '20px',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '20px',
          },
        },
        h6: {
          [theme.breakpoints.up('xs')]: {
            fontSize: '14px',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '16px',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '20px',
          },
        },
        tableHeader: {
          [theme.breakpoints.up('xs')]: {
            fontSize: '10px',
            marginTop: 5,
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '12px',
            marginTop: 6,
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '14px',
            marginTop: 7,
          },
        },
        tableBody: {
          [theme.breakpoints.up('xs')]: {
            fontSize: '10px',
            marginTop: 7,
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '14px',
            marginTop: 7,
          },
        },
      },
    },
    MuiInputLabel: {
      variants: [],
      styleOverrides: {
        root: {
          fontSize: theme.custom.inputLabel.fontSize,
          color: theme.palette.common.black,
          ...theme.custom.inputLabel,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        inputProps: {
          sx: {
            ...theme.custom.inputFontPositionFix,
          },
        },
      },
    },
    MuiInputBase: {
      variants: [],
      styleOverrides: {},
    },
    MuiNativeSelect: {
      styleOverrides: {
        select: {
          ':focus': {
            background: theme.palette.background.default,
          },
          option: {
            backgroundColor: `${theme.palette.background.default} !important`,
          },
        },
      },
    },
    // Utils
    MuiPopover: {
      // Related to MuiMenu
      defaultProps: {},
      styleOverrides: {
        root: {},
        paper: {},
      },
      variants: [],
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
      },
      styleOverrides: {
        root: {},
      },
      variants: [],
    },
    // Data Display
    MuiList: {
      defaultProps: {},
      styleOverrides: {
        root: {},
        padding: { padding: 0 },
      },
      variants: [],
    },
    MuiListItem: {
      defaultProps: {},
      styleOverrides: {
        root: {},
      },
      variants: [],
    },
    // Table
    MuiTableBody: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('xs')]: {
            fontSize: '10px',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '14px',
          },
        },
      },
    },
    //Surfaces
    MuiAccordion: {
      defaultProps: {
        elevation: 1,
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.default,
          '&:before': {
            display: 'none',
          },
          // Sets the height of the accordion in both regular & expanded (not the children)
          '& .MuiAccordionSummary-root, .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: '48px',
          },
          // Margin must be removed along with height
          '& .MuiAccordionSummary-content,.MuiAccordionSummary-content.Mui-expanded': {
            margin: 0,
          },
          '& .MuiAccordionDetails-root': {
            padding: 0,
          },
        },
      },
      variants: [],
    },
    MuiAppBar: {
      defaultProps: {
        position: 'fixed',
        elevation: 1,
        color: 'default',
      },
      styleOverrides: {
        root: {
          height: theme.custom.appBar.height,
          // With taken from the AppBar example in Mui docs
          [theme.breakpoints.up('md')]: {
            width: `calc(100% - 340px)`,
          },
          backgroundColor: theme.palette.background.default,
        },
      },
      variants: [],
    },
    // Navigation
    MuiToolbar: {
      defaultProps: {},
      styleOverrides: {
        root: {
          height: '100%',
          borderShadow: 'none',
          borderBottom: 'solid 1px lightgrey',
        },
      },
    },
    MuiDrawer: {
      // Composed of MuiPaper
      defaultProps: {},
      styleOverrides: {
        root: {},
        // Nav is "paperAnchorLeft"
        width: theme.custom.drawer?.width,
        paperAnchorLeft: {
          position: 'relative',
          backgroundColor: OemTheme.colour.nav.background,
          borderRight: 'solid 1px lightgrey',
          height: '100vh',
        },
      },
      variants: [],
    },
    MuiMenu: {
      // Related to MuiPopover
      defaultProps: {},
      styleOverrides: {
        root: {},
        list: {
          ...theme.custom.border,
          backgroundColor: theme.palette.common.white,
          padding: 0,
        },
      },
      variants: [],
    },
    MuiMenuItem: {
      // Composed of MuiListItem
      defaultProps: {},
      styleOverrides: {
        root: {},
      },
      variants: [],
    },
    // Layout
    MuiContainer: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {},
      },
    },
  },
});

// STERLING Theme
const sterlingThemeNoComponents = createTheme(sterlingThemeOptions);

const sterlingTheme = createTheme(
  sterlingThemeNoComponents,
  componentOptionsFromTheme(sterlingThemeNoComponents)
);

// HACC Theme
const haccThemeNoComponents = createTheme(sterlingThemeOptions, haccThemeOptions);
const haccTheme = createTheme(
  haccThemeNoComponents,
  componentOptionsFromTheme(haccThemeNoComponents)
);

// MMSC Theme
const mmscThemeNoComponents = createTheme(sterlingThemeOptions, mmscThemeOptions);
const mmscTheme = createTheme(
  mmscThemeNoComponents,
  componentOptionsFromTheme(mmscThemeNoComponents)
);

const themes = {
  DEMO: sterlingTheme,
  HACC: haccTheme,
  MMSC: mmscTheme,
};

export default themes;
