import React from 'react';
import { gql } from '@apollo/client';
import { useRankedDealersQuery, SortOrder } from 'generated';
import { AppState } from 'sterling-redux/store';
import { useSelector } from 'react-redux';
import { Loading, ErrorHandle } from 'components';
import { percentageDisplay } from 'utility';
import { RankedList } from './templates/RankedList';

// eslint-disable-next-line
gql`
  query rankedDealers($assessmentCode: String!, $sortOrder: String!) {
    assessmentSummaryManyGroup(
      assessmentCode: $assessmentCode
      filterRole: true
      groupBy: "UNIT_CODE"
      sortBy: "FINAL_SCORE"
      sortOrder: $sortOrder
      pageNum: 0
      pageSize: 10
    ) {
      edges {
        review_details {
          unit_name
          region_code
        }
        final_score
      }
    }
  }
`;

export function RankedDealers(props: {
  sortOrder: SortOrder;
  title: string;
  showScore: boolean;
}) {
  const { title, sortOrder, showScore } = props;
  const { assessmentCode } = useSelector((state: AppState) => ({
    assessmentCode: state.activeSelections.assessmentCode,
  }));

  const rankedDealersQuery = useRankedDealersQuery({
    variables: {
      assessmentCode,
      sortOrder,
    },
  });

  if (rankedDealersQuery.loading) {
    return <Loading debug="Loading rankedDealersQuery" />;
  }
  if (rankedDealersQuery.error) {
    return <ErrorHandle gqlError={rankedDealersQuery.error} />;
  }

  return (
    <RankedList
      title={title}
      list={rankedDealersQuery.data?.assessmentSummaryManyGroup.edges.map((summary) => ({
        formatted_unit_name: showScore
          ? `${summary.review_details.unit_name} (${percentageDisplay(summary.final_score)})`
          : summary.review_details.unit_name,
      }))}
      fieldName="formatted_unit_name"
      numToDisplay={10}
    />
  );
}
