import Stack from '@mui/material/Stack';
import { Thumbnailer } from './Thumbnailer';
import DownloadIcon from '@mui/icons-material/Download';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { ComplianceEvidenceGetResponse } from 'suites/compliance/app/business-logic';
import { ReactNode } from 'react';

interface WithFiles {
  data: File[];
}

interface WithLinks {
  data: ComplianceEvidenceGetResponse[];
}

export function Galleryer({
  filesOrLinks,
  justifyContent,
  thumbnailHandler,
}: {
  filesOrLinks: WithFiles | WithLinks;
  justifyContent: 'flex-start' | 'center';
  thumbnailHandler?: (f: File) => void;
}) {
  const allowDownload = 'tempLink' in filesOrLinks;
  const { data } = filesOrLinks;

  return (
    <Grid
      container
      direction="row"
      gap={2}
      justifyContent={justifyContent}
      alignItems="center"
      wrap="wrap"
    >
      {data.map((file, idx) => {
        const key = idx + file.name;

        if (file.type.includes('image')) {
          return (
            <Grid item key={key} sx={{ height: '100px' }}>
              {'tempLink' in file ? (
                <a href={file.tempLink} download={file.name} rel="noopener">
                  <img src={file.thumnailTempLink} alt={file.name} height="100" width="auto" />
                </a>
              ) : (
                <Thumbnailer
                  key={`${idx}-${file.name}`}
                  thumbnailHandler={thumbnailHandler}
                  file={file}
                />
              )}
            </Grid>
          );
        }

        const isDownload = 'tempLink' in file;

        const withOptionalDownload = (children: ReactNode) => {
          return isDownload ? (
            <a
              href={isDownload ? file.tempLink : undefined}
              download={isDownload ? file.name : undefined}
              rel="noopener"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {children}
            </a>
          ) : (
            <>{children}</>
          );
        };

        return withOptionalDownload(
          <Grid item key={key} sx={{ height: '100px' }}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              gap={2}
              sx={{
                width: '150px',
                height: '100px',
                border: '1px dashed black',
                textAlign: 'center',
                '&:hover': allowDownload
                  ? {
                      opacity: 0.9,
                      cursor: 'pointer',
                    }
                  : undefined,
              }}
            >
              {isDownload ? <DownloadIcon color="primary" /> : <UploadFileIcon />}
              <Typography variant="caption">{file.name}</Typography>
            </Stack>
          </Grid>
        );
      })}
    </Grid>
  );
}
