import Stack from '@mui/material/Stack';
import {
  casesSortedAscendingById,
} from 'suites/compliance/app/business-logic';
import { CaseSummaryTile, CaseSummaryTileProps } from '../CaseSummaryTile';

export interface CaseSummaryTileGroupProps {
  cases: CaseSummaryTileProps[];
  hideAssigneeChip?: boolean;
}

export function CaseSummaryTileGroup({
  cases,
  hideAssigneeChip,
}: CaseSummaryTileGroupProps) {
  return (
    <Stack direction="column" spacing={3} width="100%">
      {casesSortedAscendingById(cases).map((infCase) => {
        return (
          <CaseSummaryTile
            key={infCase.caseId}
            {...infCase}
            hideAssigneeChip={hideAssigneeChip}
          />
        );
      })}
    </Stack>
  );
}
