import { EnumUsersCoe_Role } from 'generated';
import React, { createContext, useContext, useState } from 'react';
import { useAuthorization } from 'suites/sterling/app/context/authorization';

export type AdherenceRole = 'REVIEWER' | 'DEVELOPER' | 'OTHER';

interface AdherenceContext {
  role: EnumUsersCoe_Role | AdherenceRole | undefined;
  setRole: (arg: AdherenceRole | EnumUsersCoe_Role) => void;
}

export const AdherenceRoleContext = createContext<AdherenceContext>({
  role: undefined,
  setRole: () => undefined,
});

export const canViewAdminPanel = (
  role: AdherenceRole | EnumUsersCoe_Role | undefined
): boolean => {
  switch (role) {
    case 'REVIEWER':
    case 'DEVELOPER':
      return true;
  }
  return false;
};

// If this hook is used outside the scope of a AdherenceRoleProvider it will return the default
export const useAdherenceRole = (): AdherenceContext => {
  return useContext(AdherenceRoleContext);
};

export function AdherenceRoleProvider(props: { children: React.ReactNode }) {
  const { token } = useAuthorization();
  const [role, setRole] = useState<EnumUsersCoe_Role | AdherenceRole | undefined>(
    token?.coe_role
  );

  const saferSetRole = (safe_role: AdherenceContext['role']) => {
    if (process.env.REACT_APP_DEBUG_MODE === 'true') {
      setRole(safe_role);
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AdherenceRoleContext.Provider value={{ role, setRole: saferSetRole }}>
      {props.children}
    </AdherenceRoleContext.Provider>
  );
}

export const AdherenceRoleContextConsumer = AdherenceRoleContext.Consumer;
