import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CaseCard } from 'suites/compliance/components/cards/case/CaseCard';
import { CaseStatusChip } from 'suites/compliance/components/chips/case-status/CaseStatusChip';
import { Compliance_Cases } from 'generated';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { complianceAppContent as c } from 'suites/compliance/ComplianceApp.content';

export function CaseHistoryTile({
  dealerNotes,
  internalNotes,
  internalNotesName,
  modifiedDate,
  status,
}: Compliance_Cases['history'][number]) {
  const t = useTranslation();

  const hasNotes = dealerNotes || internalNotes;

  return (
    <CaseCard
      title={
        <Typography variant="body2" sx={
            {
              marginTop: hasNotes ? 2 : 1,
              paddingBottom: hasNotes ? 5 : 0,
            }
          }>
          <span style={{ fontWeight: 'bold' }}>Date:</span> {modifiedDate}
        </Typography>
      }
      titleOpposite={<CaseStatusChip status={status} />}
      noDivider={!hasNotes}
    >
      {hasNotes && (
        <Stack direction="column" spacing={2} >
          {dealerNotes && (
            <>
              <Typography variant="tableHeader">{t(c.terms.dealerNotes)}</Typography>
              <Box
                sx={{
                  p: 3,
                  marginRight: 5,
                  backgroundColor: 'background.paper',
                  opacity: 0.6,
                }}
              >
                <Typography variant="body2">{dealerNotes}</Typography>
              </Box>
            </>
          )}

          {internalNotes && (
            <>
              <Typography variant="tableHeader" sx={{opacity: 0.6}}>{t(c.terms.internalNotes)}</Typography>
              <Box
                sx={{
                  p: 3,
                  marginRight: 5,
                  backgroundColor: 'background.paper',
                  opacity: 0.6,
                }}
              >
                <Typography variant="body2">{internalNotes}</Typography>
              </Box>
              {internalNotesName && (
                 <Typography variant="body2">{internalNotesName}</Typography>
              )}
            </>
          )}
        </Stack>
      )}
    </CaseCard>
  );
}
