/* eslint-disable @typescript-eslint/no-explicit-any */
export const Download = (props: {
  url: string;
  filename?: string;
  type?: string;
  mimeType?: string;
  onStart?: () => void;
  onFinish?: () => void;
  onError?: () => void;
}) => {
  if (props.onStart) props.onStart();

  const timeout = (ms: number, promise: Promise<Response>) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error('timeout'));
      }, ms);
      promise.then(resolve, reject);
    });
  };

  timeout(30000, fetch(props.url, { credentials: 'include' }))
    .then(async (response) => {
      // Can't figure out the typing on this one
      // eslint-disable-next-line
      const blob = await (response as any).blob?.();
      if (props.onFinish) props.onFinish();
      if (!blob) return;
      const url = window.URL.createObjectURL(blob);
      const filenameFromUrl = props.url.match(/(\w|\d|-)+\.(zip|pdf)$/) || ['file'];
      const filename = props.filename || filenameFromUrl[0];
      const fileExtensionFromUrl = props.url.match(/\.(\w|\d)+$/) || ['file'];

      const processedBlob = new Blob([blob], {
        type: props.mimeType || `application/${props.type || fileExtensionFromUrl[0]}`,
      });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      const ie11Navigator = window.navigator as typeof window.navigator & {
        msSaveOrOpenBlob: (_arg: unknown) => void;
      };
      if (ie11Navigator?.msSaveOrOpenBlob) {
        ie11Navigator.msSaveOrOpenBlob(processedBlob);
        return;
      }

      const link = (document as any).createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    })

    .catch(props.onError);
};
