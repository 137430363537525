import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import React, { Fragment, useEffect } from 'react';
import ListItemText from '@mui/material/ListItemText';
import { NavLink, useLocation } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import { LanguageLabel, Translation } from 'suites/sterling/app/types';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { OemLogo } from '../logos/oem/OemLogo';
import styled from '@mui/system/styled';
import { StHeader } from '../headers/Header';
import Box from '@mui/material/Box';
import { Collapse, ListItemButton, ListItemButtonProps } from '@mui/material';
import { useLanguage } from 'components/util/Language';

const LogoWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  height: '180px',
});

export interface MenuItem {
  label: Translation | LanguageLabel;
  to: string;
  icon?: React.ReactNode;
  children?: MenuItem[];
  order?: number;
}

export interface StDrawerProps {
  options: MenuItem[];
}

const drawerWidth = 340;

function NavItem(
  props: {
    opt: MenuItem;
    menuIndex: number;
    nested?: number;
    setMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
  } & ListItemButtonProps
) {
  const { opt, menuIndex, setMobileOpen, nested = 0 } = props;
  const t = useTranslation();
  const location = useLocation();
  const highlighted = location.pathname.includes(opt.to);

  const [open, setOpen] = React.useState(highlighted);

  useEffect(() => {
    if (highlighted) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [location.pathname, highlighted]);

  return (
    <Fragment key={`${opt.label}${menuIndex}`}>
      {opt.to ? (
        <ListItemButton
          key={`${opt.label}${menuIndex}-item`}
          component={NavLink}
          to={`${opt.to}`}
          exact
          activeClassName="active-menu-item"
          onClick={() => setMobileOpen(false)}
          sx={nested ? { pl: (6 * (nested + 1)), mb: 0, py: 1 } : {}}
        >
          {opt.icon && <ListItemIcon>{opt.icon}</ListItemIcon>}
          <ListItemText primaryTypographyProps={{ variant: 'body1' }} primary={t(opt.label)} />
        </ListItemButton>
      ) : (
        <ListItemButton
          key={`${opt.label}${menuIndex}item`}
          onClick={() => setMobileOpen(false)}
          sx={{ cursor: 'default' }}
        >
          {opt.icon && <ListItemIcon>{opt.icon}</ListItemIcon>}
          <ListItemText primaryTypographyProps={{ variant: 'body1' }} primary={t(opt.label)} />
        </ListItemButton>
      )}
      {opt.children && (
        <List
          sx={{
            paddingBottom: 0,
            '.MuiListItem-root': {
              marginLeft: (theme) => theme.spacing(4),
            },
            '.active-menu-item': {
              backgroundColor: 'secondary.main',
              color: 'secondary.contrastText',
              '& .MuiListItemText-primary': {
                fontWeight: 'bold',
              },
            },
          }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {opt.children.map((childOpt, childMenuIndex) => (
              <NavItem
                {...{
                  key: childOpt.to,
                  opt: childOpt,
                  menuIndex: childMenuIndex,
                  setMobileOpen,
                  nested: nested + 1,
                }}
              />
            ))}
          </Collapse>
        </List>
      )}
    </Fragment>
  );
}

export function StDrawer(props: StDrawerProps) {
  const { options } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const lang = useLanguage();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const forcedNavOrder = [
    /\/about-the-program/g,
    /\/brand-components/g,
    /\/media-types/g,
    /\/review/g,
    /\/support/g,
  ];

  const mapForcedOrder = (route: MenuItem) => {
    let sortedRoute: boolean | MenuItem = false;
    forcedNavOrder.forEach((routeName, newOrder) => {
      if (routeName.test(route.to)) {
        sortedRoute = { ...route, order: newOrder };
      }
    });
    return sortedRoute || route;
  };

  const drawer = (
    <nav>
      <a aria-label='Company label' href={`${{ EN: '', FR: '/fr' }[lang]}/adherence/`}>
        <LogoWrapper>
          <OemLogo width="75%" />
        </LogoWrapper>
      </a>
      {/* <Divider /> */}
      <List
        sx={{
          '.active-menu-item': {
            backgroundColor: 'secondary.main',
            color: 'secondary.contrastText',
            '& .MuiListItemText-primary': {
              fontWeight: 'bold',
            },
          },
        }}
      >
        {options
          .map(mapForcedOrder)
          .sort((a, b) => (a?.order || 0) - (b?.order || 0))
          .map((opt, menuIndex) => (
            <NavItem {...{ key: opt?.to, opt, menuIndex, setMobileOpen }} />
          ))}
      </List>
    </nav>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <StHeader handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            position: 'fixed',
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
