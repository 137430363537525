import { css } from 'styled-components';
import { OemTheme } from 'assets/styles/themes/functions';

export const accordianStyles = css`
  .accordian-wrapper {
    color: #272223;
    padding: 5px 15px;
    position: relative;
    font-size: 16px;
    background-color: ${OemTheme.colour.background.secondary} !important;

    .why-important-bar {
      display: block;
      color: #000 !important;
      text-decoration: none !important;
      font-weight: 500;
      margin: 2px 0;
      padding: 8px 0 7px 23px;
      position: relative;

      &::before {
        display: block;
        content: '+';
        font-size: 36px;
        margin-right: 16px;
        transform: translateY(-50%);
        transition: transform 0.2s linear;
        position: absolute;
        left: -7px;
        ${OemTheme.tweaks(
          'expandIconPosition',
          `${css`
            top: 50%;
          `}`
        )}
      }

      &.no-plus-icon {
        &::before {
          content: '';
        }
      }
    }

    /* importance-explaination comes from wordpress and styles depend on it, so dont change it, even though it's a spelling error */

    .importance-explaination {
      p {
        font-size: 16px;
      }
    }

    .collapse {
      transition: max-height 0.5s ease-out;
      max-height: 0px;
      overflow: hidden;

      .importance-explaination {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }

    &.expanded {
      .why-important-bar {
        &::before {
          transform: translateY(-50%) rotate(-45deg);
        }
      }

      .collapse {
        max-height: 1000px;
      }
    }
  }

  .content.guide .accordian-wrapper {
    margin-top: 2rem;
  }
`;
