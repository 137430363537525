import { ComplianceRole } from 'generated';
import { createContext, useContext, useState } from 'react';
import { useAuthorization } from 'suites/sterling/app/context/authorization';

interface ComplianceContext {
  role: ComplianceRole | undefined;
  setRole: (arg: ComplianceRole) => void;
}

export const ComplianceRoleContext = createContext<ComplianceContext>({
  role: undefined,
  setRole: () => undefined,
});

// If this hook is used outside the scope of a ComplianceRoleProvider it will return the default
export const useComplianceRole = (): ComplianceContext => {
  return useContext(ComplianceRoleContext);
};

export function ComplianceRoleProvider(props: { children: React.ReactNode }) {
  const { token } = useAuthorization();
  const [role, setRole] = useState<ComplianceRole | undefined>(token?.compliance_role);

  const saferSetRole = (_role: ComplianceRole) => {
    if (process.env.REACT_APP_DEBUG_MODE === 'true') {
      setRole(_role);
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ComplianceRoleContext.Provider value={{ role, setRole: saferSetRole }}>
      {props.children}
    </ComplianceRoleContext.Provider>
  );
}

export const ComplianceRoleContextConsumer = ComplianceRoleContext.Consumer;
