import { StPageSection } from 'suites/sterling/molecules/sections/page/PageSection';
import { TitleSection } from 'suites/sterling/molecules/sections/title/TitleSection';
import { CaseSummaryTileGroup } from 'suites/compliance/components/tiles/case-summary/group/CaseSummaryTileGroup';
import { complianceAppContent as c } from 'suites/compliance/ComplianceApp.content';
import { NoCasesCard } from '../../subcomponents/NoCasesCard';
import { StrictComplianceCase } from 'suites/compliance/app/business-logic';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { useComplianceRole } from 'suites/compliance/app/context/compliance-role';
import Typography from '@mui/material/Typography';

export interface AllCasesPageProps {
  activeCases: StrictComplianceCase[];
  archivedCases: StrictComplianceCase[];
}

export function AllCasesPage(props: AllCasesPageProps) {
  const { activeCases, archivedCases } = props;
  const { role } = useComplianceRole();

  const t = useTranslation();

  return (
    <>
      <TitleSection
        variant="paper"
        title={t(c.pages.reviewCases.landing.allCases.title)}
        noBottomPadding
      />
      <StPageSection variant="paper" noTopPadding>
        <Typography variant="subtitle1" sx={{ paddingBottom: 4, paddingTop: 3 }}>
          {t(c.pages.reviewCases.landing.allCases.submitted)}
        </Typography>
        <>
          {activeCases.length === 0 ? (
            <NoCasesCard />
          ) : (
            <CaseSummaryTileGroup cases={activeCases} hideAssigneeChip />
          )}
        </>
      </StPageSection>

      {(role === 'DEALER' || role === 'ZONE_MANAGER') && (
        <>
          <TitleSection
            variant="paper"
            subtitle={t(c.pages.reviewCases.landing.allCases.archived)}
            noTopPadding
            sx={{ paddingBottom: 4 }}
          />

          <StPageSection variant="paper" noTopPadding>
            {archivedCases.length === 0 ? (
              <NoCasesCard />
            ) : (
              <CaseSummaryTileGroup cases={archivedCases} hideAssigneeChip />
            )}
          </StPageSection>
        </>
      )}
    </>
  );
}
