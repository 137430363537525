import { UnknownAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { AppState } from 'sterling-redux/store';

export type GroupTypes = string;

export interface ActiveSelectionsState{
  unitCode: string;
  assessmentCode: string;
  categoryCode: string;
  regionCode: string;
  districtCode: string;
  groupType: GroupTypes;
  group: string;
  demoMode: boolean;
  error: string;
  printMode: boolean;
}

export interface ActiveSelectionsAction extends UnknownAction {
  type: string;
  payload: Partial<ActiveSelectionsState>;
}
export const SET_UNIT_CODE = 'SET_UNIT_CODE';
export const SET_ASSESSMENT_CODE = 'SET_ASSESSMENT_CODE';
export const SET_CATEGORY_CODE = 'SET_CATEGORY_CODE';
export const SET_REGION_CODE = 'SET_REGION_CODE';
export const SET_DISTRICT_CODE = 'SET_DISTRICT_CODE';
const SET_GROUP_TYPE = 'SET_GROUP_TYPE';
const SET_GROUP = 'SET_GROUP';
const SET_DEMO_MODE = 'SET_DEMO_MODE';
export const SET_ERROR = 'SET_ERROR';
export const SET_PRINT_MODE = 'SET_PRINT_MODE';
export const RESET_ALL = 'RESET_ALL';

type SetSelected = (arg: string) => ActiveSelectionsAction;
type SetGroupType = (arg: GroupTypes) => ActiveSelectionsAction;
type SetBoolean = (arg: boolean) => ActiveSelectionsAction;

export const setUnitCode: SetSelected = (value) => ({
  type: SET_UNIT_CODE,
  payload: {
    unitCode: value,
  },
});

export const setAssessmentCode: SetSelected = (value) => ({
  type: SET_ASSESSMENT_CODE,
  payload: {
    assessmentCode: value,
  },
});

export const setCategoryCode: SetSelected = (value) => ({
  type: SET_CATEGORY_CODE,
  payload: {
    categoryCode: value,
  },
});

export const setRegionCode: SetSelected = (value) => ({
  type: SET_REGION_CODE,
  payload: {
    regionCode: value,
  },
});

export const setDistrictCode: SetSelected = (value) => ({
  type: SET_DISTRICT_CODE,
  payload: {
    districtCode: value,
  },
});

export const setGroupType: SetGroupType = (value) => ({
  type: SET_GROUP_TYPE,
  payload: {
    groupType: value,
  },
});

export const setGroup: SetSelected = (value) => ({
  type: SET_GROUP,
  payload: {
    group: value,
  },
});

export const setDemoMode: SetBoolean = (value) => ({
  type: SET_DEMO_MODE,
  payload: {
    demoMode: value,
  },
});

export const setError: SetSelected = (value) => ({
  type: SET_ERROR,
  payload: {
    error: value,
  },
});

export const setPrintMode: SetBoolean = (value) => ({
  type: SET_PRINT_MODE,
  payload: {
    printMode: value,
  },
});

// REDUCER

const initialState: ActiveSelectionsState = {
  unitCode: '',
  assessmentCode: '',
  categoryCode: '',
  regionCode: '',
  districtCode: '',
  groupType: 'all',
  group: '',
  demoMode: false,
  error: '',
  printMode: false,
};

// eslint-disable-next-line
export default function (state = initialState, action: Partial<ActiveSelectionsAction>) {
  switch (action.type) {
    case RESET_ALL: {
      return initialState;
    }
    case SET_UNIT_CODE: {
      return { ...state, ...action.payload };
    }
    case SET_ASSESSMENT_CODE: {
      return { ...state, ...action.payload };
    }
    case SET_CATEGORY_CODE: {
      return { ...state, ...action.payload };
    }
    case SET_REGION_CODE: {
      return { ...state, ...action.payload };
    }
    case SET_DISTRICT_CODE: {
      return { ...state, ...action.payload };
    }
    case SET_GROUP_TYPE: {
      return { ...state, ...action.payload };
    }
    case SET_GROUP: {
      return { ...state, ...action.payload };
    }
    case SET_DEMO_MODE: {
      return { ...state, ...action.payload };
    }
    case SET_ERROR: {
      return { ...state, ...action.payload };
    }
    case SET_PRINT_MODE: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}

export const getPrintMode = (sel: typeof useSelector) =>
  sel((state: AppState) => state.activeSelections.printMode);
