import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

interface StSwitchProps {
  enabled: boolean;
  toggle: () => void;
  label?: string;
}

export function StSwitch({ enabled, toggle, label = "" }: StSwitchProps) {
  return (
    <FormGroup row>
      <FormControlLabel
        control={<Switch checked={enabled} onChange={toggle} color="primary" />}
        label={label}
        sx={{'span': { fontSize: (theme)=> theme.typography.subtitle2.fontSize}}}
      />
    </FormGroup>
  );
}
