/* eslint-disable @typescript-eslint/no-unused-vars */
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { StSelect } from '../../inputs/select/Select';
import { Button } from 'components';
import { Box, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { CaseCard } from 'suites/compliance/components/cards/case/CaseCard';

export interface FilterChildCollectionProps {
  children?: React.ReactNode & {
    props: { listData: { [key: string]: string }[] };
  };
  filters: {
    field: string;
    label: string;
    placeholder?: string;
    width?: string | undefined;
    onChange?: (evt?: SelectChangeEvent) => void;
  }[];
}

export const CollectionContext = React.createContext<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filteredCollection: any[];
}>({
  filteredCollection: [],
});

/**
 * @param { FilterChildCollectionProps } props
 * @description `filters` array takes filter objects of `{field: string, label: string, placeholder: string }`, where `label` & `placeholder`
 * are passed to the input and `field` is the prop name you want to filter by in the child collection. The child node for this component
 * will need to have a `listContent` prop that contains the collection to filter as well as a Consumer from `CollectionContext` that
 * the child renders from.
 */
export function FilterChildCollection(props: FilterChildCollectionProps) {
  const { children, filters } = props;
  // Create a filter state object out of the field name props passed with the config array
  const initialFilters = Object.assign(
    {},
    ...filters.map((filter) => ({ [filter.field]: '' }))
  );

  const t = useTranslation();
  const [filterState, setFilterState] = useState(initialFilters);

  const resetFilters = () => setFilterState(initialFilters);

  // 'collection' is the live state of the passed listContent after filtering
  const [collection, setCollection] = useState(children?.props.listContent);
  const updateFilter = (val: string, field: string) => {
    setFilterState({ ...filterState, ...{ [field]: val } });
  };

  useEffect(() => {
    // Get a filter state entry array with only populated props
    const currentFilterArray = Object.entries(filterState).filter(([_, value]) => !!value);
    const isFilterActive = currentFilterArray.length;
    const currentFilters = Object.fromEntries(currentFilterArray);
    setCollection(children?.props.listContent);

    if (isFilterActive) {
      const filteredCollection = children?.props.listContent.filter(
        (collectionItem: (typeof collection)[number]) => {
          // return true if no collection item properties match a filter value
          return !Object.entries(collectionItem).some(
            ([key, value]) => currentFilters[key] && value !== currentFilters[key]
          );
        }
      );
      setCollection(filteredCollection);
    }
  }, [children?.props.listContent, filterState]);

  const getUniqueFieldValues = (field: string) =>
    Array.from(
      new Set((collection as { [field: string]: string }[])?.map((option) => option[field]))
    );

  return (
    <Grid container direction="row" wrap="wrap">
      <CaseCard sx={{ marginBottom: (theme) => theme.spacing(3) }}>
        <Box flexGrow={1}>
          <Grid container item direction="row" spacing={3} xs={12} alignItems="center">
            {filters.map((filterItem, i) => (
              <Grid item flex={i + 1 === filters.length ? 1 : 0} key={i}>
                <StSelect
                  id={`${filterItem.field}-filter`}
                  label={filterItem.label}
                  options={getUniqueFieldValues(filterItem.field).map((val) => ({
                    label: val,
                    value: val,
                  }))}
                  onChange={filterItem.onChange}
                  width={filterItem.width ?? '200px'}
                  value={filterState[filterItem.field]}
                  setValue={(val) => updateFilter(val, filterItem.field)}
                />
              </Grid>
            ))}
            <Grid item xs={2}>
              <Button variant="text" onClick={resetFilters}>
                {t({ EN: 'Clear Filters', FR: 'Effacer' })}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CaseCard>
      <Grid item container xs={12}>
        {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
        <CollectionContext.Provider value={{ filteredCollection: collection }}>
          {children}
        </CollectionContext.Provider>
      </Grid>
    </Grid>
  );
}
