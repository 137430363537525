import { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Adherence_Submissions } from 'generated';

export const useSubmissionImage = (reviewId: string) => {
  const [submissionImage, setSubmissionImage] = useState('https://imgur.com/IdaBrS8.png');
  const [submissionMedia, setSubmissionMedia] = useState<
    Adherence_Submissions['attachments'][number]
  >({
    _id: '',
    url: '',
    id: '',
    filename: '',
    type: '',
    filesize: 0,
    preview: '',
  });

  const [submissionMediaIndex, setSubmissionImageIndex] = useState<number>(0);
  const [imageRotationState, setImageRotationState] = useState<number>(0);

  const ADHERENCE_SUBMISSION_QUERY = gql`
    query getAdherenceSubmissionById($requestId: String!) {
      adherenceSubmissionOne(filter: { requestId: $requestId }) {
        _id
        requestId
        mediaChannel
        audience
        carModel
        attachments {
          id
          filename
          type
          filesize
          preview
        }
      }
    }
  `;

  const adherenceSubmissionQuery = useQuery(ADHERENCE_SUBMISSION_QUERY, {
    skip: !reviewId,
    variables: { requestId: reviewId },
  });

  const rotateImage = () => {
    // only increment if media is image
    if (/image/g.test(submissionMedia.type))
      setImageRotationState((imageRotationState + 1) % 4);
  };

  const skipCurrentContentItem = () => {
    if (
      submissionMediaIndex + 1 <
      adherenceSubmissionQuery.data.adherenceSubmissionOne?.attachments?.length
    )
      setSubmissionImageIndex(submissionMediaIndex + 1);
  };
  const backToPreviousContentItem = () => {
    if (submissionMediaIndex - 1 >= 0)
      setSubmissionImageIndex(submissionMediaIndex - 1);
  };

  const updateSubmissionImage = () => {
    setSubmissionImage(
      adherenceSubmissionQuery.data.adherenceSubmissionOne?.attachments?.[submissionMediaIndex]
        .preview
    );
  };
  const updateSubmissionMedia = () => {
    setSubmissionMedia(
      adherenceSubmissionQuery.data.adherenceSubmissionOne?.attachments?.[submissionMediaIndex]
    );
  };

  const mediaIndexString = () => adherenceSubmissionQuery?.data ? `${submissionMediaIndex + 1} / ${adherenceSubmissionQuery.data?.adherenceSubmissionOne?.attachments?.length}` : '';

  useEffect(() => {
    if (!adherenceSubmissionQuery.loading && adherenceSubmissionQuery.data) {
      updateSubmissionImage();
      updateSubmissionMedia();
    }
  }, [adherenceSubmissionQuery.data, submissionMediaIndex, adherenceSubmissionQuery.loading]);

  return {
    submissionImage,
    imageRotationState,
    rotateImage,
    skipCurrentContentItem,
    backToPreviousContentItem,
    submissionMedia,
    mediaIndexString
  };
};
